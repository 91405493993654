import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';

@Injectable()
export class RolesApi {
    private readonly apiController: string = 'roles';

    constructor(private api: HttpService) {}

    getList(): Observable<string[]> {
        return this.api.get(`${this.apiController}/`);
    }
}
