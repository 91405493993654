import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DefaultFilter} from '@mominsamir/ngx-smart-table';
import {TimeUnlocalizePipe} from '../../../pipes';
import * as moment from 'moment';

@Component({
    template: `
        <input
            [ngClass]="inputClass"
            [formControl]="inputControl"
            class="form-control"
            type="text"
            pattern="{{ column.getFilterConfig().pattern || '' }}"
            placeholder="{{ column.getFilterConfig().placeholder || column.title }}"
        />
    `,
})
export class StringDateFilterComponent extends DefaultFilter implements OnInit, OnChanges {
    constructor(private unlocalizeTimePipe: TimeUnlocalizePipe) {
        super();
    }

    inputControl: FormControl = new FormControl();

    ngOnInit() {
        if (this.query) {
            this.inputControl.setValue(this.query);
        }
        this.inputControl.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(this.delay))
            .subscribe((value: string) => {
                if (value == null) {
                    this.query = '';
                    this.setFilter();
                } else if (this.inputControl.status === 'VALID') {
                    const startDateRaw = moment(this.inputControl.value.start);
                    const endDateRaw = moment(this.inputControl.value.start).add(1, 'days');

                    const startDate = this.unlocalizeTimePipe.transform(startDateRaw);
                    const endDate = this.unlocalizeTimePipe.transform(endDateRaw);
                    const result = startDate + '|' + endDate;

                    this.query = value !== null ? result : '';
                    this.setFilter();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.inputControl.setValue(this.inputControl.value);
        }
    }
}
