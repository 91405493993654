import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {LatestModelStatus, RowDataReadingsDashboardData} from '@core/interfaces/models/live';
import {Co2LoadFaultIndicationDashboardData, Co2LoadService, Co2LoadUnits} from '@core/interfaces/models/co2-load';
import {Co2LoadApi} from '@core/backend/models/api/co2-load.api';

@Injectable()
export class Co2LoadServiceImpl extends Co2LoadService {
    constructor(private api: Co2LoadApi) {
        super();
    }

    public getCo2LoadUnits(): Observable<Co2LoadUnits> {
        return this.api.getCo2LoadUnits();
    }

    public getCo2LoadDatasource(id: string): Observable<DataSource> {
        return this.api.getCo2LoadDatasource(id);
    }

    public getCountResults(id: string, uploadDate?: string): Observable<number> {
        return this.api.getCountResults(id, uploadDate);
    }

    public getCo2LoadLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus> {
        return this.api.getCo2LoadLatestModelStatus(id, uploadDate);
    }

    public getCo2LoadRowDataReadingsDashboardData(
        id: string,
        uploadDate?: string,
    ): Observable<RowDataReadingsDashboardData> {
        return this.api.getCo2LoadRowDataReadingsDashboardData(id, uploadDate);
    }

    public getCo2LoadFaultIndicationDashboardData(
        id: string,
        uploadDate?: string,
    ): Observable<Co2LoadFaultIndicationDashboardData> {
        return this.api.getCo2LoadFaultIndicationDashboardData(id, uploadDate);
    }
}
