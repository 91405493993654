import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';
import {tap} from 'rxjs/internal/operators/tap';
import {Router} from '@angular/router';
import {PagesStore} from '../../@store';
import {APIException, ExceptionDisplayLevel, ExceptionDto} from './exception.dto';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private currentPageIsLoginPage = new BehaviorSubject(null);

    constructor(private toastrService: NbToastrService, private router: Router, private pagesStore: PagesStore) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(() => {
                this.pagesStore.currentUrl$.subscribe((page) =>
                    page === '/auth/login'
                        ? this.currentPageIsLoginPage.next(true)
                        : this.currentPageIsLoginPage.next(false),
                );
            }),
            catchError((error: HttpErrorResponse, caught) => {
                let errorMessage = '';
                // Client-side error is type ErrorEvent
                if (error.error instanceof ErrorEvent) {
                    errorMessage = `Error: ${error.error.message}`;
                    this.toastrService.danger(`Error: ${error.error.message}`, 'External error. Please try again.', {
                        duration: 10000,
                    });
                    return throwError(errorMessage);
                } else if (
                    error.status === 401 &&
                    error.error === 'Unauthorized' &&
                    !this.currentPageIsLoginPage.value
                ) {
                    setTimeout(() => {
                        this.toastrService.warning(
                            'Session has expired and you have been automatically logged out. ' + 'Please log back in.',
                            'Session is expired',
                            {duration: 0},
                        );
                    }, 1500);
                    this.router.navigate(['auth/login']);
                    return throwError(errorMessage);
                } else {
                    const apiException = error.error.response as APIException;
                    if (apiException != null && apiException.exceptions) {
                        const exceptions = apiException.exceptions as ExceptionDto[];
                        exceptions
                            .filter((f) => f.errorDisplayType === 'GLOBAL')
                            .forEach((err) => {
                                this.sendToastr(`Error: ${err.message}`, err.exceptionDetail, 10000, err.errorLevel);
                            });
                    } else {
                        this.toastrService.danger(
                            `Error: ${error.error.message}`,
                            'Internal error. Please try again or contact your administrator.',
                            {duration: 10000},
                        );
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        return throwError(errorMessage);
                    }
                }
            }),
        );
    }

    private sendToastr(title, body, duration, level: ExceptionDisplayLevel) {
        switch (level) {
            case ExceptionDisplayLevel.DANGER:
                this.toastrService.danger(body, title, {duration: duration});
                break;
            case ExceptionDisplayLevel.WARNING:
                this.toastrService.warning(body, title, {duration: duration});
                break;
            case ExceptionDisplayLevel.INFO:
                this.toastrService.info(body, title, {duration: duration});
                break;
            case ExceptionDisplayLevel.NONE:
                break;
        }
    }
}
