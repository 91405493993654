<ng-container *ngIf="(authService.getToken() | async).getPayload() as user">
    <!-- Collapsed menu - user icon and user email -->
    <div class="display-flex flex-column height-100">
        <div class="header-user-container" [nbPopover]="menuItems" nbPopoverTrigger="click">
            <!-- Icon -->
            <ngx-icon-box class="user-icon" icon="person-outline"> </ngx-icon-box>
            <!-- User email -->
            <div class="user-info">
                <label class="secondary-label"> User </label>
                <label class="main-label">
                    {{ user.email }}
                </label>
            </div>
            <!-- Expand icon -->
            <ngx-icon-box class="open-menu" icon="chevron-down-outline"> </ngx-icon-box>
        </div>
    </div>
</ng-container>

<!-- Expanded menu -->
<ng-template #menuItems>
    <ngx-header-settings-dialog></ngx-header-settings-dialog>
</ng-template>
