import {Component, Input} from '@angular/core';

@Component({
    selector: 'ngx-empty-table-small',
    templateUrl: './table-small.component.html',
    styleUrls: ['./table-small.component.scss', '../common-theme-empty-charts.scss'],
})
export class EmptyTableSmallComponent {
    @Input() tableHeader;
    tableRow = ['empty1', 'empty2'];
}
