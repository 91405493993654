import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {Note, NoteCreate, NoteService} from '@core/interfaces/common/note';
import {NoteApi} from '@core/backend/common/api/note.api';

@Injectable()
export class NoteServiceImpl extends NoteService {
    constructor(private api: NoteApi) {
        super();
    }

    /*
     * Operations
     */
    create(note: NoteCreate): Observable<APIResponse<Note>> {
        return this.api.create(note);
    }

    update(noteId: number, note: Note): Observable<APIResponse<Note>> {
        return this.api.update(noteId, note);
    }

    delete(noteId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(noteId);
    }

    /*
     * Retrieval
     */
    getAssetNotes(assetId: string): Observable<APIResponse<Note[]>> {
        return this.api.getAssetNotes(assetId);
    }
}
