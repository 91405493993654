import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThemeModule} from '@theme/theme.module';

const CHART_COMPONENTS = [];

@NgModule({
    declarations: [...CHART_COMPONENTS],
    exports: [...CHART_COMPONENTS],
    imports: [ThemeModule, CommonModule],
})
export class ChartLibModule {}
