import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ngx-select-search',
    template: `
        <ngx-mat-select-search
            [ngStyle]="optionsStyle"
            [formControl]="filterControl"
            [placeholderLabel]="placeholder"
            noEntriesFoundLabel="no matching found"
            [hideClearSearchButton]="true"
        ></ngx-mat-select-search>
    `,
    styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent {
    @Input() optionsStyle;
    @Input() filterControl;
    @Input() placeholder;
    constructor() {}
}
