import {Injectable} from '@angular/core';
import {APIResponse} from '@core/interfaces/system/system-common';
import {Observable} from 'rxjs';
import {ConfigurationService, DatasetItem, LiveConfig, WorkflowConfig} from '@core/interfaces/common/configuration';
import {ConfigurationApi} from '../api/configuration.api';

@Injectable()
export class ConfigurationServiceImpl extends ConfigurationService {
    constructor(private api: ConfigurationApi) {
        super();
    }

    getWorkFlows(): Observable<APIResponse<WorkflowConfig[]>> {
        return this.api.getWorkFlows();
    }

    getDatasets(): Observable<APIResponse<DatasetItem[]>> {
        return this.api.getDatasets();
    }

    getTablesFromSchema(schemaName: string): Observable<APIResponse<string[]>> {
        return this.api.getTablesFromSchema(schemaName);
    }

    getLiveConfiguration(): Observable<APIResponse<LiveConfig>> {
        return this.api.getLiveConfiguration();
    }
}
