import {Observable} from 'rxjs';
import {DatasetType} from './dataset';
import {APIResponse, SimpleUnit} from '@core/interfaces/system/system-common';
import {AlertStatusEnum, AlertStatusInd} from '@core/interfaces/models/live';

export interface DatasetItem {
    code: DatasetType;
    name: string;
}

export interface DatasetList {
    code: string;
    name: string;
    required: boolean;
}

export interface WorkflowConfig {
    code: string;
    name: string;
    datasetList: DatasetList[];
}

export interface SchemaType {
    name: string;
    label: string;
}

export interface LiveConfig {
    statuses: AlertStatusItem[];
    enablePrediction: boolean;
    meters: MeterTypeItem[];
    units: SimpleUnitItem[];
}
export interface AlertStatusItem {
    code: AlertStatusEnum;
    label: string;
}
export interface MeterTypeItem {
    code: string;
    label: string;
}
export interface SimpleUnitItem {
    code: string;
    unit: SimpleUnit;
}

export interface TenantUnitsConfig {
    currency: SimpleUnit;
    count: SimpleUnit;
    length: SimpleUnit;
    power: SimpleUnit[];
}

// External DTOs
export interface TenantUnits {
    currency: SimpleUnit;
    count: SimpleUnit;
    length: SimpleUnit;
    power: SimpleUnit[];
    graphUnits: GraphUnitConfig[];
}
export interface GraphUnitConfig {
    value: string;
    label: string;
}

export abstract class ConfigurationService {
    abstract getWorkFlows(): Observable<APIResponse<WorkflowConfig[]>>;

    abstract getDatasets(): Observable<APIResponse<DatasetItem[]>>;

    abstract getTablesFromSchema(schemaName: string): Observable<APIResponse<string[]>>;

    abstract getLiveConfiguration(): Observable<APIResponse<LiveConfig>>;
}
