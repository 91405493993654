import {Component} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {Unsubscribable} from '../../../@core/interfaces/unsubscribable';
import {SystemInfoDialogViewModel} from './system-info-dialog-view.model';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {AboutSystemInfo} from '../../../@core/interfaces/common/support';

@Component({
    selector: 'ngx-system-about',
    templateUrl: './system-info-dialog.component.html',
    styleUrls: ['./system-info-dialog.component.scss'],
    providers: [SystemInfoDialogViewModel],
})
export class SystemInfoDialogComponent extends Unsubscribable {
    // Static fields for about dialog
    dialogTitle: String = 'About';

    aboutFormGroup: FormGroup = this.fb.group({
        details: this.fb.array([]),
    });

    constructor(public vm: SystemInfoDialogViewModel, private dialogService: NbDialogService, private fb: FormBuilder) {
        super();
        this.vm.aboutSystemInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe((aboutSystemInfo: AboutSystemInfo) => {
            // Push details to form control
            this.detailsFormArray.push(
                this.fb.control({
                    title: 'ENGIN Version',
                    value: aboutSystemInfo.getEnginVersion(),
                }),
            );
            this.detailsFormArray.push(
                this.fb.control({
                    title: 'Tenant Identifier',
                    value: aboutSystemInfo.getTenantId(),
                }),
            );
            this.detailsFormArray.push(
                this.fb.control({
                    title: 'User Account',
                    value: `${aboutSystemInfo.getUserName()} \n ${aboutSystemInfo.getUserEmail()}`,
                }),
            );
            this.detailsFormArray.push(this.fb.control({title: 'Browser', value: aboutSystemInfo.getBrowserInfo()}));
        });
    }

    openAbout(dialog) {
        this.dialogService.open(dialog, {closeOnBackdropClick: false});
    }

    get aboutFormGroupValue(): FormControl {
        return this.aboutFormGroup.value;
    }

    get detailsFormArray() {
        return this.aboutFormGroup.get('details') as FormArray;
    }
}
