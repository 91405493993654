import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {Note, NoteCreate} from '@core/interfaces/common/note';

@Injectable()
export class NoteApi {
    private readonly prefix = 'note';

    constructor(private api: HttpService) {}

    /*
     * Operations
     */
    create(note: NoteCreate): Observable<APIResponse<Note>> {
        return this.api.post(`${this.prefix}/`, note);
    }

    update(noteId: number, note: Note): Observable<APIResponse<Note>> {
        return this.api.put(`${this.prefix}/${noteId}`, note);
    }

    delete(noteId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${noteId}`, {});
    }

    /*
     * Retrieval
     */
    getAssetNotes(assetId: string): Observable<APIResponse<Note[]>> {
        const type: string = 'ASSET';
        return this.api.get(`${this.prefix}/type/${type}/id/${assetId}`);
    }
}
