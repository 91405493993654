import {Observable} from 'rxjs';
import {APIResponse, PresignedURL} from '@core/interfaces/system/system-common';
import {AssetImageData} from '@core/interfaces/engin/asset-details';

export interface Document {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    fileFormat: string;
    fileName: string;
    fileKey: string;
    bucketName: string;
    datasetType: string;
    relatedEntity: string;
    userName: string;
    userId: string;
    name: string;
    tag: string;
}

export interface DocumentCreate {
    fileFormat: string;
    fileName: string;
    fileKey: string;
    bucketName: string;
    documentType: string;
    relatedEntity: string;
    userName: string;
    userId: string;
    tag: string;
}

export interface DocumentAssetImage {
    id: number;
    assetId: number;
    name: string;
    tag: string;
    url: string;
}

export abstract class DocumentService {
    /*
     * Operational
     */
    abstract create(document: DocumentCreate): Observable<APIResponse<Document>>;

    abstract update(documentId: number, document: AssetImageData): Observable<APIResponse<Document>>;

    abstract delete(documentId: number): Observable<APIResponse<boolean>>;

    abstract getPresignedUrl(fileName: string): Observable<APIResponse<PresignedURL>>;

    abstract uploadToPresignedUrl(url: string, data: File);

    /*
     * Retrieval
     */
    abstract getAssetImageDocuments(assetId: string): Observable<APIResponse<DocumentAssetImage[]>>;

    abstract findOne(documentId: string): Observable<APIResponse<Document>>;

    abstract downloadDocumentsById(documentIds: number[]): Observable<APIResponse<string[]>>;
}
