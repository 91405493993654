import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

/*
 * Material imports
 */
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';

import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

/*
 * Nebular imports
 */
import {
    NbActionsModule,
    NbAlertModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDatepickerModule,
    NbDialogModule,
    NbIconModule,
    NbInputModule,
    NbFormFieldModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule,
    NbPopoverModule,
    NbProgressBarModule,
    NbRadioModule,
    NbSelectModule,
    NbSidebarModule,
    NbTabsetModule,
    NbThemeModule,
    NbToastrModule,
    NbTooltipModule,
    NbWindowModule,
    NbButtonGroupModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';
import {NbDateFnsDateModule} from '@nebular/date-fns';

/*
 * Components
 */
import * as components from './components';

/*
 * Pipes
 */
import {
    CapitalizePipe,
    NumberWithCommasPipe,
    TimeLocalizePipe,
    TimeUnlocalizePipe,
    TimezonePipe,
    DateFormatPipe,
    DatetimeFormatPipe,
} from './pipes';

/*
 * Directives
 */

/*
 * Empty components
 */
import {EmptyTableComponent, EmptyTableSmallComponent} from './empty-components';
import {EmptyDataComponent} from './components';

/*
 * Styles and theme
 */
import {DEFAULT_THEME} from './styles/theme.default';
import {DARK_THEME} from './styles/theme.dark';

// Other
import {AuthModule} from '@auth/auth.module';
import {RouterModule} from '@angular/router';
import {SidebarStore} from '@store/config/sidebar.store';

// External
import {NgxEchartsModule} from 'ngx-echarts';
import {NgxValidationMessageComponent} from './components/validation-message/validation-message.component';
import {LoginComponent} from './components/login-form/login.component';
import {SystemInfoDialogComponent} from './components/system-info-dialog/system-info-dialog.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {HelpSupportComponent} from './components/help-support/help-support.component';
import {HeaderSettingsComponent} from '@theme/components';
import {HeaderSettingsDialogComponent} from '@theme/components';
import {NgxSmartTableModule} from '@mominsamir/ngx-smart-table';
import {SwiperModule} from 'swiper/angular';
import {TabButtonsComponent} from '@theme/components/tab-buttons/tab-buttons.component';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];
const NB_MODULES = [
    NbEvaIconsModule,
    NbCardModule,
    NbLayoutModule,
    NbTabsetModule,
    NbMenuModule,
    NbActionsModule,
    NbSidebarModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbContextMenuModule,
    NbSecurityModule, // *nbIsGranted directive,
    NbProgressBarModule,
    NbButtonModule,
    NbButtonGroupModule,
    NbListModule,
    NbToastrModule,
    NbInputModule,
    NbFormFieldModule,
    NbDatepickerModule,
    NbAlertModule,
    NbRadioModule,
    NbSelectModule,
    NbTooltipModule,
    NbIconModule,
    NbAutocompleteModule,
];
const MAT_MODULES = [MatSelectModule, MatButtonModule, MatFormFieldModule, NgxMatSelectSearchModule];

const APP_MODULES = [NgxSmartTableModule, NgxEchartsModule, SwiperModule];

const COMPONENTS = [
    NgxValidationMessageComponent,
    LoginComponent,
    components.ThemeSwitcherComponent,
    components.FooterComponent,
    components.IconBoxComponent,
    components.PagesGroupPopoverComponent,
    components.MenuComponent,
    components.SubmenuComponent,
    components.SubmenuPopoverComponent,
    components.TablePagerComponent,
    components.SidebarActionComponent,
    components.SidebarActionPopoverComponent,
    components.ChartComponent,
    components.ChartSettingsComponent,
    components.ChartSettingsPopupComponent,
    components.DataTableComponent,
    components.DateFilterComponent,
    components.StringDateFilterComponent,
    components.NumericRangeFilterComponent,
    components.ConformDialogComponent,
    components.InputErrorComponent,
    components.EmptyDataComponent,
    components.StatusCellComponent,
    components.MultiSelectFilterComponent,
    components.SelectSearchComponent,
    components.AlertStatusCellComponent,
    components.CurrentPositionVisualizerComponent,
    components.GuideDialogComponent,

    HelpSupportComponent,
    HeaderSettingsComponent,
    HeaderSettingsDialogComponent,

    TabButtonsComponent,

    // Empty chart components
    EmptyTableComponent,
    EmptyTableSmallComponent,
];

const ENTRY_COMPONENTS = [EmptyDataComponent];

const PIPES = [CapitalizePipe, NumberWithCommasPipe, TimezonePipe, DateFormatPipe, DatetimeFormatPipe];
const DATA_PIPES = [TimeLocalizePipe, TimeUnlocalizePipe];

const DIRECTIVES = [];
const VM = [];
const NB_THEME_PROVIDERS = [
    ...NbThemeModule.forRoot(
        {
            name: 'default',
        },
        [DEFAULT_THEME, DARK_THEME],
    ).providers,
    ...NbSidebarModule.forRoot().providers,
    ...NbMenuModule.forRoot().providers,
    ...NbDatepickerModule.forRoot().providers,
    ...(NbDateFnsDateModule.forRoot({format: 'yyyy-MM-dd'}).providers ?? []),
    ...NbDialogModule.forRoot().providers,
    ...NbWindowModule.forRoot().providers,
    ...NbToastrModule.forRoot().providers,
];

@NgModule({
    imports: [
        AuthModule,
        RouterModule,
        ...BASE_MODULES,
        ...NB_MODULES,
        ...MAT_MODULES,
        ...APP_MODULES,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ],
    exports: [...BASE_MODULES, ...NB_MODULES, ...MAT_MODULES, ...COMPONENTS, ...PIPES, ...DATA_PIPES, ...DIRECTIVES],
    providers: [SidebarStore, DecimalPipe, DeviceDetectorService, ...VM],
    declarations: [...COMPONENTS, ...PIPES, ...DATA_PIPES, ...DIRECTIVES, SystemInfoDialogComponent],
    entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [...NB_THEME_PROVIDERS],
        };
    }
}
