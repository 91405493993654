import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {Report} from '../common/pages';
import {SnapshotDto} from '../common/snapshot';
import {APIResponse} from '@core/interfaces/system/system-common';

export interface ReportLink {
    link: string;
}

export class ReportRequest {
    reportId: string;
    templateFlag: boolean;
    studyId: string;
    userId: string;
    sensitivityFlag: boolean;

    constructor(report: Report, studyId: string, userId: string, sensitivityFlag: boolean) {
        this.reportId = report ? report.reportId : '';
        this.templateFlag = report ? report.templateFlag : true;
        this.studyId = studyId;
        this.userId = userId;
        this.sensitivityFlag = sensitivityFlag;
    }
}

export interface OutputReportSource {
    links: [string];
}

export interface Workflow {
    id: number;
    createdOn: string;
    updatedOn: string;
    name: string;
    description: string;
    snapshotId: number;
    workflowType: string;
    status: string;
    startTime: string;
    endTime: string;
    tag: string;
    note: string;
    createdBy: string;
    createdByName: string;
    workflowItems?: WorkflowItem[];
    duration: string;
}

export interface WorkflowItemParam {
    id: number;
    createdOn: string;
    updatedOn?: any;
    workflowItemId: number;
    paramKey: string;
    value: string;
}

export interface WorkflowItem {
    id: number;
    createdOn: string;
    updatedOn?: any;
    itemName: string;
    sensitivityCode: string;
    status: string;
    defaultItem: boolean;
    executionOrder: number;
    startTime?: any;
    endTime?: any;
    currentYear: number;
    evaluationPeriod: number;
    workflowId: number;
    itemParams: WorkflowItemParam[];
}

export abstract class WorkflowService {
    abstract getWorkflowDataSource(): Observable<DataSource>;

    abstract getWorkflowById(workflowId: number): Observable<APIResponse<Workflow>>;

    abstract getWorkflowItemById(workflowItemId: number): Observable<APIResponse<WorkflowItem>>;

    abstract getLatestCompletedWorkFlow(istrue: boolean): Observable<APIResponse<Workflow>>;

    abstract updateWorkflowNoteTag(workflowId: number, Workflow): Observable<APIResponse<Workflow>>;

    abstract executeNewWorkflowFromSnapshot(snapshotId: number, automated: boolean, date?: string): Observable<any>;

    abstract findBySnapshotId(snapshotId: number): Observable<APIResponse<Workflow>>;

    abstract findAllBySnapshotId(snapshotId: number): Observable<APIResponse<Array<Workflow>>>;

    abstract findBySnapshotDtoByWorkflowId(workflowId: number): Observable<APIResponse<SnapshotDto>>;

    abstract delete(workflowId: number): Observable<APIResponse<boolean>>;

    abstract reversePendingDelete(workflowId: number): Observable<APIResponse<boolean>>;

    abstract updateActiveWorkflow(
        workflowId: number,
        userId: string,
        active: boolean,
    ): Observable<APIResponse<boolean>>;
}
