import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RolesApi} from '../api/roles.api';
import {RolesService} from '../../../interfaces/common/roles';

@Injectable()
export class RolesServiceImpl extends RolesService {
    constructor(private api: RolesApi) {
        super();
    }

    getList(): Observable<string[]> {
        return this.api.getList();
    }
}
