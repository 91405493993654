import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {
    Sf6GasLeakComparisonResponse,
    Sf6GasLeakCorrectedResponse,
    Sf6GasLeakLatestStatusResponse,
    Sf6GasLeakRawResponse,
    Sf6GasLeakService,
    Sf6GasLeakUnits,
} from '@core/interfaces/models/sf6-gas-leak';
import {LatestModelStatus, LiveModelRequest} from '@core/interfaces/models/live';
import {Sf6GasLeakApi} from '@core/backend/models/api/sf6-gas-leak.api';

@Injectable()
export class Sf6GasLeakServiceImpl extends Sf6GasLeakService {
    constructor(private api: Sf6GasLeakApi) {
        super();
    }

    public getSf6GasLeakDatasource(id: string): Observable<DataSource> {
        return this.api.getSf6GasLeakDatasource(id);
    }

    public getSf6GasLeakUnits(): Observable<Sf6GasLeakUnits> {
        return this.api.getSf6GasLeakUnits();
    }

    public getCountResults(id: string, uploadDate?: string): Observable<number> {
        return this.api.getCountResults(id, uploadDate);
    }

    public getSf6GasLeakLatestStatusDetails(request: LiveModelRequest): Observable<LatestModelStatus> {
        return this.api.getSf6GasLeakLatestStatusDetails(request);
    }

    public getSf6GasLeakChartDataComparison(request: LiveModelRequest): Observable<Sf6GasLeakComparisonResponse> {
        return this.api.getSf6GasLeakChartDataComparison(request);
    }

    public getSf6GasLeakChartDataRaw(request: LiveModelRequest): Observable<Sf6GasLeakRawResponse> {
        return this.api.getSf6GasLeakChartDataRaw(request);
    }

    public getSf6GasLeakChartDataCorrected(request: LiveModelRequest): Observable<Sf6GasLeakCorrectedResponse> {
        return this.api.getSf6GasLeakChartDataCorrected(request);
    }
}
