import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ngx-empty-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss', '../common-theme-empty-charts.scss'],
})
export class EmptyTableComponent implements OnInit {
    @Input() column;
    @Input() header = false;

    private defaultColumns = [
        {
            code: 'col1',
            title: 'Asset ID',
            width: '25%',
        },
        {
            code: 'col2',
            title: 'Asset Class Code',
            width: '25%',
        },
        {
            code: 'col3',
            title: 'Asset Subclass Code',
            width: '25%',
        },
        {
            code: 'col4',
            title: 'Plant',
            width: '25%',
        },
    ];

    constructor() {}

    dynamicColumn = {};
    dynamicData = [];

    ngOnInit() {
        if (!this.column) {
            this.column = this.defaultColumns;
        }

        this.column.map((data) => {
            // from study-asset page
            if (data.code) {
                Object.assign(this.dynamicColumn, {
                    [data.code]: {
                        title: data.title,
                        width: data.width,
                        sort: false,
                        filter: false,
                    },
                });
            } else {
                // from other pages.
                if (data['title']) {
                    Object.assign(this.dynamicColumn, {
                        [data.title]: {
                            title: data.title,
                            width: data.width,
                            sort: false,
                            filter: false,
                        },
                    });
                }
            }
        });

        const tempObject = {};
        Object.keys(this.dynamicColumn).map((header) => {
            Object.assign(tempObject, {[header]: 'empty string'});
            this.dynamicData.push(tempObject);
        });
    }

    settings = {
        actions: {
            add: false,
            delete: false,
            edit: false,
        },
        editable: false,
        pager: {
            display: false,
        },
        columns: this.dynamicColumn,
    };
}
