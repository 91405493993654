import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PagesStore, SidebarStore} from '../../@store';
import {map} from 'rxjs/operators';
import {PageGroup} from '@core/interfaces/common/pages';

@Component({
    selector: 'ngx-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
    readonly modules$ = this.pagesStore.dashboardCards$.pipe(
        map((cards: PageGroup[]) => {
            return cards.find((card) => card.id === 'modules');
        }),
    );
    readonly tools$ = this.pagesStore.dashboardCards$.pipe(
        map((cards: PageGroup[]) => {
            return cards.find((card) => card.id === 'tools');
        }),
    );
    readonly archives$ = this.pagesStore.dashboardCards$.pipe(
        map((cards: PageGroup[]) => {
            return cards.find((card) => card.id === 'archive');
        }),
    );
    readonly configuration$ = this.pagesStore.dashboardCards$.pipe(
        map((cards: PageGroup[]) => {
            return cards.find((card) => card.id === 'configuration');
        }),
    );

    constructor(private sidebarStore: SidebarStore, public pagesStore: PagesStore) {
        this.sidebarStore.toggleSidebarState(false);
    }
}
