import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class UsersApi {
    private readonly apiController: string = 'users';

    constructor(private api: HttpService) {}

    getCurrent(): Observable<any> {
        return this.api.get(`${this.apiController}/current`);
    }

    get(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/${id}`);
    }

    delete(id: string): Observable<boolean> {
        return this.api.delete(`${this.apiController}/${id}`);
    }

    create(item: any): Observable<any> {
        return this.api.post(`${this.apiController}/`, item);
    }

    update(item: any): Observable<any> {
        return this.api.put(`${this.apiController}/${item.id}`, item);
    }

    getUsersDataSource(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.apiController}`);
    }
}
