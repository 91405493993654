import {getTimeZones, TimeZone} from '@vvo/tzdb';

export class Timezone {
    code: string;
    name: string;
    continent: string;
    country: string;
    mainCities: string[];
    abbreviation: string;
    rawOffsetInMinutes: number;
}

/*
 * Source: https://github.com/vvo/tzdb/
 */
export class TimezoneService {
    // Include only canada, us
    private _list_CA_US: TimeZone[] = getTimeZones().filter((t) => t.countryCode === 'CA' || t.countryCode === 'US');
    // Include only europe
    private _list_EU: TimeZone[] = getTimeZones().filter((t) => t.continentCode === 'EU');

    // Manually create UTC
    private _list_UTC: Timezone[] = [
        {
            code: 'Etc/UTC',
            name: 'Coordinated Universal Time',
            continent: 'UTC',
            country: 'UTC',
            mainCities: ['Global'],
            abbreviation: 'UTC',
            rawOffsetInMinutes: 0,
        },
    ];

    // Map to internal data object, sort by country
    public list: Timezone[] = this._list_CA_US
        .concat(this._list_EU)
        .map((t: TimeZone) => {
            return {
                code: t.name,
                name: t.alternativeName,
                continent: t.continentName,
                country: t.countryName,
                mainCities: t.mainCities,
                abbreviation: t.abbreviation,
                rawOffsetInMinutes: t.rawOffsetInMinutes,
            };
        })
        .concat(this._list_UTC)
        .sort((a: Timezone, b: Timezone) =>
            a.rawOffsetInMinutes > b.rawOffsetInMinutes ? 1 : b.rawOffsetInMinutes > a.rawOffsetInMinutes ? -1 : 0,
        );
}
