import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {LatestModelStatus, FaultIndicationDashboardData} from '@core/interfaces/models/live';
import {InsulationMoistureService, InsulationMoistureUnits} from '@core/interfaces/models/insulation-moisture';
import {InsulationMoistureApi} from '../api/insulation-moisture.api';

@Injectable()
export class InsulationMoistureServiceImpl extends InsulationMoistureService {
    constructor(private api: InsulationMoistureApi) {
        super();
    }

    public getInsulationMoistureUnits(): Observable<InsulationMoistureUnits> {
        return this.api.getInsulationMoistureUnits();
    }

    public getInsulationMoistureDatasource(id: string): Observable<DataSource> {
        return this.api.getInsulationMoistureDatasource(id);
    }

    public getCountResults(id: string, uploadDate?: string): Observable<number> {
        return this.api.getCountResults(id, uploadDate);
    }

    public getInsulationMoistureLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus> {
        return this.api.getInsulationMoistureLatestModelStatus(id, uploadDate);
    }

    public getInsulationMoistureDashboardData(
        id: string,
        uploadDate?: string,
    ): Observable<FaultIndicationDashboardData> {
        return this.api.getInsulationMoistureDashboardData(id, uploadDate);
    }
}
