import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {SimpleUnit} from '@core/interfaces/system/system-common';
import {FaultIndicationDashboardData, LatestModelStatus} from './live';

export interface InsulationMoistureUnits {
    paperMoistureUnit: SimpleUnit;
}

export abstract class InsulationMoistureService {
    abstract getInsulationMoistureUnits(): Observable<InsulationMoistureUnits>;

    abstract getInsulationMoistureDatasource(id: string): Observable<DataSource>;

    abstract getCountResults(id: string, uploadDate?: string): Observable<number>;

    abstract getInsulationMoistureLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus>;

    abstract getInsulationMoistureDashboardData(
        id: string,
        uploadDate?: string,
    ): Observable<FaultIndicationDashboardData>;
}
