import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NbAuthService} from '@nebular/auth';

@Component({
    selector: 'ngx-header-settings',
    templateUrl: './header-settings.component.html',
    styleUrls: ['./header-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [NbAuthService],
})
export class HeaderSettingsComponent {
    constructor(public authService: NbAuthService) {}
}
