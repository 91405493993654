import {ModuleWithProviders, NgModule} from '@angular/core';
import {SidebarStore} from './config/sidebar.store';
import {PagesStore} from './config/pages.store';
import {RolesStore} from './common/roles.store';
import {UsersStore} from './common/users.store';
import {SupportStore} from './common/support.store';
import {DataManagementStore} from './common/data-management.store';
import {ConfigurationStore} from './config/configuration.store';
import {LiveSearchStore} from '@store/live/live-search.store';

const STORES = [
    // Common
    DataManagementStore,
    RolesStore,
    UsersStore,
    SupportStore,
    LiveSearchStore,

    // Config
    PagesStore,
    SidebarStore,
    ConfigurationStore,
];

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [...STORES],
})
export class StoreModule {
    static forRoot(): ModuleWithProviders<StoreModule> {
        return {
            ngModule: StoreModule,
            providers: [],
        };
    }
}
