import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AboutSystemInfo, EnginVersionInfo} from '@core/interfaces/common/support';
import {User} from '@core/interfaces/common/users';
import {Page} from '@core/interfaces/common/pages';
import {UsersStore} from './users.store';
import {PagesStore} from '../config/pages.store';
import {DeviceDetectorService} from 'ngx-device-detector';
import {environment} from '../../../environments/environment';

@Injectable()
export class SupportStore {
    // Static environment version information
    version_major: number = environment.APP_VERSION_MAJOR;
    version_minor: number = environment.APP_VERSION_MINOR;
    version_patch: number = environment.APP_VERSION_PATCH;

    readonly aboutSystemInfo$: Observable<AboutSystemInfo> = combineLatest<Observable<User>, Observable<Page>>([
        this.usersStore.currentUser$,
        this.pagesStore.activeFeature$,
    ]).pipe(
        map(([activeUser, activeFeature]: [User, Page]) => {
            // Grab automatic information: software version, browser
            const enginVersion = new EnginVersionInfo(this.version_major, this.version_minor, this.version_patch);
            const browser = `${this.deviceService.browser} ${this.deviceService.browser_version}`;
            const result = new AboutSystemInfo(activeUser, activeFeature, browser, enginVersion);
            return result;
        }),
    );

    constructor(
        private usersStore: UsersStore,
        private pagesStore: PagesStore,
        private deviceService: DeviceDetectorService,
    ) {}
}
