import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PagesApi} from '../api/pages.api';
import {PageGroup, PagesService, PageWithLogi} from '../../../interfaces/common/pages';

@Injectable()
export class PagesServiceImpl extends PagesService {
    constructor(private api: PagesApi) {
        super();
    }

    getPages(): Observable<PageGroup[]> {
        return this.api.getPages();
    }

    updateLogiComponentsSettings(pages: PageWithLogi[]): Observable<boolean> {
        return this.api.updateLogiComponentsSettings(pages);
    }

    getDashboardInfo(): Observable<PageGroup[]> {
        return this.api.getDashboardInfo();
    }

    getLogiComponentsSettings(): Observable<PageWithLogi[]> {
        return this.api.getLogiComponentsSettings();
    }
}
