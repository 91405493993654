import {Injectable} from '@angular/core';

@Injectable()
export class FontsService {
    constructor() {}

    pxToRem(windowWidth, type) {
        if (type === 'numberSize') {
            let numberSize;
            if (windowWidth <= 1024 && windowWidth > 768) {
                // 1024
                numberSize = 20;
            } else if (windowWidth <= 768) {
                // 768
                numberSize = 28;
            } else {
                // default
                numberSize = 28;
            }
            return numberSize;
        }
        if (type === 'fontSize') {
            let fontSize;
            if (windowWidth <= 1024 && windowWidth > 768) {
                // 1024
                fontSize = 14;
            } else if (windowWidth <= 768) {
                // 768
                fontSize = 16;
            } else {
                // default
                fontSize = 16;
            }
            return fontSize;
        }

        if (type === 'titleFontSize') {
            let titleFontSize;
            if (windowWidth <= 1024 && windowWidth > 768) {
                // 1024
                titleFontSize = 14;
            } else if (windowWidth <= 768) {
                // 768
                titleFontSize = 11.5;
            } else {
                // default
                titleFontSize = 16;
            }
            return titleFontSize;
        }

        if (type === 'subTitleFontSize') {
            let subTitleFontSize;
            if (windowWidth <= 1024 && windowWidth > 768) {
                // 1024
                subTitleFontSize = 12;
            } else if (windowWidth <= 768) {
                // 768
                subTitleFontSize = 11;
            } else {
                // default
                subTitleFontSize = 14;
            }
            return subTitleFontSize;
        }

        // Font size
        // colorsMap['1024_fontSize'] = 12;
        // colorsMap['1024_numberSize'] = 20;
        // colorsMap['768_fontSize'] = 10;
        // colorsMap['768_numberSize'] = 15;
    }
}
