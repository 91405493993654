import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {DataFile} from '../../../interfaces/common/data-file';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DatasetType} from '../../../interfaces/common/dataset';
import {APIResponse, PresignedURL} from '@core/interfaces/system/system-common';
import {Document, DocumentAssetImage, DocumentCreate} from '@core/interfaces/common/document';
import {AssetImageData} from '@core/interfaces/engin/asset-details';

@Injectable()
export class DocumentApi {
    private readonly prefix = 'document';

    constructor(private api: HttpService) {}

    /*
     * Operations
     */
    create(document: DocumentCreate): Observable<APIResponse<Document>> {
        return this.api.post(`${this.prefix}/`, document);
    }

    update(documentId: number, document: AssetImageData): Observable<APIResponse<Document>> {
        return this.api.put(`${this.prefix}/${documentId}`, document);
    }

    delete(documentId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${documentId}`, {});
    }

    getPresignedUrl(fileName: string): Observable<APIResponse<PresignedURL>> {
        return this.api.get(`${this.prefix}/presigned-url/${fileName}`);
    }

    upload(url: string, fileData: File): Observable<any> {
        return this.api.uploadS3(`${url}`, fileData, {
            reportProgress: true,
            observe: 'events',
            headers: {
                'Content-Disposition': 'attachment',
                'Content-Type': 'application/octet-stream',
            },
        });
    }

    /*
     * Retrieval
     */
    getAssetImageDocuments(assetId: string): Observable<APIResponse<DocumentAssetImage[]>> {
        const type: string = 'ASSET';
        return this.api.get(`${this.prefix}/type/${type}/asset/${assetId}`);
    }

    findOne(documentId: string): Observable<APIResponse<Document>> {
        return this.api.get(`${this.prefix}/${documentId}`);
    }

    downloadDocumentsById(documentIds: number[]): Observable<APIResponse<string[]>> {
        return this.api.get(`${this.prefix}/download?documentIds=${documentIds}`);
    }
}
