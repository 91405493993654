import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PagesStore} from '../../../@store';
import {ChartDataDownloadService, ChartDownloadData, ECHARTS_TYPE} from './chart-data-download-service';
import {BehaviorSubject} from 'rxjs';
import {GraphLabel} from '@core/interfaces/common/pages';
import {ECharts} from 'echarts';

@Component({
    selector: 'ngx-chart-settings-popup',
    templateUrl: './chart-settings-popup.component.html',
    styleUrls: ['./chart-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartSettingsPopupComponent implements OnInit {
    /*
     * Data binding for unit selector functionality
     * - unitOptions: {label, value}[] for expected unit options
     * - unitItem: selected unit
     */
    @Input() unitOptions: {label: string; value: string}[];
    @Input() unitItem: any;
    /*
     * Data binding for label selector functionality
     * - labelOptions: defined labels options from page settings
     * - graphId: used with labelOptions to pull options for a single graph
     */
    @Input() labelOptions: BehaviorSubject<GraphLabel[]>;
    @Input() graphId: string;
    @Output() itemChanged = new EventEmitter<any>();
    /*
     * Data download component is enabled by default, and expects chartOptions and chartType data sources
     * - Optional input to disable download button
     * - Optional input of custom CSV file name
     * - Optional input of custom data object
     */
    @Input() disableDataDownload?: boolean;
    @Input() downloadFileName?: string;
    @Input() chartOptions?: any;
    @Input() chartType?: ECHARTS_TYPE;
    @Input() preparedDownloadData?: ChartDownloadData;
    // chartInstance: for image download options
    @Input() chartInstance?: ECharts;

    showLabelSelection;
    graphLabels: GraphLabel;
    labelSelected: any;

    constructor(public pagesStore: PagesStore) {}

    ngOnInit() {
        const labelOptionsValue = this.labelOptions ? this.labelOptions.value : null;
        const graphLabelOptions = labelOptionsValue
            ? labelOptionsValue.filter((g) => g.graphId === this.graphId)
            : null;
        // Show selection if the label settings is initialized, has values for this graph, and values are not placeholder
        if (labelOptionsValue && graphLabelOptions && graphLabelOptions[0]) {
            if (graphLabelOptions[0].options.length > 1 && graphLabelOptions[0].options[0].value !== 'none') {
                this.showLabelSelection = true;
                this.labelSelected = graphLabelOptions[0].options.filter((e) => e.selected)[0].value;
                this.graphLabels = graphLabelOptions[0];
            } else {
                this.showLabelSelection = false;
            }
        } else {
            this.showLabelSelection = false;
        }
    }

    dataDownloadCSV() {
        const fileName = this.getDataDownloadFilename();

        // If custom data has been provided, send it to the user
        if (this.preparedDownloadData != null) {
            ChartDataDownloadService.downloadChartData(this.preparedDownloadData, fileName);
        } else {
            ChartDataDownloadService.prepareAndDownloadChartData(this.chartOptions, this.chartType, fileName);
        }
    }

    dataDownloadImage(format: 'png' | 'jpeg') {
        const fileName = this.getDataDownloadFilename();
        ChartDataDownloadService.downloadChartImage(this.chartInstance, fileName, format);
    }

    private getDataDownloadFilename() {
        if (this.downloadFileName == null) {
            const defaultTitle = 'chart_download_' + this.getCurrentTime();

            if (this.chartOptions != null && this.chartOptions.title != null) {
                return this.chartOptions.title
                    ? this.chartOptions.title.text.replace(/ /g, '_') + '_' + this.getCurrentTime()
                    : defaultTitle;
            }
            return defaultTitle;
        }

        return this.downloadFileName;
    }

    private getCurrentTime() {
        const today = new Date();
        return `${today.getFullYear()}-${
            today.getMonth() + 1
        }-${today.getDate()}_${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
    }

    public labelChanged(event) {
        let newGraphLabels = this.labelOptions.value;
        newGraphLabels = newGraphLabels.map((graph) => {
            // Extract options for this graph only
            if (graph.graphId === this.graphId) {
                const newLabelOptions = graph.options.map((option) => {
                    // For the item selected in the event, set selected to true
                    if (event === option.value) {
                        return {
                            ...option,
                            selected: true,
                        };
                    } else {
                        // Set all other items to unselected
                        return {
                            ...option,
                            selected: false,
                        };
                    }
                });

                return {
                    ...graph,
                    options: newLabelOptions,
                };
            } else {
                // Do not change options for other graphs
                return {
                    ...graph,
                };
            }
        });
        this.labelOptions.next(newGraphLabels);
    }
}
