import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DefaultFilter} from '@mominsamir/ngx-smart-table';
import {TimeUnlocalizePipe} from '@theme/pipes';
import * as moment from 'moment';

@Component({
    template: `
        <nb-form-field>
            <input
                nbInput
                fullWidth
                placeholder="{{ column.title }}"
                [formControl]="inputControl"
                [nbDatepicker]="rangepicker"
                class="date-filter"
            />
            <nb-rangepicker #rangepicker></nb-rangepicker>
            <nb-icon nbSuffix icon="calendar-outline"></nb-icon>
        </nb-form-field>
    `,
})
export class DateFilterComponent extends DefaultFilter implements OnInit, OnChanges {
    constructor(private unlocalizeTimePipe: TimeUnlocalizePipe) {
        super();
    }

    inputControl: FormControl = new FormControl();

    ngOnInit() {
        this.inputControl.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(this.delay))
            .subscribe((value: string) => {
                if (value == null) {
                    this.query = '';
                    this.setFilter();
                } else {
                    const startDateRaw = moment(this.inputControl.value.start);
                    // End date is set to selected date + 1 day (i.e. 12:00am next day); includes entire end day.
                    const endDateRaw = moment(this.inputControl.value.end).add(1, 'days');

                    const startDate = this.unlocalizeTimePipe.transform(startDateRaw);
                    const endDate = this.unlocalizeTimePipe.transform(endDateRaw);

                    this.query = startDate + '|' + endDate;
                    this.setFilter();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.inputControl.setValue(this.inputControl.value);
        }
    }
}
