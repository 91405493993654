<div class="header-settings-container">
    <!-- Section 1: interface settings -->
    <div class="menu-section">
        <label class="display-flex align-items-center justify-content-between">
            DARK MODE
            <ngx-theme-switcher> </ngx-theme-switcher>
        </label>
        <!--<label [routerLink]="['/user/edit/preferences']">PREFERENCES</label>-->
        <label [routerLink]="['/user-management/user/profile-settings']">PROFILE SETTINGS</label>
    </div>

    <!-- Section 2: profile and knowledge base -->
    <div class="menu-section">
        <label (click)="navigateUrl()" class="label-with-navigation"> KNOWLEDGE BASE </label>
    </div>

    <!-- Section 3: organization and logout -->
    <div class="menu-section">
        <ngx-icon-box title="LOG OUT" (click)="logout()" icon="power"> </ngx-icon-box>
    </div>

    <!-- Section 4: about -->
    <div class="menu-section">
        <ngx-system-about class="w-100"></ngx-system-about>
    </div>
</div>
