import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ChartDownloadData, ECHARTS_TYPE} from './chart-data-download-service';
import {BehaviorSubject} from 'rxjs';
import {GraphLabel} from '@core/interfaces/common/pages';
import {ECharts} from 'echarts';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Component({
    selector: 'ngx-chart-settings',
    templateUrl: './chart-settings.component.html',
    styleUrls: ['./chart-settings.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChartSettingsComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Combined chart settings component including:
 * - Unit selection
 * - Data download (CSV)
 * Unit selection is disabled by default; enabled if [unitOptions, formControlName] is provided to this component
 * Label selection is disabled by default; enabled if [labelOptions, graphId] is provided to this component
 * Data download is enabled by default; disabled if either [disableDataDownload] is true, or if no data is provided
 */
export class ChartSettingsComponent extends Unsubscribable implements ControlValueAccessor, OnChanges {
    // Data binding for unit selector functionality
    @Input() unitOptions: string[];
    // Data binding for chart label options
    @Input() graphId: string; // used for indexing labelOptions, unitOptions
    @Input() labelOptions: BehaviorSubject<GraphLabel[]>;
    /*
     * Data download component is enabled by default, and expects chartOptions and chartType data sources
     * - Optional input to disable download button
     * - Optional input of custom CSV file name
     * - Optional input of custom data object
     */
    @Input() disableDataDownload?: boolean;
    @Input() downloadFileName?: string;
    @Input() chartOptions?: any;
    @Input() chartType?: ECHARTS_TYPE;
    @Input() preparedDownloadData?: ChartDownloadData;
    // chartInstance: for image download options
    @Input() chartInstance?: ECharts;
    @Input() placement?: string = 'bottom';

    private defaultUnitOptions: string[] = []; // empty by default
    public fullUnitOptions: {value: string; label: string}[];
    private _unitItem: any;

    propagateChange: any = () => {};

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    get unitItem(): any {
        return this._unitItem;
    }

    set unitItem(val: any) {
        this._unitItem = val;
    }

    writeValue(value: any): void {
        if (value) {
            this.unitItem = value;
        }
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.propagateChange(this.unitItem);
        this.cd.detectChanges();
        this.cd.markForCheck();
    }

    registerOnTouched() {}

    changed(value) {
        this.unitItem = {...this.unitItem, unit: value};
        this.propagateChange(this.unitItem);
        this.cd.markForCheck();
    }
}
