import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ngx-tab-buttons',
    templateUrl: './tab-buttons.component.html',
    styleUrls: ['./tab-buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabButtonsComponent {
    @Input() options: {code: string; label: string}[];
    @Input() selected: string;
    @Output() valueChange = new EventEmitter();

    constructor() {}

    public onChange(selected: string) {
        this.valueChange.emit(selected);
    }

    public isButtonSelected(code: string): boolean {
        return code === this.selected;
    }
}
