import * as moment from 'moment';

export enum DateType {
    DATE,
    STRING,
    EPOCHE,
}

export class FormatsService {
    static EMAIL_PATTERN = "\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*";
    static ONE_NUMBER_PATTERN = '.*[0-9].*';
    static ONE_UPPERCASE_LETTER_PATTERN = '.*[A-Z].*';
    static ONE_LOWERCASE_LETTER_PATTERN = '.*[a-z].*';
    static PASS_LENGTH_PATTERN = '^\\w{8,50}$';
    static DEFAULT_DATETIME_FORMAT = 'yyyy-MM-DD hh:mm A';
    static DEFAULT_DATE_FORMAT = 'yyyy-MM-DD';

    // TODO: change all instance of getFormmatedValue to prepareValue?
    public static getFormattedValue(value: number, full: boolean = false): string[] {
        // Changed B, M, K from "0" and "1" to "1" and "2"
        if (value >= 10000000000) {
            return [parseFloat((value / 1000000000).toFixed(1)).toString(), `${full ? 'Billion' : 'B'}`];
        } else if (value >= 1000000000) {
            return [parseFloat((value / 1000000000).toFixed(2)).toString(), `${full ? 'Billion' : 'B'}`];
        } else if (value >= 10000000) {
            return [parseFloat((value / 1000000).toFixed(1)).toString(), `${full ? 'Million' : 'M'}`];
        } else if (value >= 1000000) {
            return [parseFloat((value / 1000000).toFixed(2)).toString(), `${full ? 'Million' : 'M'}`];
        } else if (value >= 10000) {
            return [parseFloat((value / 1000).toFixed(1)).toString(), `${full ? 'Thousand' : 'K'}`];
        } else if (value >= 1000) {
            return [parseFloat((value / 1000).toFixed(2)).toString(), `${full ? 'Thousand' : 'K'}`];
        } else if (value >= 100) {
            return [parseFloat(value.toFixed(0)).toString(), ''];
        } else if (value >= 10) {
            return [parseFloat(value.toFixed(1)).toString(), ''];
        } else if (value >= 1) {
            return [parseFloat(value.toFixed(2)).toString(), ''];
        } else {
            return [parseFloat(value.toFixed(3)).toString(), ''];
        }
    }

    /*
     * Updated, try to use for all formatting going forward
     */
    public static prepareValue(value: number, prefix: string, suffix: string, full: boolean = false): string {
        const renderedValue = (count, qual) => `${prefix}${count}${qual}${suffix}`;

        // TODO: this needs to be made more expandable, but works for now
        // If the unit is length-based in meters (m), round everything to thousands and use lower-case 'k'
        if (suffix != null && suffix === 'm') {
            if (Math.abs(value) >= 10000000000) {
                return renderedValue((value / 1000).toFixed(0), `${full ? 'Thousand' : 'k'}`);
            } else if (Math.abs(value) >= 1000000000) {
                return renderedValue((value / 1000).toFixed(0), `${full ? 'Thousand' : 'k'}`);
            } else if (Math.abs(value) >= 10000000) {
                return renderedValue((value / 1000).toFixed(0), `${full ? 'Thousand' : 'k'}`);
            } else if (Math.abs(value) >= 1000000) {
                return renderedValue((value / 1000).toFixed(0), `${full ? 'Thousand' : 'k'}`);
            } else if (Math.abs(value) >= 10000) {
                return renderedValue((value / 1000).toFixed(1), `${full ? 'Thousand' : 'k'}`);
            } else if (Math.abs(value) >= 1000) {
                return renderedValue((value / 1000).toFixed(2), `${full ? 'Thousand' : 'k'}`);
            } else if (Math.abs(value) >= 100) {
                return renderedValue(value.toFixed(0), '');
            } else if (Math.abs(value) >= 10) {
                return renderedValue(value.toFixed(1), '');
            } else if (Math.abs(value) >= 1) {
                return renderedValue(value.toFixed(2), '');
            } else {
                return renderedValue(value.toFixed(3), '');
            }
        } else {
            if (Math.abs(value) >= 10000000000) {
                return renderedValue((value / 1000000000).toFixed(0), `${full ? 'Billion' : 'B'}`);
            } else if (Math.abs(value) >= 1000000000) {
                return renderedValue((value / 1000000000).toFixed(1), `${full ? 'Billion' : 'B'}`);
            } else if (Math.abs(value) >= 10000000) {
                return renderedValue((value / 1000000).toFixed(0), `${full ? 'Million' : 'M'}`);
            } else if (Math.abs(value) >= 1000000) {
                return renderedValue((value / 1000000).toFixed(1), `${full ? 'Million' : 'M'}`);
            } else if (Math.abs(value) >= 10000) {
                return renderedValue((value / 1000).toFixed(1), `${full ? 'Thousand' : 'K'}`);
            } else if (Math.abs(value) >= 1000) {
                return renderedValue((value / 1000).toFixed(2), `${full ? 'Thousand' : 'K'}`);
            } else if (Math.abs(value) >= 100) {
                return renderedValue(value.toFixed(0), '');
            } else if (Math.abs(value) >= 10) {
                return renderedValue(value.toFixed(1), '');
            } else if (Math.abs(value) >= 1) {
                return renderedValue(value.toFixed(2), '');
            } else {
                return renderedValue(value.toFixed(3), '');
            }
        }
    }

    public static prepareCostValue(value, prefix, suffix) {
        const renderedValue = (count, qual) => `${prefix}${count}${qual}${suffix}`;

        if (value >= 10000000000) {
            return renderedValue((value / 1000000000).toFixed(1), 'B');
        } else if (value >= 1000000000) {
            return renderedValue((value / 1000000000).toFixed(2), 'B');
        } else if (value >= 1000000) {
            return renderedValue((value / 1000000).toFixed(1), 'M');
        } else if (value >= 1000000) {
            return renderedValue((value / 1000000).toFixed(2), 'M');
        } else if (value >= 10000) {
            return renderedValue((value / 1000).toFixed(1), 'K');
        } else if (value >= 1000) {
            return renderedValue((value / 1000).toFixed(2), 'K');
        } else {
            return renderedValue(value.toFixed(2), '');
        }
    }

    public static prepareChartLabel(value, prefix, suffix) {
        if (value >= 0 && value < 1000) {
            return `${prefix}${value} ${suffix}`;
        } else if (value >= 1000 && value < 1000000) {
            return `${prefix}${value / 1000}K ${suffix}`;
        } else {
            return `${prefix}${value / 1000000}M ${suffix}`;
        }
    }

    public static prepareIntegerNumber(value) {
        if (value >= 0 && value < 1000) {
            return `${value}`;
        } else if (value >= 1000 && value < 10000) {
            return `${(value / 1000).toFixed(2)}K`;
        } else if (value >= 10000 && value < 100000) {
            return `${(value / 1000).toFixed(1)}K`;
        } else {
            return `${value / 1000000}M`;
        }
    }

    public static preparePercentageLabelFromDecimal(val: number, decimals: number) {
        const pVal = val * 100.0;
        if (pVal < 10) {
            return pVal.toFixed(decimals) + '%';
        } else {
            return pVal.toFixed(decimals - 1) + '%';
        }
    }

    /*
     * Map a codified string to a title format
     * e.g. data_load --> Data Load
     */
    public static mapCodeToTitle(code: string) {
        return code
            .split('_')
            .map((str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase())
            .join(' ');
    }

    /*
     * Dates
     */
    /* @Deprecated - see localtime.pipe.ts */
    public static prepareDateString(val: any, inType: DateType, outFormat: string) {
        switch (inType) {
            case DateType.DATE:
                return moment(val).format(outFormat);
            case DateType.STRING:
                const epocheMs = Date.parse(val);
                return FormatsService.prepareDateString(epocheMs, DateType.EPOCHE, outFormat);
            case DateType.EPOCHE:
                const date = new Date(val);
                return moment(date).format(outFormat);
        }
    }

    public static parseDateToEpoche(val: any) {
        return moment(val).valueOf();
    }
}
