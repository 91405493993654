export class ObjectsHelper {
    public static cloneObject(obj) {
        if (null == obj || 'object' !== typeof obj) return obj;
        const copy = obj.constructor();
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = this.cloneObject(obj[attr]);
        }
        return copy;
    }
}
