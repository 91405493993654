import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NbIconLibraries} from '@nebular/theme';
import {ENGIN_ICONS} from './engin-icons/engin-icons.model';

@Component({
    selector: 'ngx-app',
    template: '<router-outlet></router-outlet>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    constructor(iconLibraries: NbIconLibraries) {
        iconLibraries.registerSvgPack('engin', ENGIN_ICONS);
    }
}
