import {Injectable} from '@angular/core';
import {AssetDetailsApi} from '../api/asset-details.api';
import {AssetDetailsService, AssetStaticInfo} from '@core/interfaces/engin/asset-details';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class AssetDetailsServiceImpl extends AssetDetailsService {
    constructor(private api: AssetDetailsApi) {
        super();
    }

    getAssetInfoDetails(assetId: string): Observable<APIResponse<AssetStaticInfo[]>> {
        return this.api.getAssetInfoDetails(assetId);
    }
}
