import {Observable} from 'rxjs';
import {AboutSystemInfo} from '@core/interfaces/common/support';
import {SupportStore} from '@store/common/support.store';
import {Injectable} from '@angular/core';

@Injectable()
export class SystemInfoDialogViewModel {
    constructor(private supportStore: SupportStore) {}

    readonly aboutSystemInfo$: Observable<AboutSystemInfo> = this.supportStore.aboutSystemInfo$;
}
