import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {filter, map, shareReplay, startWith, tap} from 'rxjs/operators';
import {DisplaySettings, Page, PageGroup, PagesService, PageWithLogi} from '@core/interfaces/common/pages';
import {NavigationEnd, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NbAclService} from '@nebular/security';

@Injectable()
export class PagesStore implements OnDestroy {
    readonly pagesInfo$ = this.pagesData.getLogiComponentsSettings().pipe(shareReplay(1));

    readonly pageGroups$ = this.pagesData.getPages().pipe(
        map((groups) => {
            return groups.sort((a, b) => {
                return a.order - b.order;
            });
        }),
        tap((groups) => {
            const pagesWithPermissions: Page[] = [];
            groups.forEach((group) => {
                this.getPagesWithPermissions(group, pagesWithPermissions);
            });

            this.aclService.allow(
                'user',
                'view',
                pagesWithPermissions
                    .filter((page) => page.roleLevel === 'user' || page.roleLevel === undefined)
                    .map((page) => page.link),
            );

            this.aclService.allow(
                'admin',
                'view',
                pagesWithPermissions.filter((page) => page.roleLevel === 'admin').map((page) => page.link),
            );

            this.aclService.allow(
                'superadmin',
                'view',
                pagesWithPermissions.filter((page) => page.roleLevel === 'superadmin').map((page) => page.link),
            );
        }),
        shareReplay(1),
    );
    // TODO: combined all info into pagesGroups on MongoDB level
    readonly dashboardCards$: Observable<PageGroup[]> = combineLatest<Observable<PageGroup[]>, Observable<PageGroup[]>>(
        [this.pageGroups$, this.pagesData.getDashboardInfo()],
    ).pipe(
        map(([groups, cardsInfo]: [PageGroup[], PageGroup[]]) => {
            return cardsInfo;
            // return groups;
        }),
        shareReplay(1),
    );
    private currentUrl = new BehaviorSubject<string>('');
    readonly currentUrl$ = this.currentUrl.asObservable().pipe(filter((url) => !!url));
    readonly logiComponentName$: Observable<string> = combineLatest<Observable<string>, Observable<PageWithLogi[]>>([
        this.currentUrl$,
        this.pagesInfo$,
    ]).pipe(
        map(([url, pages]: [string, PageWithLogi[]]) => {
            const page = pages.find((item) => url.indexOf(item.link) >= 0 && item.useLogi);
            return page ? page.logiUrl : null;
        }),
    );

    private currentUrlWithPageGroups$ = combineLatest<Observable<string>, Observable<PageGroup[]>>([
        this.currentUrl$,
        this.pageGroups$,
    ]);

    /*
     * Four level hierarchy of navigation:
     * 1. Group (modules, tools & add-ons, archive, configuration)
     * 2. Feature (Analyzer, Study History)
     * 3. Page (EOL Metrics, Investment Plan)
     * 4. Tab (Economic (in EOL Metrics, Demographics), Health (in Asset Details))
     */
    readonly activeGroup$: Observable<PageGroup> = this.currentUrlWithPageGroups$.pipe(
        map(([url, pagesGroups]: [string, PageGroup[]]) => {
            try {
                return []
                    .concat(...pagesGroups)
                    .find((group) =>
                        group.children.map((page) => (url.indexOf(page.link) >= 0 ? 1 : 0)).reduce((a, b) => a + b, 0),
                    );
            } catch (err) {
                return null;
            }
        }),
    );

    readonly activeFeature$: Observable<Page> = this.currentUrlWithPageGroups$.pipe(
        map(([url, pagesGroups]: [string, PageGroup[]]) => {
            try {
                return []
                    .concat(...pagesGroups.map((group) => group.children))
                    .find((page) => url.indexOf(page.link) >= 0);
            } catch (err) {
                return null;
            }
        }),
    );
    readonly activePage$: Observable<Page> = this.currentUrlWithPageGroups$.pipe(
        map(([url, pagesGroups]: [string, PageGroup[]]) => {
            try {
                return []
                    .concat(...pagesGroups.map((group) => group.children))
                    .find((page) => url.indexOf(page.link) >= 0)
                    .children.find((page) => url.indexOf(page.link) >= 0);
            } catch (err) {
                return null;
            }
        }),
    );
    readonly activeTab$: Observable<Page> = this.currentUrlWithPageGroups$.pipe(
        map(([url, pagesGroups]: [string, PageGroup[]]) => {
            try {
                return []
                    .concat(
                        ...[]
                            .concat(...pagesGroups.map((group) => group.children))
                            .find((page) => url.indexOf(page.link) >= 0)
                            .children.map((group) => group.children || []),
                    )
                    .find((page) => url.indexOf(page.link) >= 0);
            } catch (err) {
                return null;
            }
        }),
    );

    private routerEventsSubscription: Subscription;

    constructor(
        private pagesData: PagesService,
        private router: Router,
        private aclService: NbAclService,
        private fb: FormBuilder,
    ) {
        this.routerEventsSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => (event as NavigationEnd).urlAfterRedirects),
                shareReplay(1),
            )
            .subscribe((url) => {
                this.currentUrl.next(url);
                try {
                    this.loadPagesSettings();
                } catch (err) {}
            });
    }

    save(pages: PageWithLogi[]): Observable<boolean> {
        return this.pagesData.updateLogiComponentsSettings(pages);
    }

    ngOnDestroy(): void {
        this.routerEventsSubscription.unsubscribe();
    }

    public pagesFormGroup = this.fb.group({});

    private pagesChangeSubscription: Subscription;

    /*
    readonly logiComponentName$: Observable<string> =
      combineLatest<Observable<string>, Observable<PageWithLogi[]>>([
        this.currentUrl$,
        this.pages$,
      ]).pipe(
        map(([url, pages]) => {
          const page = pages.find((item) => (url.indexOf(item.link) >= 0 && item.useLogi));
          return page ? page.logiComponentName : null;
        }),
      );
     */

    private pagesFormGroupValue = new BehaviorSubject(null);
    readonly currentDisplay$: Observable<DisplaySettings> = combineLatest<
        Observable<PageGroup>,
        Observable<Page>,
        Observable<Page>,
        Observable<Page>,
        Observable<any>
    >([
        this.activeGroup$,
        this.activeFeature$,
        this.activePage$,
        this.activeTab$,
        this.pagesFormGroupValue.asObservable(),
    ]).pipe(
        map(([group, feature, page, tab, graphs]) => {
            return this.mapPagesFormGroupValue(group, feature, page, tab, graphs);
        }),
        shareReplay(1),
    );

    private pagesSettings = new BehaviorSubject<DisplaySettings>(null);
    readonly pagesSettings$: Observable<DisplaySettings> = this.pagesSettings.asObservable().pipe(
        filter((settings) => settings != null),
        tap((settings) => {
            this.pagesChangeSubscription && this.pagesChangeSubscription.unsubscribe();
            this.initPagesSettings(settings);
        }),
        shareReplay(1),
    );

    public loadPagesSettings() {
        combineLatest<Observable<PageGroup>, Observable<Page>, Observable<Page>, Observable<Page>>([
            this.activeGroup$,
            this.activeFeature$,
            this.activePage$,
            this.activeTab$,
        ])
            .pipe(
                map(([group, feature, page, tab]) => {
                    return {
                        group: group,
                        feature: feature,
                        page: page,
                        tab: tab,
                    };
                }),
                filter((value) => {
                    return !this.isEquivalent(this.pagesFormGroupValue.value, value);
                }),
            )
            .subscribe((value) => {
                try {
                    const settings = {
                        id:
                            (value.feature ? value.feature.id : '') +
                            (value.page ? value.page.id : '') +
                            (value.tab ? value.tab.id : ''),
                        groupId: value.group ? value.group.id : '',
                        featureId: value.feature ? value.feature.id : '',
                        pageId: value.page ? value.page.id : '',
                        tabId: value.tab ? value.tab.id : '',
                        graphList: value.tab
                            ? value.tab.graphList
                                ? value.tab.graphList
                                : value.page
                                ? value.page.graphList
                                    ? value.page.graphList
                                    : null
                                : null
                            : value.page
                            ? value.page.graphList
                                ? value.page.graphList
                                : null
                            : null,
                    };
                    this.updateSettings(settings);
                } catch (err) {}
            });
    }

    public updateSettings(settings: DisplaySettings, settingName?: string) {
        let resultSettings: DisplaySettings = settings;
        if (settingName) {
            resultSettings = {
                ...this.pagesSettings.value,
                [settingName]: settings[settingName].map((item) => {
                    return {
                        ...item,
                        enabled: item.selected,
                    };
                }),
            };
        }
        this.pagesSettings.next(resultSettings);
    }

    // reset units??

    public setGraphsFormGroup(graphs: FormGroup) {
        if (this.graphsFormGroup) {
            // TODO: Remove and reset the pages form group: may want to save in the future?
            this.pagesFormGroup.removeControl('graphs');
            this.pagesFormGroup.setControl('graphs', graphs);
        } else {
            this.pagesFormGroup.setControl('graphs', graphs);
        }
    }

    get graphsFormGroup(): FormGroup {
        return this.pagesFormGroup.get('graphs') as FormGroup;
    }

    private subscribeOnChanges() {
        this.pagesChangeSubscription = this.graphsFormGroup.valueChanges
            .pipe(startWith({...this.graphsFormGroup.value}))
            .pipe(
                map((graphs) => {
                    return graphs;
                }),
                tap((value) => {
                    if (this.pagesFormGroupValue.value === null) {
                        this.pagesFormGroupValue.next(value);
                    }
                }),
                filter((value) => {
                    return !this.isEquivalent(this.pagesFormGroupValue.value, value);
                }),
            )
            .subscribe((value) => {
                this.pagesFormGroupValue.next(value);
            });
    }

    private isEquivalent(val1: any, val2: any): boolean {
        if (val1 instanceof Object && val2 instanceof Object) {
            const val1props = Object.keys(val1);
            const val2props = Object.keys(val2);

            if (val1props.length !== val2props.length) return false;

            for (let i = 0; i < val1props.length; i++) {
                if (val1props[i] === 'enabled') continue;
                const val2Prop = val2props.find((item) => item === val1props[i]);
                if (!val2Prop) return false;

                if (!this.isEquivalent(val1[val2Prop], val2[val2Prop])) return false;
            }
            return true;
        }
        return val1 === val2;
    }

    private mapPagesFormGroupValue(
        group: PageGroup,
        feature: Page,
        page: Page,
        tab: Page,
        graphs: any,
    ): DisplaySettings {
        // Graphs
        const graphList = [];
        if (graphs) {
            const iter = Object.keys(graphs);
            iter.forEach((item) => {
                graphList.push({
                    graphId: graphs[item].graphId,
                    unit: graphs[item].unit,
                });
            });
        }
        // For all custom settings, default setting state is always "false".
        // Then work up the hierarchy tab < page < feature < group to find the nearest value.
        let dataAuditWidgetHidden = false;
        if (null != tab) {
            dataAuditWidgetHidden =
                tab.dataAuditWidgetHidden || page.dataAuditWidgetHidden || feature.dataAuditWidgetHidden;
        } else if (null != page) {
            dataAuditWidgetHidden = page.dataAuditWidgetHidden || feature.dataAuditWidgetHidden;
        } else if (null != feature) {
            dataAuditWidgetHidden = feature.dataAuditWidgetHidden;
        }

        return {
            id: (feature ? feature.id : '') + (page ? page.id : '') + (tab ? tab.id : ''),
            groupId: group ? group.id : '',
            featureId: feature ? feature.id : '',
            pageId: page ? page.id : '',
            tabId: tab ? tab.id : '',
            graphList: graphList,
            dataAuditWidgetHidden: dataAuditWidgetHidden,
        };
    }

    private initPagesSettings(settings: DisplaySettings) {
        this.initPages(settings.graphList);
        this.subscribeOnChanges();
    }

    private initPages(initialGraphs: any[]) {
        const graphs = this.fb.group({});

        if (initialGraphs !== null && initialGraphs !== undefined) {
            initialGraphs.forEach((item) => {
                graphs.setControl(item.graphId, this.fb.control(item));
            });
        }
        this.setGraphsFormGroup(graphs);
    }

    private getPagesWithPermissions(page: PageGroup | Page, result: any[]) {
        if (page.children) {
            for (let i = 0; i < page.children.length; i++) {
                this.getPagesWithPermissions(page.children[i], result);
            }
        } else {
            result.push(page);
        }
    }

    public getRedirect(id: string): Observable<string> | string {
        return this.pageGroups$.pipe(
            map((pageGroups: Page[]) => {
                pageGroups.forEach((group) => {
                    if (group.id === id) {
                        return group.redirectTo;
                    } else {
                        if (group.children) {
                            group.children.forEach((page) => {
                                if (page.id === id) {
                                    return page.redirectTo;
                                }
                            });
                        }
                        return '';
                    }
                });
                return '';
            }),
        );
    }

    // Get Optimizer popout configuration once
    public optimizerControlPanelConfig$: Observable<number> = this.pageGroups$.pipe(
        map((groups: PageGroup[]) => {
            const moduleGroup = groups.filter((g) => g.id === 'modules');
            const optimizerPage = moduleGroup ? moduleGroup[0].children.filter((c) => c.id === 'optimizer') : null;
            const config = optimizerPage ? optimizerPage[0].popoutPanelConfiguration : -1;
            return config;
        }),
    );
}
