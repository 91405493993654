import {Injectable} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {map} from 'rxjs/operators';
import {AlertStatusEnum} from '@core/interfaces/models/live';

@Injectable()
export class ColorsService {
    readonly colorsMap$ = this.themeService.getJsTheme().pipe(
        map((config) => {
            const colorsMap = [];
            colorsMap['Past Economic EOL'] = config.variables.chartColors[0];
            colorsMap['Very Poor'] = config.variables.chartColors[0];
            colorsMap['Past TUL'] = config.variables.chartColors[0];
            colorsMap['Approaching Economic EOL'] = config.variables.chartColors[1];
            colorsMap['Poor'] = config.variables.chartColors[1];
            colorsMap['Approaching TUL'] = config.variables.chartColors[1];
            colorsMap['Fair'] = config.variables.chartColors[2];
            colorsMap['Good'] = config.variables.chartColors[3];
            colorsMap['Not Near EOL'] = config.variables.chartColors[4];
            colorsMap['Not Near TUL'] = config.variables.chartColors[4];
            colorsMap['Very Good'] = config.variables.chartColors[4];
            colorsMap['N/A'] = '#9fa9bd';

            colorsMap['primaryFontColor'] = config.variables.fontColor;
            colorsMap['hintFontColor'] = config.variables.fontHintColor;
            colorsMap['Degraded'] = config.variables.chartColors[0];
            colorsMap['Undegraded'] = config.variables.chartColors[4];

            colorsMap['Annual Cost'] = config.variables.chartColors[0];
            colorsMap['Annualized NPV'] = config.variables.chartColors[4];
            colorsMap['Minimum EAC'] = config.variables.chartColors[5];

            colorsMap['Effective Age'] = config.variables.chartColors[9];
            colorsMap['Hazard Rate'] = config.variables.chartColors[0];
            colorsMap['Calendar Age'] = config.variables.chartColors[5];
            colorsMap['backgroundBasicColor1'] = config.variables.backgroundBasicColor1;
            colorsMap['backgroundChartColor'] = config.variables.backgroundChartColor;
            colorsMap['primaryColor'] = config.variables.chartColors[0];
            colorsMap['Probability Density'] = config.variables.chartColors[7];
            colorsMap['Survival Rate'] = config.variables.chartColors[5];
            colorsMap['New Asset'] = config.variables.chartColors[9];
            colorsMap['Existing Asset'] = config.variables.chartColors[0];

            colorsMap['With Intervention'] = config.variables.chartColors[0];
            colorsMap['Without Intervention'] = config.variables.chartColors[5];
            colorsMap['Optimal Intervention'] = config.variables.chartColors[4];

            colorsMap['Valid'] = '#649B0C';
            colorsMap['Invalid'] = 'transparent';

            colorsMap['With Intervention'] = config.variables.chartColors[0];
            colorsMap['Without Intervention'] = config.variables.chartColors[5];
            colorsMap['Optimal Intervention'] = config.variables.chartColors[4];

            colorsMap['Current FP'] = config.variables.chartColors[2];
            colorsMap['Five Year FP'] = config.variables.chartColors[1];
            colorsMap['Ten Year FP'] = config.variables.chartColors[0];

            colorsMap['Financial Impact (Existing)'] = config.variables.chartColors[6];
            colorsMap['Env Impact (Existing)'] = config.variables.chartColors[7];
            colorsMap['Customer Impact (Existing)'] = config.variables.chartColors[8];
            colorsMap['Collateral Impact (Existing)'] = config.variables.chartColors[10];

            colorsMap['Financial Impact (New)'] = config.variables.chartColors[14];
            colorsMap['Env Impact (New)'] = config.variables.chartColors[15];
            colorsMap['Customer Impact (New)'] = config.variables.chartColors[16];
            colorsMap['Collateral Impact (New)'] = config.variables.chartColors[17];

            colorsMap['Overhead Transformer'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer (Proactive)'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer (Reactive)'] = config.variables.chartColors[18];
            colorsMap['Aerial Transformer'] = config.variables.chartColors[5];
            colorsMap['Aerial Transformer (Proactive)'] = config.variables.chartColors[5];
            colorsMap['Aerial Transformer (Reactive)'] = config.variables.chartColors[18];
            colorsMap['Pole'] = config.variables.chartColors[7];
            colorsMap['Pole (Proactive)'] = config.variables.chartColors[7];
            colorsMap['Pole (Reactive)'] = config.variables.chartColors[15];
            colorsMap['Overhead Switch'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch (Proactive)'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch (Reactive)'] = config.variables.chartColors[19];
            colorsMap['Network Transformer'] = config.variables.chartColors[10];
            colorsMap['Network Transformer (Proactive)'] = config.variables.chartColors[10];
            colorsMap['Network Transformer (Reactive)'] = config.variables.chartColors[17];
            colorsMap['Underground Transformer'] = config.variables.chartColors[8];
            colorsMap['Underground Transformer (Proactive)'] = config.variables.chartColors[8];
            colorsMap['Underground Transformer (Reactive)'] = config.variables.chartColors[16];
            colorsMap['Underground Cable'] = config.variables.chartColors[1];
            colorsMap['Underground Cable (Proactive)'] = config.variables.chartColors[1];
            colorsMap['Underground Cable (Reactive)'] = config.variables.chartColors[20];
            colorsMap['Switchgear'] = config.variables.chartColors[6];
            colorsMap['Switchgear (Proactive)'] = config.variables.chartColors[6];
            colorsMap['Switchgear (Reactive)'] = config.variables.chartColors[14];
            colorsMap['Switch Cubicle'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle (Proactive)'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle (Reactive)'] = config.variables.chartColors[21];
            colorsMap['Circuit Breaker'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker (Proactive)'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker (Reactive)'] = config.variables.chartColors[22];
            colorsMap['Power Transformer'] = config.variables.chartColors[13];
            colorsMap['Power Transformer (Proactive)'] = config.variables.chartColors[13];
            colorsMap['Power Transformer (Reactive)'] = config.variables.chartColors[23];
            colorsMap['Line Structure (Proactive)'] = config.variables.chartColors[24];
            colorsMap['Line Structure (Reactive)'] = config.variables.chartColors[25];
            colorsMap['Line Conductor (Proactive)'] = config.variables.chartColors[2];
            colorsMap['Line Conductor (Reactive)'] = config.variables.chartColors[3];

            colorsMap['Total Proactive'] = config.variables.chartColors[24];
            colorsMap['Total Reactive'] = config.variables.chartColors[25];

            colorsMap['SAIDI'] = config.variables.chartColors[7];
            colorsMap['SAIFI'] = config.variables.chartColors[3];
            colorsMap['CAIDI'] = config.variables.chartColors[0];
            colorsMap['SPENDING'] = config.variables.chartColors[7];

            colorsMap['Acceptable Risk(RTF)'] = config.variables.chartColors[6];
            colorsMap['Excess Risk(RTF)'] = config.variables.chartColors[5];
            colorsMap['Acceptable Risk(WP)'] = config.variables.chartColors[14];
            colorsMap['Excess Risk(WP)'] = config.variables.chartColors[18];

            /**
             * Intermittent variables, some mapping by name/id pairs
             */
            colorsMap['OH_TX'] = config.variables.chartColors[5];
            colorsMap['ohtx'] = config.variables.chartColors[5];
            colorsMap['ohtx_total'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer_total'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer_proactive'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer_reactive'] = config.variables.chartColors[18];
            colorsMap['Aerial Transformer_total'] = config.variables.chartColors[5];
            colorsMap['Aerial Transformer_proactive'] = config.variables.chartColors[5];
            colorsMap['Aerial Transformer_reactive'] = config.variables.chartColors[18];
            colorsMap['Overhead Transformer_wp'] = config.variables.chartColors[5];
            colorsMap['Overhead Transformer_rtf'] = config.variables.chartColors[18];
            colorsMap['Aerial Transformer_wp'] = config.variables.chartColors[5];
            colorsMap['Aerial Transformer_rtf'] = config.variables.chartColors[18];
            colorsMap['POLE'] = config.variables.chartColors[7];
            colorsMap['pole'] = config.variables.chartColors[7];
            colorsMap['pole_total'] = config.variables.chartColors[7];
            colorsMap['Pole_total'] = config.variables.chartColors[7];
            colorsMap['Pole_proactive'] = config.variables.chartColors[7];
            colorsMap['Pole_reactive'] = config.variables.chartColors[15];
            colorsMap['Pole_wp'] = config.variables.chartColors[7];
            colorsMap['Pole_rtf'] = config.variables.chartColors[15];
            colorsMap['OH_SW'] = config.variables.chartColors[3];
            colorsMap['ohsw'] = config.variables.chartColors[3];
            colorsMap['ohsw_total'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch_total'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch_proactive'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch_reactive'] = config.variables.chartColors[19];
            colorsMap['Overhead Switch_wp'] = config.variables.chartColors[3];
            colorsMap['Overhead Switch_rtf'] = config.variables.chartColors[19];
            colorsMap['NTX'] = config.variables.chartColors[10];
            colorsMap['ntx'] = config.variables.chartColors[10];
            colorsMap['ntx_total'] = config.variables.chartColors[10];
            colorsMap['Network Transformer_total'] = config.variables.chartColors[10];
            colorsMap['Network Transformer_proactive'] = config.variables.chartColors[10];
            colorsMap['Network Transformer_reactive'] = config.variables.chartColors[17];
            colorsMap['Network Transformer_wp'] = config.variables.chartColors[10];
            colorsMap['Network Transformer_rtf'] = config.variables.chartColors[17];
            colorsMap['UG_TX'] = config.variables.chartColors[8];
            colorsMap['ugtx'] = config.variables.chartColors[8];
            colorsMap['ugtx_total'] = config.variables.chartColors[8];
            colorsMap['Underground Transformer_total'] = config.variables.chartColors[8];
            colorsMap['Underground Transformer_proactive'] = config.variables.chartColors[8];
            colorsMap['Underground Transformer_reactive'] = config.variables.chartColors[16];
            colorsMap['Underground Transformer_wp'] = config.variables.chartColors[8];
            colorsMap['Underground Transformer_rtf'] = config.variables.chartColors[16];
            colorsMap['UG_CABLE'] = config.variables.chartColors[1];
            colorsMap['ugcable'] = config.variables.chartColors[1];
            colorsMap['ugcable_total'] = config.variables.chartColors[1];
            colorsMap['Underground Cable_total'] = config.variables.chartColors[1];
            colorsMap['Underground Cable_proactive'] = config.variables.chartColors[1];
            colorsMap['Underground Cable_reactive'] = config.variables.chartColors[20];
            colorsMap['Underground Cable_wp'] = config.variables.chartColors[1];
            colorsMap['Underground Cable_rtf'] = config.variables.chartColors[20];
            colorsMap['SWGR'] = config.variables.chartColors[6];
            colorsMap['swgr'] = config.variables.chartColors[6];
            colorsMap['swgr_total'] = config.variables.chartColors[6];
            colorsMap['Switchgear_total'] = config.variables.chartColors[6];
            colorsMap['Switchgear_proactive'] = config.variables.chartColors[6];
            colorsMap['Switchgear_reactive'] = config.variables.chartColors[14];
            colorsMap['Switchgear_wp'] = config.variables.chartColors[6];
            colorsMap['Switchgear_rtf'] = config.variables.chartColors[14];
            colorsMap['UG_SW'] = config.variables.chartColors[11];
            colorsMap['ugsw'] = config.variables.chartColors[11];
            colorsMap['ugsw_total'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle_total'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle_proactive'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle_reactive'] = config.variables.chartColors[21];
            colorsMap['Switch Cubicle_wp'] = config.variables.chartColors[11];
            colorsMap['Switch Cubicle_rtf'] = config.variables.chartColors[21];
            colorsMap['CB'] = config.variables.chartColors[12];
            colorsMap['cb'] = config.variables.chartColors[12];
            colorsMap['cb_total'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker_total'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker_proactive'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker_reactive'] = config.variables.chartColors[22];
            colorsMap['Circuit Breaker_wp'] = config.variables.chartColors[12];
            colorsMap['Circuit Breaker_rtf'] = config.variables.chartColors[22];
            colorsMap['PTX'] = config.variables.chartColors[13];
            colorsMap['ptx'] = config.variables.chartColors[13];
            colorsMap['ptx_total'] = config.variables.chartColors[13];
            colorsMap['Power Transformer_total'] = config.variables.chartColors[13];
            colorsMap['Power Transformer_proactive'] = config.variables.chartColors[13];
            colorsMap['Power Transformer_reactive'] = config.variables.chartColors[23];
            colorsMap['L_STRUCT'] = config.variables.chartColors[24];
            colorsMap['lstruct'] = config.variables.chartColors[24];
            colorsMap['lstruct_total'] = config.variables.chartColors[24];
            colorsMap['Line Structure_total'] = config.variables.chartColors[24];
            colorsMap['Line Structure_proactive'] = config.variables.chartColors[24];
            colorsMap['Line Structure_reactive'] = config.variables.chartColors[25];
            colorsMap['L_COND'] = config.variables.chartColors[2];
            colorsMap['lcond'] = config.variables.chartColors[2];
            colorsMap['lcond_total'] = config.variables.chartColors[2];
            colorsMap['Line Conductor_total'] = config.variables.chartColors[2];
            colorsMap['Line Conductor_proactive'] = config.variables.chartColors[2];
            colorsMap['Line Conductor_reactive'] = config.variables.chartColors[3];

            colorsMap['Total Proactive'] = config.variables.chartColors[24];
            colorsMap['Total Reactive'] = config.variables.chartColors[25];
            colorsMap['Total_total'] = config.variables.chartColors[24];
            colorsMap['total_total'] = config.variables.chartColors[24];
            colorsMap['total_proactive'] = config.variables.chartColors[24];
            colorsMap['total_reactive'] = config.variables.chartColors[25];
            colorsMap['total_wp'] = config.variables.chartColors[24];
            colorsMap['total_rtf'] = config.variables.chartColors[25];
            colorsMap['temperature_line'] = config.variables.chartColors[10];
            colorsMap['pressure_line'] = config.variables.secondary_default;
            colorsMap['heater_on'] = config.variables.chartColors[28];
            colorsMap['heater_on_area'] = config.variables.chartColors[29];
            colorsMap['shaded_area'] = config.variables.chartColors[29];

            // Optimizer: Risk Forecast
            colorsMap['acceptable_wp'] = config.variables.optimizerColors[2];
            colorsMap['acceptable_rtf'] = config.variables.optimizerColors[3];
            colorsMap['excess_wp'] = config.variables.optimizerColors[0];
            colorsMap['excess_rtf'] = config.variables.optimizerColors[1];
            colorsMap['environmental_wp'] = config.variables.optimizerColors[4];
            colorsMap['environmental_rtf'] = config.variables.optimizerColors[5];
            colorsMap['customer_wp'] = config.variables.optimizerColors[6];
            colorsMap['customer_rtf'] = config.variables.optimizerColors[7];
            colorsMap['collateral_wp'] = config.variables.optimizerColors[8];
            colorsMap['collateral_rtf'] = config.variables.optimizerColors[9];
            colorsMap['financial_wp'] = config.variables.optimizerColors[10];
            colorsMap['financial_rtf'] = config.variables.optimizerColors[11];
            // Optimizer: Failure Forecast
            colorsMap['planned_wp'] = config.variables.chartColors[5];
            colorsMap['planned_rtf'] = config.variables.chartColors[18];
            colorsMap['unplanned_wp'] = config.variables.chartColors[11];
            colorsMap['unplanned_rtf'] = config.variables.chartColors[12];
            // Optimizer: Outage Forecast
            colorsMap['none_wp'] = config.variables.chartColors[5];
            colorsMap['nooutage_rtf'] = config.variables.chartColors[18];
            colorsMap['momentary_wp'] = config.variables.chartColors[7];
            colorsMap['momentary_rtf'] = config.variables.chartColors[15];
            colorsMap['sustined_wp'] = config.variables.chartColors[3];
            colorsMap['sustained_rtf'] = config.variables.chartColors[19];
            colorsMap['planned_wp'] = config.variables.chartColors[10];
            colorsMap['planned_rtf'] = config.variables.chartColors[17];

            colorsMap['survival'] = {
                data_history: config.variables.primary_default,
                actual: config.variables.primary_default,
                calibrated: config.variables.primary_default,
            };
            colorsMap['data_audit'] = {
                availability: '#0c8ac7',
                validity: '#a0de92',
                impact: '#ffb660',
                count: '#c36aed',
                trend_up: config.variables.chartColors[7],
                trend_down: config.variables.chartColors[0],
            };
            colorsMap['hi'] = {
                hi: config.variables.primary_default,
                availability: config.variables.chartColors[5],
                validity: config.variables.chartColors[4],
            };

            // Transmission Forecaster
            colorsMap['Availability'] = '#0C8AC7';
            colorsMap['Availability_forecast'] = '#FE9339';

            colorsMap['colorsArray'] = [
                config.variables.chartColors[5],
                config.variables.chartColors[6],
                config.variables.chartColors[7],
                config.variables.chartColors[8],
                config.variables.chartColors[9],
                config.variables.chartColors[10],
                config.variables.chartColors[11],
                config.variables.chartColors[12],
                config.variables.chartColors[13],
                config.variables.chartColors[30],
                config.variables.chartColors[26],
            ];

            // Default echarts color palette
            colorsMap['default'] = [
                '#c23531',
                '#2f4554',
                '#61a0a8',
                '#d48265',
                '#91c7ae',
                '#749f83',
                '#ca8622',
                '#bda29a',
                '#6e7074',
                '#546570',
                '#c4ccd3',
            ];

            colorsMap['axisSliderBackground'] = config.variables.axisSliderBackground;
            colorsMap['axisSliderShadow'] = config.variables.axisSliderShadow;
            colorsMap['axisSliderHandle'] = config.variables.axisSliderHandle;
            colorsMap['axisNameText'] = config.variables.axisNameText;
            colorsMap['axisLine'] = config.variables.axisLine;
            colorsMap['tooltipBg'] = config.variables.tooltipBg;

            colorsMap['status_normal'] = config.variables.chartColors[4];
            colorsMap['status_alert'] = config.variables.chartColors[26];
            colorsMap['status_lockout'] = config.variables.chartColors[27];
            colorsMap['new_data_color'] = config.variables.new_data_color;
            colorsMap['old_data_color'] = config.variables.old_data_color;

            colorsMap['Alert Statuses'] = {
                [AlertStatusEnum.None]: config.variables.alertStatuses[0],
                [AlertStatusEnum.Information]: config.variables.alertStatuses[1],
                [AlertStatusEnum.Reactive_Priority5]: config.variables.alertStatuses[2],
                [AlertStatusEnum.Predict_5year]: config.variables.alertStatuses[2],
                [AlertStatusEnum.Reactive_Priority4]: config.variables.alertStatuses[3],
                [AlertStatusEnum.Predict_3year]: config.variables.alertStatuses[3],
                [AlertStatusEnum.Reactive_Priority3]: config.variables.alertStatuses[4],
                [AlertStatusEnum.Predict_1year]: config.variables.alertStatuses[4],
                [AlertStatusEnum.Reactive_Priority2]: config.variables.alertStatuses[5],
                [AlertStatusEnum.Reactive_Priority1]: config.variables.alertStatuses[6],
            };

            colorsMap['tableFilterBg'] = config.variables.tableFilterBg;
            colorsMap['tableFilterColor'] = config.variables.tableFilterColor;

            colorsMap['Thresholds'] = {
                0: config.variables.chartColors[0],
                1: config.variables.chartColors[1],
                2: config.variables.chartColors[2],
            };

            return colorsMap;
        }),
    );

    constructor(private themeService: NbThemeService) {}
}
