import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {LatestModelStatus, LiveModelRequest} from '@core/interfaces/models/live';
import {HttpParams} from '@angular/common/http';
import {
    Sf6GasLeakComparisonResponse,
    Sf6GasLeakCorrectedResponse,
    Sf6GasLeakLatestStatusResponse,
    Sf6GasLeakRawResponse,
    Sf6GasLeakUnits,
} from '@core/interfaces/models/sf6-gas-leak';
import {map} from 'rxjs/operators';

@Injectable()
export class Sf6GasLeakApi {
    private readonly PREFIX = 'live/output/sf6-gas-leak';

    constructor(private api: HttpService) {}

    public getSf6GasLeakUnits(): Observable<Sf6GasLeakUnits> {
        return this.api.get(`${this.PREFIX}/units`);
    }

    public getSf6GasLeakDatasource(id: string): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.PREFIX}/list?filterByassetId=${id}`);
    }

    public getCountResults(assetId: string, uploadDate?: string): Observable<number> {
        return this.api
            .get(`${this.PREFIX}/count`, {
                params: new HttpParams().set('assetId', assetId || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<number>) => res.response));
    }

    /**
     * Detailed analysis showing calculation for current alert status
     * #1005
     */
    public getSf6GasLeakLatestStatusDetails(request: LiveModelRequest): Observable<LatestModelStatus> {
        return this.api
            .get(`${this.PREFIX}/latest`, {
                params: new HttpParams()
                    .set('assetId', request.assetId || '')
                    .set('model', request.model || '')
                    .set('uploadDate', request.uploadDate || ''),
            })
            .pipe(map((res: APIResponse<LatestModelStatus>) => res.response));
    }

    public getSf6GasLeakChartDataComparison(request: LiveModelRequest): Observable<Sf6GasLeakComparisonResponse> {
        return this.api
            .get(`${this.PREFIX}/data/comparison`, {
                params: new HttpParams()
                    .set('assetId', request.assetId || '')
                    .set('model', request.model || '')
                    .set('uploadDate', request.uploadDate || ''),
            })
            .pipe(map((res: APIResponse<Sf6GasLeakComparisonResponse>) => res.response));
    }

    public getSf6GasLeakChartDataRaw(request: LiveModelRequest): Observable<Sf6GasLeakRawResponse> {
        return this.api
            .get(`${this.PREFIX}/data/raw`, {
                params: new HttpParams()
                    .set('assetId', request.assetId || '')
                    .set('model', request.model || '')
                    .set('uploadDate', request.uploadDate || ''),
            })
            .pipe(map((res: APIResponse<Sf6GasLeakRawResponse>) => res.response));
    }

    public getSf6GasLeakChartDataCorrected(request: LiveModelRequest): Observable<Sf6GasLeakCorrectedResponse> {
        return this.api
            .get(`${this.PREFIX}/data/corrected`, {
                params: new HttpParams()
                    .set('assetId', request.assetId || '')
                    .set('model', request.model || '')
                    .set('uploadDate', request.uploadDate || ''),
            })
            .pipe(map((res: APIResponse<Sf6GasLeakCorrectedResponse>) => res.response));
    }
}
