import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonBackendModule} from '../common/common-backend.module';
import {LiveApi} from '@core/backend/models/api/live.api';
import {LiveService} from '@core/interfaces/models/live';
import {LiveServiceImpl} from '@core/backend/models/services/live.service';
import {HydrogenTrendingApi} from '@core/backend/models/api/hydrogen-trending.api';
import {HydrogenTrendingService} from '@core/interfaces/models/hydrogen-tending';
import {HydrogenTrendingServiceImpl} from '@core/backend/models/services/hydrogen-trending.service';
import {Sf6GasLeakService} from '@core/interfaces/models/sf6-gas-leak';
import {Sf6GasLeakServiceImpl} from '@core/backend/models/services/sf6-gas-leak.service';
import {Sf6GasLeakApi} from '@core/backend/models/api/sf6-gas-leak.api';
import {OilTemperatureMoistureService} from '@core/interfaces/models/oil-temperature-moisture';
import {OilTemperatureMoistureServiceImpl} from './services/oil-temperature-moisture.service';
import {Co2LoadService} from '@core/interfaces/models/co2-load';
import {Co2LoadServiceImpl} from '@core/backend/models/services/co2-load.service';
import {OnlineDgaService} from '@core/interfaces/models/online-dga';
import {OnlineDgaServiceImpl} from '@core/backend/models/services/online-dga.service';
import {OilTemperatureMoistureApi} from '@core/backend/models/api/oil-temperature-moisture.api';
import {Co2LoadApi} from '@core/backend/models/api/co2-load.api';
import {OnlineDgaApi} from '@core/backend/models/api/online-dga.api';
import {InsulationMoistureService} from '@core/interfaces/models/insulation-moisture';
import {InsulationMoistureServiceImpl} from '@core/backend/models/services/insulation-moisture.service';
import {InsulationMoistureApi} from './api/insulation-moisture.api';

const API = [
    LiveApi,
    Sf6GasLeakApi,
    HydrogenTrendingApi,
    OilTemperatureMoistureApi,
    Co2LoadApi,
    OnlineDgaApi,
    InsulationMoistureApi,
];

const SERVICES = [
    {provide: LiveService, useClass: LiveServiceImpl},
    {provide: Sf6GasLeakService, useClass: Sf6GasLeakServiceImpl},
    {provide: HydrogenTrendingService, useClass: HydrogenTrendingServiceImpl},
    {provide: OilTemperatureMoistureService, useClass: OilTemperatureMoistureServiceImpl},
    {provide: Co2LoadService, useClass: Co2LoadServiceImpl},
    {provide: OnlineDgaService, useClass: OnlineDgaServiceImpl},
    {provide: InsulationMoistureService, useClass: InsulationMoistureServiceImpl},
];

@NgModule({
    imports: [CommonModule, CommonBackendModule],
    exports: [CommonBackendModule],
})
export class LiveModelsModule {
    static forRoot(): ModuleWithProviders<LiveModelsModule> {
        return {
            ngModule: LiveModelsModule,
            providers: [...API, ...SERVICES],
        };
    }
}
