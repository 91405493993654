<nb-card>
    <nb-card-header>
        {{ title }}
        <button nbButton ghost status="basic" size="small" (click)="dialogRef.close()">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>
    <nb-card-body>
        <ng-container *ngFor="let item of guideData">
            <ng-container *ngTemplateOutlet="renderItem; context: {$implicit: item}"></ng-container>
        </ng-container>
    </nb-card-body>
    <nb-card-footer>
        <button fullWidth nbButton status="info" (click)="dialogRef.close()">
            <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
            Got It
        </button>
    </nb-card-footer>
</nb-card>

<ng-template #renderItem let-item>
    <ng-container [ngSwitch]="item.elementType">
        <ng-container *ngSwitchCase="GuideInfoElementTypes.Section">
            <div class="section">{{ item.value }}</div>

            <ng-container *ngFor="let child of item.children">
                <ng-container *ngTemplateOutlet="renderItem; context: {$implicit: child}"></ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="GuideInfoElementTypes.Paragraph">
            <p>{{ item.value }}</p>

            <div *ngIf="item.children as children" class="children">
                <ng-container *ngFor="let child of children">
                    <ng-container *ngTemplateOutlet="renderItem; context: {$implicit: child}"></ng-container>
                </ng-container>
            </div>
        </ng-container>

        <ol *ngSwitchCase="GuideInfoElementTypes.OrderedList">
            <ng-container *ngTemplateOutlet="list; context: {$implicit: item}"></ng-container>
        </ol>

        <ul *ngSwitchCase="GuideInfoElementTypes.UnorderedList">
            <ng-container *ngTemplateOutlet="list; context: {$implicit: item}"></ng-container>
        </ul>
    </ng-container>
</ng-template>

<ng-template #list let-item>
    <span class="list-title">{{ item.value }}</span>
    <ng-container *ngFor="let child of item.children">
        <li>
            <span class="list-element">{{ child.value }}</span>
        </li>
        <div *ngIf="child.children as children" class="children">
            <ng-container *ngFor="let child of children">
                <ng-container *ngTemplateOutlet="renderItem; context: {$implicit: child}"></ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-template>
