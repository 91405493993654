import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UsersApi} from '../api/users.api';
import {User, UsersService} from '../../../interfaces/common/users';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class UsersServiceImpl extends UsersService {
    constructor(private api: UsersApi) {
        super();
    }

    getCurrentUser(): Observable<User> {
        return this.api.getCurrent();
    }

    get(id: string): Observable<User> {
        return this.api.get(id);
    }

    create(user: any): Observable<User> {
        return this.api.create(user);
    }

    update(user: any): Observable<any> {
        return this.api.update(user);
    }

    delete(id: string): Observable<boolean> {
        return this.api.delete(id);
    }

    getUsersDataSource(): Observable<DataSource> {
        return this.api.getUsersDataSource();
    }
}
