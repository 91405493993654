import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {Observable} from 'rxjs';
import {DataFile} from './data-file';
import {APIResponse} from '@core/interfaces/system/system-common';

export enum DatasetType {
    asset = 'asset',
    evaluation = 'evaluation',
    health_index = 'health_index',
    survival = 'survival',
}

export interface Dataset {
    _id: string;
    id: number;
    createdOn: Date;
    updatedOn: Date;
    datasetType: DatasetType;
    version: number;
    status: 'CREATED' | 'DELETED' | 'PENDING_DELETE';
    tag?: string;
    note?: string;
    files: DatasetFile[];
}

export interface DatasetFile {
    datasetId: number;
    uploadFileId: number;
    targetedTable: string;
    uploadFile: DataFile;
}

export interface DatasetCreator {
    datasetType: string;
    createType: string;
    sourceDatasetId: number;
    tag: string;
    note: string;
    files: DatasetFileCreator[];
}

export interface DatasetFileCreator {
    fileId: number;
    tableLabel: string;
}

export abstract class DatasetService {
    abstract findOne(id: string): Observable<APIResponse<Dataset>>;

    abstract create(data: DatasetCreator): Observable<APIResponse<Dataset>>;

    abstract update(id: string, data: Dataset): Observable<APIResponse<Dataset>>;

    abstract delete(uploadedId: string): Observable<APIResponse<boolean>>;

    abstract reversePendingDelete(datasetId: string): Observable<APIResponse<boolean>>;

    abstract listLatestDataSet(pageSize: number, schemaType: string): Observable<Dataset[]>;

    abstract listFiles(): Observable<DataSource>;

    abstract listFilesByType(dataType: DatasetType): Observable<DataSource>;

    abstract listFilesByTypeAndStatus(dataType: DatasetType, status: string): Observable<DataSource>;
}
