<div class="page-container empty-table">
    <div class="page-content-container table-container">
        <div class="table-header">
            <nb-card-header class="chart-card-header" *ngIf="header">
                <div class="chart-header">
                    <label class="chart-title">Table title</label>
                    <label class="chart-subtitle">Table subtitle</label>
                </div>
            </nb-card-header>
        </div>

        <ngx-smart-table class="study-assets-table" [settings]="settings" [source]="dynamicData"> </ngx-smart-table>
    </div>
</div>
