import {Component, Input, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

export interface GuideInfo {
    elementType: GuideInfoElementTypes;
    value?: string;
    children?: GuideInfo[];
}

export enum GuideInfoElementTypes {
    Section = 'SECTION', //value is a section title
    Paragraph = 'PARAGRAPH', //value is paragraph text
    OrderedList = 'ORDERED_LIST', //value is a title for ordered list
    UnorderedList = 'UNORDERED_LIST', //value is a title for unordered list
    ListElement = 'LIST_ELEMENT', //value is a list element's text
}

@Component({
    selector: 'ngx-guide-dialog',
    templateUrl: './guide-dialog.component.html',
    styleUrls: ['./guide-dialog.component.scss'],
})
export class GuideDialogComponent {
    @Input() title?: string = 'Data Translation Guide';
    @Input() guideData: GuideInfo[];

    GuideInfoElementTypes = GuideInfoElementTypes;

    //TODO delete this example of the guideData
    data: GuideInfo[] = [
        {
            elementType: GuideInfoElementTypes.Section,
            value: 'Process described below: ',
            children: [
                {
                    elementType: GuideInfoElementTypes.Paragraph,
                    value: 'Steps 1-4 are repeated for each unique Customer Type. Feeder totals are summed across each individual Customer Type.',
                    children: [
                        {
                            elementType: GuideInfoElementTypes.OrderedList,
                            value: 'Pivot data from impact-per-neighbourhood to impact-per-feeder. (Ordered)',
                            children: [
                                {
                                    elementType: GuideInfoElementTypes.ListElement,
                                    value: 'Upload data file(s) with new information for this tool. ',
                                },
                                {
                                    elementType: GuideInfoElementTypes.ListElement,
                                    value: "For each file select an applicable option from the 'File Type' dropdown. Once all files are ready, press the 'Upload' button. ",
                                    children: [
                                        {
                                            elementType: GuideInfoElementTypes.UnorderedList,
                                            children: [
                                                {
                                                    elementType: GuideInfoElementTypes.ListElement,
                                                    value: 'Upload status is provided for all files. Cannot proceed until all files are successful; delete files or restart and try again. Uploads should not commonly fail but may indicate a lapse in internet connection. ',
                                                },
                                                {
                                                    elementType: GuideInfoElementTypes.ListElement,
                                                    value: 'Minimum files: 1. ',
                                                },
                                                {
                                                    elementType: GuideInfoElementTypes.ListElement,
                                                    value: 'Minimum files: 1. ',
                                                },
                                                {
                                                    elementType: GuideInfoElementTypes.ListElement,
                                                    value: 'File contents: Demand Factor Data which will be translated. Must begin with 7 columns: timestamp, season, type_of_day, hour, total_city_evs, per_capita, customer_type. Remainder of file columns are names of neighbourhoods (may be 100s). ',
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    elementType: GuideInfoElementTypes.ListElement,
                                    value: 'Please wait while files are uploaded from your local machine into the ENGIN Landing Zone. ',
                                },
                            ],
                        },
                    ],
                },
                {
                    elementType: GuideInfoElementTypes.Paragraph,
                    value: 'Steps 1-4 are repeated for each unique Customer Type. Feeder totals are summed across each individual Customer Type.',
                },
            ],
        },
        {
            elementType: GuideInfoElementTypes.Section,
            value: 'Section 2',
            children: [
                {
                    elementType: GuideInfoElementTypes.OrderedList,
                    children: [
                        {
                            elementType: GuideInfoElementTypes.ListElement,
                            value: 'value 1',
                        },
                        {
                            elementType: GuideInfoElementTypes.ListElement,
                            value: 'value 2',
                            children: [
                                {
                                    elementType: GuideInfoElementTypes.UnorderedList,
                                    value: 'Un Ordered List 2',
                                    children: [
                                        {
                                            elementType: GuideInfoElementTypes.ListElement,
                                            value: 'un. value 1',
                                        },
                                        {
                                            elementType: GuideInfoElementTypes.ListElement,
                                            value: 'un. value 2',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            elementType: GuideInfoElementTypes.ListElement,
                            value: 'value 3',
                        },
                    ],
                },
            ],
        },
        {
            elementType: GuideInfoElementTypes.Section,
            value: 'Section 3',
        },
    ];

    constructor(@Optional() public dialogRef: NbDialogRef<any>) {}
}
