import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';

type OptionalExceptFor<T, TRequired extends keyof T> = Partial<T> & Pick<T, TRequired>;

export interface AssetImageData {
    id: number;
    assetId: number;
    name: string;
    tag: string;
    url: string;
}

export type CreateAssetImageData = Omit<AssetImageData, 'id'>;

export interface AssetNoteData {
    id: number;
    author: string;
    date: string;
    message: string;
}

export interface AssetStaticInfo {
    name: string;
    items: AssetInfo[];
}

export interface AssetInfo {
    name: string;
    value: string;
    order: number;
}

export type CreateAssetNoteData = OptionalExceptFor<AssetNoteData, 'message'>;

export abstract class AssetDetailsService {
    abstract getAssetInfoDetails(assetId: string): Observable<APIResponse<AssetStaticInfo[]>>;
}
