import {Component, Input} from '@angular/core';

@Component({
    selector: 'ngx-current-position-visualizer',
    templateUrl: './current-position-visualizer.component.html',
    styleUrls: ['./current-position-visualizer.component.scss'],
})
export class CurrentPositionVisualizerComponent {
    @Input() list: {min: number; max: number; label: string; alertStatus: string; color?: string}[];
    @Input() currentPosition: number;

    getBulletStyle() {
        let _position,
            _color,
            section = 100 / this.list.length;

        this.list.forEach((item, index) => {
            if (item.min === this.currentPosition && item.max === this.currentPosition) {
                _position = item.min;
                _color = item.color;
            }

            if (item.min < this.currentPosition && item.max >= this.currentPosition) {
                let segment = section / ((item.max - item.min) / (this.currentPosition - item.min));

                _position = segment + index * section;
                _color = item.color;
            }
        });
        return {
            'background-color': _color,
            left: `calc(${_position}% - 4px)`,
        };
    }
}
