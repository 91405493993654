import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {OutputReportSource, ReportRequest, StudyCollection} from '../../../interfaces/engin/jobs';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class JobsApi {
    private readonly prefix = 'study';

    constructor(private api: HttpService) {}

    getOutputReportGeneric(reportRequest: ReportRequest): Observable<OutputReportSource> {
        return this.api.post(`${this.prefix}/report/generic`, reportRequest);
    }

    updateDefaultStudyStrategy(latestAsDefault: boolean, defaultCollectionId: string): Observable<any> {
        const params = new HttpParams()
            .set('useDefault', latestAsDefault.toString())
            .set('defaultCollectionId', defaultCollectionId);
        return this.api.post(`${this.prefix}/updateDefaultStudyStrategy`, null, {params: params});
    }
}
