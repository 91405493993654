import {Component, Input} from '@angular/core';

@Component({
    selector: 'ngx-empty-data',
    templateUrl: './empty-data.component.html',
    styleUrls: ['./empty-data.component.scss'],
})
export class EmptyDataComponent {
    @Input() rowData: any;
}
