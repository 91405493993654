import {Injectable} from '@angular/core';
import {NbToastrService} from '@nebular/theme';
import {APIException, ExceptionDto} from './exception.dto';

export interface ErrorInfo {
    code: string;
    messageTitle: string;
    messageBody: string;
}

@Injectable()
export class EnginErrorHandlerService {
    constructor(private toastrService: NbToastrService) {}

    public handleCustomException(exception: APIException) {
        // Empty response
        if (exception === null) {
            this.toastrService.danger(
                'Something went wrong. Please try again or contact your administrator.',
                'Internal error.',
                {duration: 10000},
            );
        } else {
            const exceptions = exception.exceptions as ExceptionDto[];
            exceptions
                .filter((f) => f.errorDisplayType === 'GLOBAL')
                .forEach((err) => {
                    this.toastrService.danger(`Error: ${err.message}`, err.exceptionDetail, {
                        duration: 10000,
                    });
                });
        }
    }
}
