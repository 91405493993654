import {Component, Input, ChangeDetectionStrategy} from '@angular/core';

@Component({
    selector: 'ngx-sidebar-action-popover',
    template: `
        <div class="label-container">
            <div class="label-body" [ngStyle]="expanded ? {display: 'none'} : {display: 'flex'}">
                <label>{{ text }}</label>
            </div>
        </div>
    `,
    styleUrls: ['./sidebar-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarActionPopoverComponent {
    @Input() text: string;
    @Input() expanded: boolean;
}
