<div class="chart-settings-container">
    <div class="chart-settings-items">
        <!-- Units selector -->
        <ng-container *ngIf="unitItem !== null && unitOptions as settings">
            <p class="section-title">Units</p>
            <nb-radio-group name="group1" [value]="unitItem.unit" (valueChange)="itemChanged.emit($event)">
                <nb-radio class="unit-selector-item" *ngFor="let unit of settings" [value]="unit.value"
                    >{{ unit.label }}
                </nb-radio>
            </nb-radio-group>

            <div
                *ngIf="
                    ((disableDataDownload === null || disableDataDownload === false) &&
                        chartOptions !== null &&
                        chartType !== null) ||
                    preparedDownloadData !== null
                "
                class="section-divider"
            ></div>
        </ng-container>

        <!-- Label selection -->
        <ng-container *ngIf="showLabelSelection && graphLabels.options as labelSettings">
            <p class="section-title">Labels</p>
            <nb-radio-group name="group2" [value]="labelSelected" (valueChange)="labelChanged($event)">
                <nb-radio class="unit-selector-item" *ngFor="let label of labelSettings" [value]="label.value"
                    >{{ label.label }}
                </nb-radio>
            </nb-radio-group>

            <div
                *ngIf="
                    ((disableDataDownload === null || disableDataDownload === false) &&
                        chartOptions !== null &&
                        chartType !== null) ||
                    preparedDownloadData !== null
                "
                class="section-divider"
            ></div>
        </ng-container>

        <!-- Data download -->
        <ng-container
            *ngIf="
                ((disableDataDownload === null || disableDataDownload === false) &&
                    chartOptions !== null &&
                    chartType !== null) ||
                preparedDownloadData !== null
            "
        >
            <p class="section-title">Export</p>
            <div *ngIf="!disableDataDownload" class="download-menu-item" (click)="dataDownloadCSV()">
                <nb-icon class="download-menu-icon" icon="download"></nb-icon>
                <span class="download-menu-text">CSV</span>
            </div>
            <div class="download-menu-item" (click)="dataDownloadImage('jpeg')">
                <nb-icon class="download-menu-icon" icon="download"></nb-icon>
                <span class="download-menu-text">JPG</span>
            </div>
            <div class="download-menu-item" (click)="dataDownloadImage('png')">
                <nb-icon class="download-menu-icon" icon="download"></nb-icon>
                <span class="download-menu-text">PNG</span>
            </div>
        </ng-container>
    </div>

    <div class="selector-arrow"></div>
</div>
