import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    ChartAssetAlertHistoryResponse,
    ChartModelAlertHistoryResponse,
    ChartModelHistoryResponse,
    LiveAssetRequest,
    LiveFleetRequest,
    LiveModelRequest,
    LiveService,
    FleetLatestStatusResponse,
} from '@core/interfaces/models/live';
import {LiveApi} from '../api/live.api';
import {APIResponse} from '@core/interfaces/system/system-common';
import {GuideInfo} from '@theme/components';

@Injectable()
export class LiveServiceImpl extends LiveService {
    constructor(private api: LiveApi) {
        super();
    }

    /*
     * Data explorer
     */
    getMeterDataDatasource(): Observable<DataSource> {
        return this.api.getMeterDataDatasource();
    }

    /*
     * Generic
     */
    getSearchAssetIds(assetId: string, model: string): Observable<APIResponse<string[]>> {
        return this.api.getSearchAssetIds(assetId, model);
    }

    getSearchModels(assetId: string, model: string): Observable<APIResponse<string[]>> {
        return this.api.getSearchModels(assetId, model);
    }

    /*
     * Model, generic
     */
    public getCountResultsByModel(request: LiveModelRequest): Observable<number> {
        return this.api.getCountResultsByModel(request);
    }

    getModelOutputDatasource(assetId: string, uploadDate: string): Observable<DataSource> {
        return this.api.getModelOutputDatasource(assetId, uploadDate);
    }

    getChartModelHistory(request: LiveModelRequest): Observable<APIResponse<ChartModelHistoryResponse>> {
        return this.api.getChartModelHistory(request);
    }

    getChartModelAlertHistory(
        request: LiveModelRequest,
        groupBy: string,
    ): Observable<APIResponse<ChartModelAlertHistoryResponse>> {
        return this.api.getChartModelAlertHistory(request, groupBy);
    }

    /*
     * Asset
     */
    public getCountResultsByAsset(assetId: string, uploadDate: string): Observable<number> {
        return this.api.getCountResultsByAsset(assetId, uploadDate);
    }

    getChartAssetAlertHistory(request: LiveAssetRequest): Observable<APIResponse<ChartAssetAlertHistoryResponse[]>> {
        return this.api.getChartAssetAlertHistory(request);
    }

    getAssetLatestModelStatus(id: string, uploadDate: string): Observable<DataSource> {
        return this.api.getAssetLatestModelStatus(id, uploadDate);
    }

    /*
     * Fleet
     */
    public getCountResultsByFleet(uploadDate: string): Observable<number> {
        return this.api.getCountResultsByFleet(uploadDate);
    }

    getFleetLatestStatusDetails(request: LiveFleetRequest): Observable<APIResponse<FleetLatestStatusResponse>> {
        return this.api.getFleetLatestStatusDetails(request);
    }

    getCurrentAssetAlerts(request: LiveFleetRequest): Observable<DataSource> {
        return this.api.getCurrentAssetAlerts(request);
    }

    public getGuideInfo(modelId: string): Observable<GuideInfo[]> {
        return this.api.getGuideInfo(modelId);
    }
}
