import * as FileSaver from 'file-saver';
import {ECharts} from 'echarts';

export enum ECHARTS_TYPE {
    CUSTOM_BAR_STACKED, // e.g., analyzer EOL metrics
    BAR_V, // Temporary: planner, project view
    CUSTOM_COL_STACKED, // e.g., chartData.data.name
    COL_STACKED_CLUSTERED, // e.g., optimizer, forecaster
    PIE, // e.g., analyzer EOL metrics
    LINE, // e.g., Data audit tool
    MULTI_LINE, // e.g., Data audit tool
    TIMESERIES, // e.g., LIVE dense time-series (line or area)
    SCATTER, // e.g., LIVE dashboard > Sf6 gas leak
}

export interface ChartDownloadData {
    header: any[];
    data: any[];
}

export class ChartDataDownloadService {
    // Prepare chart data for download then send to browser
    static prepareAndDownloadChartData(options: any, chartType: ECHARTS_TYPE, filename: string) {
        const outputHeader = [];
        const outputObjectArray = [];
        let xAxisName;

        switch (chartType) {
            case ECHARTS_TYPE.CUSTOM_BAR_STACKED:
                // Create header from series names
                outputHeader.push('Category');
                for (let i = 0; i < options.series.length; i++) {
                    outputHeader.push(options.series[i].name);
                }

                // Add data rows from each independent series/bar
                if (options.yAxis[0].data && options.yAxis[0].data != null) {
                    // Multi bar has yAxis data element
                    for (let yIndex = 0; yIndex < options.yAxis[0].data.length; yIndex++) {
                        const tempDataEntry = {};
                        tempDataEntry['Category'] = options.yAxis[0].data[yIndex];

                        // Look for custom data object "origValue" in cases when custom formatting was used
                        for (let i = 0; i < options.series.length; i++) {
                            tempDataEntry[`field_${i}`] = options.series[i].data[yIndex].origValue
                                ? options.series[i].data[yIndex].origValue
                                : options.series[i].data[yIndex].value;
                        }
                        outputObjectArray.push(tempDataEntry);
                    }
                } else {
                    // Single bar - no yAxis data element
                    const tempDataEntry = {};
                    tempDataEntry['Category'] = 'All';
                    for (let i = 0; i < options.series.length; i++) {
                        tempDataEntry[`field_${i}`] = options.series[i].data[0].origValue
                            ? options.series[i].data[0].origValue
                            : options.series[i].data[0].value;
                    }
                    outputObjectArray.push(tempDataEntry);
                }

                break;

            case ECHARTS_TYPE.BAR_V:
                outputHeader.push('Alternative');
                outputHeader.push('Total Cost of Ownership [$M]');
                for (let xIndex = 0; xIndex < options.xAxis[0].data.length; xIndex++) {
                    const tempDataEntry = {};
                    tempDataEntry['Alternative'] = options.xAxis[0].data[xIndex];
                    tempDataEntry['Total Cost of Ownership [$M]'] = options.series[0].data[xIndex].value;
                    outputObjectArray.push(tempDataEntry);
                }
                break;

            case ECHARTS_TYPE.COL_STACKED_CLUSTERED:
                // Create header row
                xAxisName = options.xAxis[0].name;
                outputHeader.push(xAxisName);
                for (let seriesIndex = 0; seriesIndex < options.series.length; seriesIndex++) {
                    outputHeader.push(options.series[seriesIndex].name);
                }

                /*
                 * Iterate over x-axis values and pull values for each series row-by-row.
                 * - Stacked series objects show with different names in the series list
                 * - Pull custom data format for series names vs. codes, and handle total lines
                 */
                for (let xIndex = 0; xIndex < options.xAxis[0].data.length; xIndex++) {
                    const tempDataEntry = {};
                    tempDataEntry[`field_${xAxisName}`] = Number(options.xAxis[0].data[xIndex]);

                    for (let seriesIndex = 0; seriesIndex < options.series.length; seriesIndex++) {
                        // Push data; for totals, read the second data element
                        if (
                            options.series[seriesIndex].name.includes('total') &&
                            Array.isArray(options.series[seriesIndex].data[xIndex])
                        ) {
                            tempDataEntry[`field_${seriesIndex}`] = options.series[seriesIndex].data[xIndex][1];
                        } else {
                            tempDataEntry[`field_${seriesIndex}`] = options.series[seriesIndex].data[xIndex];
                        }
                    }
                    outputObjectArray.push(tempDataEntry);
                }
                break;

            case ECHARTS_TYPE.CUSTOM_COL_STACKED:
                // Create header row
                xAxisName = options.xAxis[0].name;
                outputHeader.push(xAxisName);
                for (let seriesIndex = 0; seriesIndex < options.series.length; seriesIndex++) {
                    outputHeader.push(options.series[seriesIndex].name);
                }

                // Iterate over x-axis values and pull values for each series row-by-row
                for (let xIndex = 0; xIndex < options.xAxis[0].data.length; xIndex++) {
                    const tempDataEntry = {};
                    tempDataEntry[`field_${xAxisName}`] = Number(options.xAxis[0].data[xIndex]);

                    for (let seriesIndex = 0; seriesIndex < options.series.length; seriesIndex++) {
                        // Push data; for totals, read the second data element
                        if (
                            options.series[seriesIndex].name.includes('total') &&
                            Array.isArray(options.series[seriesIndex].data[xIndex])
                        ) {
                            tempDataEntry[`field_${seriesIndex}`] = options.series[seriesIndex].data[xIndex][1];
                        } else {
                            tempDataEntry[`field_${seriesIndex}`] = options.series[seriesIndex].data[xIndex].origValue;
                        }
                    }

                    outputObjectArray.push(tempDataEntry);
                }
                break;

            case ECHARTS_TYPE.PIE:
                outputHeader.push('Category');
                outputHeader.push('Value');

                for (let i = 0; i < options.series[0].data.length; i++) {
                    const tempDataEntry = {};
                    tempDataEntry['Category'] = options.series[0].data[i].name;
                    tempDataEntry['Cost'] = options.series[0].data[i].value;
                    outputObjectArray.push(tempDataEntry);
                }
                break;

            case ECHARTS_TYPE.LINE:
                const xAxisValue = options.xAxis.data;
                outputHeader.push('Category');
                const tempDataArray = [];
                xAxisValue.map((xAxis, index) => {
                    outputHeader.push(xAxis);
                    options.series.map((item) => {
                        if (item.data) {
                            const tempDataObj = {};
                            tempDataObj['Category'] = item.name;
                            tempDataObj[xAxis] = item.data[index];
                            tempDataArray.push(tempDataObj);
                        }
                    });
                });

                // merge object by same Category
                const result = {};
                tempDataArray.forEach((item) => (result[item.Category] = {...result[item.Category], ...item}));
                // Get category then delete it
                const category = result[Object.keys(result)[0]].Category;
                delete result[Object.keys(result)[0]].Category;
                // re add the category but heading the object.
                result[Object.keys(result)[0]]['0'] = category;
                Object.values(result).map((res) => {
                    outputObjectArray.push(res);
                });

                break;
            case ECHARTS_TYPE.MULTI_LINE:
                const series = options.series;
                outputHeader.push('Category');
                outputHeader.push('Series');
                outputHeader.push('Value');
                series.map((item: any) => {
                    let seriesName = item.name;
                    item.data.map((d: any) => {
                        const tempDataObj = {};
                        tempDataObj['Category'] = seriesName;
                        tempDataObj['Series'] = d[0];
                        tempDataObj['Value'] = d[1];
                        outputObjectArray.push(tempDataObj);
                    });
                });
                break;
            case ECHARTS_TYPE.TIMESERIES:
                /*
                 * Options:
                 * - Options.xAxis has no data
                 * - Options.series has one or more elements; each element has data array where each element is time/value pairs
                 * Output:
                 * - First column: header "date" or "time" from Options.xAxis.name
                 * - One column per series
                 * - One row per x-Axis value
                 */
                const multiSeries = options.series;
                const xAxisData = options.series[0]?.data?.map((e) => e[0]);
                const xAxisHeader = options.xAxis?.name ? options.xAxis?.name : 'Date/Time';
                // Create header
                outputHeader.push(xAxisHeader);
                multiSeries.forEach((series) => {
                    const seriesName = series.name;
                    outputHeader.push(seriesName);
                });
                // Populate rows one one at a time
                xAxisData.forEach((val, index) => {
                    const tempDataObj = {};
                    tempDataObj[xAxisHeader] = val;
                    multiSeries.forEach((series) => {
                        const seriesName = series.name;
                        tempDataObj[seriesName] = series.data[index][1]; // [date/time, value]
                    });
                    outputObjectArray.push(tempDataObj);
                });
                break;
            case ECHARTS_TYPE.SCATTER:
                /*
                 * Options:
                 * - Options.xAxis, Options.yAxis have labels for X/Y series
                 * - Options.series has one or more elements; each element has [X, Y, ...E] where E is possible extras
                 * Output:
                 * - xAxis label, xAxis label, <Extras<e>> for any extras
                 */
                xAxisName = options.xAxis?.name;
                const yAxisName = options.yAxis?.name;
                const data = options.series[0]?.data;
                // Header
                outputHeader.push(xAxisName);
                outputHeader.push(yAxisName);
                const extrasLabels = [];
                for (let e = 2; e < data[0]?.length; e++) {
                    const label = 'Extras' + (e - 1);
                    outputHeader.push(label);
                    extrasLabels.push(label);
                }
                // Rows
                data.forEach((item, index) => {
                    const tempDataObj = {};
                    tempDataObj[xAxisName] = item[0];
                    tempDataObj[yAxisName] = item[1];
                    // extras
                    for (let e = 2; e < item.length; e++) {
                        const label = 'Extras' + (e - 1);
                        tempDataObj[label] = item[e];
                    }
                    outputObjectArray.push(tempDataObj);
                });
                break;
        }

        // Send data to be downloaded to browser
        const customData: ChartDownloadData = {
            header: outputHeader,
            data: outputObjectArray,
        };
        ChartDataDownloadService.downloadChartData(customData, filename);
    }

    // Prepare CSV data and send to browser
    static downloadChartData(customData: ChartDownloadData, fileName: string) {
        let headerRow: string[] = customData.header;
        const arrData: any[] = customData.data;
        const dataKey = Object.keys(arrData[0]);
        const replacer = (key, value) => (value === null ? '' : value);

        // Create header row if it was not directly provided
        if (headerRow === null) {
            headerRow = Object.keys(arrData[0]);
        }

        // Create CSV data for download
        const csvData = arrData.map((row) =>
            dataKey
                .map((fieldName) => {
                    return JSON.stringify(row[fieldName], replacer);
                })
                .join(','),
        );
        csvData.unshift(headerRow.join(','));

        // Create CSV file and send to browser
        const csvArray = csvData.join('\r\n');
        const blob = new Blob([csvArray], {type: 'text/csv'});
        FileSaver.saveAs(blob, `${fileName}.csv`);
    }

    static downloadChartImage(chartInstance: ECharts, fileName: string, format: 'png' | 'jpeg') {
        let img = new Image();
        img.src = chartInstance.getDataURL({
            type: format,
        });

        FileSaver.saveAs(img.src, `${fileName}.${format}`);
    }
}
