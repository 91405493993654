import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Router} from '@angular/router';
import {SupportService} from '@core/interfaces/common/support';
import {takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Component({
    selector: 'ngx-header-settings-dialog',
    templateUrl: './header-settings-dialog.component.html',
    styleUrls: ['./header-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSettingsDialogComponent extends Unsubscribable {
    constructor(private router: Router, private supportService: SupportService) {
        super();
    }

    logout() {
        this.router.navigate(['/auth/logout']);
    }

    // Generate URL and reroute user
    public navigateUrl() {
        this.supportService
            .accessKnowledgeBase()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
                // Open URL in new window
                if (res) {
                    window.open(res);
                }
            });
    }
}
