import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    APIResponse,
    SimpleData,
    SimpleMultiSeries,
    SimpleSeries,
    ThresholdStatic,
} from '@core/interfaces/system/system-common';
import {GuideInfo} from '@theme/components';

/*
 * Request DTOs
 */
export interface LiveModelRequest {
    assetId?: string;
    model?: string;
    uploadDate?: string;
}

export interface LiveAssetRequest {
    assetId: string;
    uploadDate: string;
}

export interface LiveFleetRequest {
    uploadDate: string;
}

/*
 * Response DTOs
 */
export interface LatestModelStatus {
    modelStatusList: AlertStatusEnum[];
    status: AlertStatusEnum;
    faultType: string;
    assetInfo: InfoItem[];
    modelInfo?: InfoItem[];
}
export interface ChartModelHistoryResponse {
    data: ModelHistory[];
    statusList: AlertStatusEnum[];
}
export interface ModelHistory {
    date: string;
    alertStatus: AlertStatusEnum;
    alertStatusLabel?: string;
}

export interface ChartModelAlertHistoryResponse {
    rangeFilter: string[];
    data: SimpleData<AggregateAlert>;
    statusList: AlertStatusEnum[];
}
export interface AggregateAlert {
    status: AlertStatusEnum;
    date: Date;
    count: number;
}

export interface ChartAssetAlertHistoryResponse extends Alert {}

export interface FleetLatestStatusResponse {
    totalAssets: {current: number; previous: number; change: number};
    totalAlerts: {current: number; previous: number; change: number};
    data: SystemAlertCount[];
}

export interface RowDataReadingsDashboardData {
    data: SimpleMultiSeries<Date>;
}
export interface FaultIndicationDashboardData {
    data: SimpleSeries<Date, number>;
    thresholds: ThresholdStatic[];
}

/*
 * Supporting DTOs
 */
export interface SystemAlertCount {
    status: string;
    alertCount: number;
    assetCount: number;
    previousAlertCount: number;
    alertChange: number;
}

export interface InfoItem {
    label: string;
    value: string;
}

/*
 * Common DTOs
 */
export interface Alert {
    date: string;
    alertStatuses: {
        alertStatusLabel?: string;
        alertStatus: AlertStatusEnum;
        count: number;
    }[];
}

/*
 * Enumerations and labels
 */
// Alert status
export enum AlertStatusEnum {
    None = 'NONE',
    Information = 'INFORMATION',
    Reactive_Priority5 = 'REACTIVE_PRIORITY5',
    Reactive_Priority4 = 'REACTIVE_PRIORITY4',
    Reactive_Priority3 = 'REACTIVE_PRIORITY3',
    Reactive_Priority2 = 'REACTIVE_PRIORITY2',
    Reactive_Priority1 = 'REACTIVE_PRIORITY1',
    Predict_1month = 'PREDICT_1MONTH',
    Predict_3month = 'PREDICT_3MONTH',
    Predict_6month = 'PREDICT_6MONTH',
    Predict_1year = 'PREDICT_1YEAR',
    Predict_3year = 'PREDICT_3YEAR',
    Predict_5year = 'PREDICT_5YEAR',
}
// Index / iterator for displaying indicator
export const AlertStatusInd = {
    [AlertStatusEnum.None]: 0,
    [AlertStatusEnum.Information]: 1,
    [AlertStatusEnum.Predict_5year]: 2,
    [AlertStatusEnum.Predict_3year]: 3,
    [AlertStatusEnum.Predict_1year]: 4,
    [AlertStatusEnum.Predict_6month]: 5,
    [AlertStatusEnum.Predict_3month]: 6,
    [AlertStatusEnum.Predict_1month]: 7,
    [AlertStatusEnum.Reactive_Priority5]: 8,
    [AlertStatusEnum.Reactive_Priority4]: 9,
    [AlertStatusEnum.Reactive_Priority3]: 10,
    [AlertStatusEnum.Reactive_Priority2]: 11,
    [AlertStatusEnum.Reactive_Priority1]: 12,
};
// Model type
export enum ApmModelType {
    SF6_GAS_LEAK = 'SF6_GAS_LEAK',
    HYDROGEN_TRENDING = 'HYDROGEN_TRENDING',
    OIL_TEMP_MOISTURE = 'OIL_TEMP_MOISTURE',
    INSULATION_MOISTURE = 'INSULATION_MOISTURE',
    CO2_LOAD = 'CO2_LOAD',
    ONLINE_DGA = 'ONLINE_DGA',
    DEGREE_OF_POLYMERIZATION = 'DEGREE_OF_POLYMERIZATION',
}
export const ApmModelTypeLabel = {
    [ApmModelType.SF6_GAS_LEAK]: 'SF6 Gas Leak',
    [ApmModelType.HYDROGEN_TRENDING]: 'Hydrogen Gas Trending',
    [ApmModelType.OIL_TEMP_MOISTURE]: 'Oil Temperature vs. Moisture',
    [ApmModelType.INSULATION_MOISTURE]: 'Insulation Moisture',
    [ApmModelType.CO2_LOAD]: 'CO2 vs Load',
    [ApmModelType.ONLINE_DGA]: 'Dissolved Gas Analysis',
    [ApmModelType.DEGREE_OF_POLYMERIZATION]: 'Degree of Polymerization (DP)',
};

// TODO: can we just reverse ApmModelTypeLabel?
export const ApmModelTypeCode = {
    'SF6 Gas Leak': [ApmModelType.SF6_GAS_LEAK],
    'Hydrogen Gas Trending': [ApmModelType.HYDROGEN_TRENDING],
    'Oil Temperature vs. Moisture': [ApmModelType.OIL_TEMP_MOISTURE],
    'Insulation Moisture': [ApmModelType.INSULATION_MOISTURE],
    'CO2 vs Load': [ApmModelType.CO2_LOAD],
    'Dissolved Gas Analysis': [ApmModelType.ONLINE_DGA],
    'Degree of Polymerization (DP)': [ApmModelType.DEGREE_OF_POLYMERIZATION],
};

export abstract class LiveService {
    /*
     * Data explorer
     */
    abstract getMeterDataDatasource(): Observable<DataSource>;

    /*
     * Generic
     */
    abstract getSearchAssetIds(assetId: string, model: string): Observable<APIResponse<string[]>>;

    abstract getSearchModels(assetId: string, model: string): Observable<APIResponse<string[]>>;

    /*
     * Model, generic
     */
    abstract getCountResultsByModel(request: LiveModelRequest): Observable<number>;

    abstract getModelOutputDatasource(assetId: string, uploadDate: string): Observable<DataSource>;

    abstract getChartModelHistory(request: LiveModelRequest): Observable<APIResponse<ChartModelHistoryResponse>>;

    abstract getChartModelAlertHistory(
        request: LiveModelRequest,
        groupBy: string,
    ): Observable<APIResponse<ChartModelAlertHistoryResponse>>;

    /*
     * Asset
     */
    abstract getCountResultsByAsset(assetId: string, uploadDate: string): Observable<number>;

    abstract getChartAssetAlertHistory(
        request: LiveModelRequest,
    ): Observable<APIResponse<ChartAssetAlertHistoryResponse[]>>;

    abstract getAssetLatestModelStatus(id: string, uploadDate: string): Observable<DataSource>;

    /*
     * Fleet
     */
    abstract getCountResultsByFleet(uploadDate: string): Observable<number>;

    abstract getFleetLatestStatusDetails(request: LiveModelRequest): Observable<APIResponse<FleetLatestStatusResponse>>;

    abstract getCurrentAssetAlerts(request: LiveFleetRequest): Observable<DataSource>;

    abstract getGuideInfo(modelId: string): Observable<GuideInfo[]>;
}
