import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {IconDetails} from '@core/interfaces/common/pages';

@Component({
    selector: 'ngx-icon-box',
    template: `
        <ng-container *ngIf="iconDetails">
            <nb-icon class="icon" [icon]="iconDetails.icon" [pack]="iconDetails.pack"></nb-icon>
        </ng-container>
        <ng-container *ngIf="!iconDetails">
            <ng-container *ngIf="icon; else emptyIcon">
                <nb-icon class="icon" [icon]="icon" [pack]="'eva'"></nb-icon>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="title || subtitle">
            <div class="icon-text">
                <label class="title" *ngIf="title"> {{ title }} </label>
                <label class="subtitle-2" *ngIf="subtitle"> {{ subtitle }} </label>
            </div>
        </ng-container>

        <ng-template #emptyIcon>
            <div class="icon"></div>
        </ng-template>
    `,
    styleUrls: ['./icon-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconBoxComponent {
    @Input() icon: string;
    @Input() iconDetails: IconDetails;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() direction: string;

    @HostBinding('style.flex-direction') get boxDirection(): string {
        return this.direction ? this.direction : 'row';
    }
}
