import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {NbAuthService} from '@nebular/auth';
import {finalize, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PagesStore} from '@store/config/pages.store';
import html2canvas from 'html2canvas';
import {DOCUMENT} from '@angular/common';
import {RolesStore} from '@store/common/roles.store';
import {Page} from '@core/interfaces/common/pages';
import {Router} from '@angular/router';
import {UsersStore} from '@store/common/users.store';
import {SupportStore} from '@store/common/support.store';
import {SidebarStore} from '@store/config/sidebar.store';

@Component({
    selector: 'ngx-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    providers: [SidebarStore, RolesStore, UsersStore, SupportStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagesComponent {
    public readonly isAuthenticated$: Observable<boolean> = this.authService.onAuthenticationChange().pipe(
        tap((isAuthenticated) => {
            if (isAuthenticated) {
            }
        }),
        finalize(() => {}),
    );

    constructor(
        public pagesStore: PagesStore,
        public authService: NbAuthService,
        public sidebarStore: SidebarStore,
        // private reportsStore: ReportsStore,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    getCapture() {
        const pageContainer = this.document.getElementsByClassName('layout').item(0) as HTMLElement;
        if (pageContainer) {
            html2canvas(pageContainer).then((canvas) => {
                const data = canvas.toDataURL('image/jpeg', 1);
                const downloadLink = this.document.createElement('a');
                downloadLink['href'] = encodeURI(data);
                downloadLink['download'] = 'screenshot.jpg';
                downloadLink.click();
            });
        }
    }

    getOutputReport(module: Page) {}

    // ReportingTool() {
    //     // this.reportsStore.downloadOutputReportGeneric(module.report, this.analyzerPopoutStore.selectedSensitivityItem);
    // }
    //
    showReportConfigShortcut() {
        return !this.router.url.includes('reporting-tool');
    }
}
