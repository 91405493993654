import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export interface SidebarStatus {
    expanded: boolean;
    isMainPage: boolean;
}

@Injectable()
export class SidebarStore {
    private sidebarStatus = new BehaviorSubject<SidebarStatus>({
        expanded: false,
        isMainPage: true,
    });
    readonly sidebarStatus$: Observable<SidebarStatus> = this.sidebarStatus.asObservable();

    toggleSidebarState(expanded: boolean) {
        this.sidebarStatus.next({
            expanded: expanded,
            isMainPage: true,
        });
    }

    // private isClearPage = new BehaviorSubject<boolean>(true);
    // readonly isClearPage$: Observable<boolean> = this.isClearPage.asObservable();

    setClearPage(isMainPage: boolean) {
        this.sidebarStatus.next({
            expanded: this.sidebarStatus.getValue().expanded,
            isMainPage: isMainPage,
        });
    }
}
