export const DEFAULT_THEME = {
    name: 'default',
    base: null,
    variables: {
        chartColors: [
            '#ff5e29', // 0
            '#ffaa00', // 1
            '#ffd500', // 2
            '#b7db00', // 3
            '#84db2c', // 4
            '#4ca5ff', // 5
            '#ff708d', // 6
            '#00b383', // 7
            '#fad36d', // 8
            '#424d69', // 9
            '#c853ff', // 10
            '#776eff', // 11
            '#34cad3', // 12
            '#a6aebd', // 13
            '#ffd5de', // 14
            '#b4e9db', // 15
            '#fef3d5', // 16
            '#eabeff', // 17
            '#cae5ff', // 18
            '#e9f4b1', // 19
            '#ffe6b3', // 20
            '#d6d3ff', // 21
            '#c1eef2', // 22
            '#e4e6eb', // 23
            '#424d69', // 24
            '#879ac3', // 25
            '#ffb660', //26
            '#f0735b', //27
            '#E1492B', //28
            'rgba(255, 96, 66, 0.06)', //29
            '#579FE6', //30
        ],
        optimizerColors: [
            '#f78279', // 0
            '#c00000', // 1
            '#bdd7ee', // 2
            '#0070c0', // 3
            '#20cd17', // 4
            '#a0ffa7', // 5
            '#ffc000', // 6
            '#fff0a0', // 7
            '#c00000', // 8
            '#f78279', // 9
            '#ff7c80', // 10
            '#ffccc0', // 11
        ],

        alertStatuses: [
            '#C5CCDB', //0
            '#579FE6', //1
            '#86CDAB', //2
            '#D9D57D', //3
            '#F8BD79', //4
            '#F09EC5', //5
            '#ED7B68', //6
        ],

        primary_default: '#fe9339',
        secondary_default: '#0c8ac7',

        fontColor: '#202840',
        fontHintColor: '#9fa9bd',
        backgroundChartColor: '#fff',
        backgroundBasicColor1: '#fff',
        tooltipBg: '#ffffff',
        axisSliderBackground: '#ffffff', // color-basic-100
        axisSliderShadow: '#c5ccdb', // color-basic-500
        axisSliderHandle: '#9fa9bd', // color-basic-600

        axisNameText: '#8992A3',
        axisLine: '#e0e6f1',

        tableFilterBg: '#ffffff',
        tableFilterColor: '#2a344f',

        new_data_color: '#424d69',
        old_data_color: '#a6aebd',
    },
};
