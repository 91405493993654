import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {SimpleSeries, SimpleUnit, ThresholdStep} from '@core/interfaces/system/system-common';
import {LatestModelStatus} from '@core/interfaces/models/live';

export interface HydrogenUnits {
    hydrogenUnit: SimpleUnit;
}

export interface HydrogenGasTrendingDashboardData {
    data: SimpleSeries<Date, number>;
    thresholds: ThresholdStep[];
}

export abstract class HydrogenTrendingService {
    abstract getHydrogenGasTrendingUnits(): Observable<HydrogenUnits>;

    abstract getHydrogenGasTrendingDatasource(id: string): Observable<DataSource>;

    abstract getCountResults(id: string, uploadDate?: string): Observable<number>;

    abstract getHydrogenGasTrendingLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus>;

    abstract getHydrogenGasTrendingDashboardData(
        chart: string,
        id: string,
        uploadDate?: string,
    ): Observable<HydrogenGasTrendingDashboardData>;
}
