import {Injectable} from '@angular/core';
import {OnlineDgaApi} from '../api/online-dga.api';
import {
    DGAConcentration,
    LatestGasReading,
    OnlineDgaDashboardData,
    OnlineDgaService,
    DGADuvalPentagonData,
    GasSummary,
} from '@core/interfaces/models/online-dga';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {LatestModelStatus} from '@core/interfaces/models/live';

@Injectable()
export class OnlineDgaServiceImpl extends OnlineDgaService {
    constructor(private api: OnlineDgaApi) {
        super();
    }

    getOnlineDgaDatasource(id: string): Observable<DataSource> {
        return this.api.getOnlineDgaDatasource(id);
    }

    public getCountResults(id: string, uploadDate?: string): Observable<number> {
        return this.api.getCountResults(id, uploadDate);
    }

    getOnlineDgaLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus> {
        return this.api.getOnlineDgaLatestModelStatus(id, uploadDate);
    }

    getLatestGasReading(id: string, uploadDate: string): Observable<LatestGasReading> {
        return this.api.getLatestGasReading(id, uploadDate);
    }

    getModelDashboardDuvalPentagon(id: string, uploadDate?: string): Observable<DGADuvalPentagonData> {
        return this.api.getModelDashboardDuvalPentagon(id, uploadDate);
    }

    getModelDashboardGasSummary(id: string, uploadDate?: string): Observable<GasSummary> {
        return this.api.getModelDashboardGasSummary(id, uploadDate);
    }

    getGasConcentration(id: string, gas: string, uploadDate: string): Observable<DGAConcentration> {
        return this.api.getGasConcentration(id, gas, uploadDate);
    }
}
