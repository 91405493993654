import {Observable} from 'rxjs';
import {SupportApi} from '../api/support.api';
import {SupportService} from '../../../interfaces/common/support';
import {Injectable} from '@angular/core';

@Injectable()
export class SupportServiceImpl extends SupportService {
    constructor(private api: SupportApi) {
        super();
    }

    createTicket(ticket): Observable<any> {
        return this.api.createTicket(ticket);
    }

    accessKnowledgeBase(): Observable<any> {
        return this.api.accessKnowledgeBase();
    }
}
