import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User, UsersService} from '@core/interfaces/common/users';
import {takeUntil} from 'rxjs/operators';
import {NbAuthService, NbAuthToken} from '@nebular/auth';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {Router} from '@angular/router';
import {NbToastrService} from '@nebular/theme';

export enum Org {
    SWI,
    EII,
}
@Injectable()
export class UsersStore extends Unsubscribable {
    public currentUser = new BehaviorSubject<User>(null);
    readonly currentUser$: Observable<User> = this.currentUser.asObservable();

    constructor(
        private userService: UsersService,
        private authService: NbAuthService,
        private router: Router,
        private toastrService: NbToastrService,
    ) {
        super();
        this.userService.getCurrentUser().subscribe((user: User) => {
            // If user timezone is undefined (never set), one-time set based on timezone from browser
            if (!user.timezone) {
                const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const newUserInfo = {
                    ...user,
                    timezone: localTimezone,
                };
                this.userService
                    .update(newUserInfo)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((res: any) => {
                        const updatedUser = newUserInfo;
                        this.currentUser.next(updatedUser);
                    });
            } else {
                this.currentUser.next(user);
            }

            // Support for different icons for EII vs. partner (SWI) organizations
            try {
                const userOrg: Org = this.userOrg(user);
                if (userOrg != this.iconOrg()) {
                    const oldLinkPng = document.getElementById('dynamic-favicon-png'),
                        oldLinkIco = document.getElementById('dynamic-favicon-ico'),
                        linkPng = document.createElement('link'),
                        linkIco = document.createElement('link');
                    linkPng.id = 'dynamic-favicon-png';
                    linkPng.rel = 'icon';
                    linkPng.href = this.getFaviconPng(userOrg);
                    linkIco.id = 'dynamic-favicon-ico';
                    linkIco.rel = 'icon';
                    linkIco.href = this.getFaviconIco(userOrg);
                    if (oldLinkPng) {
                        document.head.removeChild(oldLinkPng);
                    }
                    if (oldLinkIco) {
                        document.head.removeChild(oldLinkIco);
                    }
                    document.head.appendChild(linkPng);
                    document.head.appendChild(linkIco);
                }
            } catch (err) {
                // do nothing
            }

            // Set force logout based on token expiry time
            this.authService
                .getToken()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((jwt: NbAuthToken) => {
                    const expTime = jwt.getPayload()['exp'];
                    const expTimeout = expTime * 1000 - new Date().valueOf();
                    if (expTime && expTimeout && expTimeout > 0) {
                        setTimeout(() => {
                            this.logout();
                        }, expTimeout);
                    }
                });
        });
    }

    logout() {
        // Clear all local data, navigate to login page; send session expired message
        localStorage.clear();
        this.router.navigate(['/auth/login'], {state: {sessionExpire: true}});
        setTimeout(() => {
            this.toastrService.warning(
                'Session has expired and you have been automatically logged out. ' + 'Please log back in.',
                'Session is expired',
                {duration: 0},
            );
        }, 1500);
    }

    public updateUserDefaultStudyStrategy(latestAsDefault, defaultCollectionId) {
        const currentUser: User = this.currentUser.getValue();

        // Update value if it has actually changed
        if (currentUser.studyStrategy.latestAsDefault !== latestAsDefault) {
            const newUser: User = {
                ...currentUser,
                studyStrategy: {
                    ...currentUser.studyStrategy,
                    latestAsDefault: latestAsDefault,
                    defaultCollectionId: defaultCollectionId,
                },
            };
            this.currentUser.next(newUser);
        }
    }

    public updateUserTimezone(newTimezone) {
        const currentUser: User = this.currentUser.getValue();
        const newUser: User = {
            ...currentUser,
            timezone: newTimezone,
        };
        this.currentUser.next(newUser);
    }

    private userOrg(user: User): Org {
        if (user && user.email) {
            return user.email.includes('swi') ? Org.SWI : Org.EII;
        }
        return Org.EII;
    }
    private iconOrg(): Org {
        const favicon = document.getElementById('dynamic-favicon-png');
        if (favicon) {
            return favicon['href'].includes('swi') ? Org.SWI : Org.EII;
        }
        return Org.EII;
    }
    private getFaviconPng(org: Org): string {
        return org == Org.SWI ? 'assets/favicon/favicon_swi.png' : 'assets/favicon/favicon.png';
    }
    private getFaviconIco(org: Org): string {
        return org == Org.SWI ? 'assets/favicon/favicon_swi.ico' : 'assets/favicon/favicon.ico';
    }
    public getCurrentOrg(): Org {
        try {
            return this.userOrg(this.currentUser.value);
        } catch (err) {
            return Org.EII;
        }
    }
    public checkCurrentOrg(user: User): Org {
        try {
            return this.userOrg(user);
        } catch (err) {
            return Org.EII;
        }
    }
}
