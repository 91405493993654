import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {PageGroup, PageWithLogi} from '../../../interfaces/common/pages';

@Injectable()
export class PagesApi {
    private readonly apiController: string = 'pages';

    constructor(private api: HttpService) {}

    getPages(): Observable<PageGroup[]> {
        return this.api.get(`${this.apiController}/`);
    }

    getLogiComponentsSettings(): Observable<PageWithLogi[]> {
        return this.api.get(`${this.apiController}/logi-components`);
    }

    updateLogiComponentsSettings(pages: PageWithLogi[]): Observable<boolean> {
        return this.api.post(`${this.apiController}/logi-components`, pages);
    }

    getDashboardInfo(): Observable<PageGroup[]> {
        return this.api.get(`${this.apiController}/dashboard`);
    }
}
