import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {Workflow, WorkflowItem} from '../../../interfaces/engin/workflow';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {SnapshotDto} from '../../../interfaces/common/snapshot';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class WorkflowApi {
    private readonly prefix = 'workflow';

    constructor(private api: HttpService) {}

    getWorkflowDataSource(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list`);
    }

    getWorkflowById(workflowId: number): Observable<APIResponse<Workflow>> {
        return this.api.get(`${this.prefix}/find/${workflowId}`);
    }

    getWorkflowItemById(workflowItemId: number): Observable<APIResponse<WorkflowItem>> {
        return this.api.get(`${this.prefix}/find/workflowItem/${workflowItemId}`);
    }

    getLatestCompletedWorkFlow(): Observable<APIResponse<Workflow>> {
        return this.api.get(`${this.prefix}/find/latest`);
    }

    executeNewWorkflowFromSnapshot(snapshotId: number, automated: boolean, date: string): Observable<any> {
        const description = (automated ? 'Data refresh ' : 'Run on ') + date.slice(0, 10);
        const name = automated ? 'Automated multi-study run' : 'Manual multi-study run';
        const body = {
            snapshotId: snapshotId,
            automatedExecution: automated,
            name: name,
            description: description,
        };
        return this.api.post(`${this.prefix}/execute`, body);
    }

    updateWorkflowNoteTag(workflowId: number, workflow: Workflow): Observable<APIResponse<Workflow>> {
        return this.api.put(`${this.prefix}/update/${workflowId}`, workflow);
    }

    findBySnapshotId(snapshotId: number): Observable<APIResponse<Workflow>> {
        return this.api.get(`${this.prefix}/find/snapshot/${snapshotId}`);
    }

    delete(workflowId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${workflowId}`);
    }

    reversePendingDelete(workflowId: number): Observable<APIResponse<boolean>> {
        return this.api.put(`${this.prefix}/reverse-pending-delete/${workflowId}`, {});
    }

    updateActiveWorkflow(workflowId: number, userId: string, active: boolean): Observable<APIResponse<boolean>> {
        const req = {
            workflowId: workflowId,
            userId: userId,
            active: active,
        };
        return this.api.post(`${this.prefix}/toggle-active`, req);
    }

    findBySnapshotDtoByWorkflowId(workflowId: number): Observable<APIResponse<SnapshotDto>> {
        return this.api.get(`${this.prefix}/find/snapshot-dto/${workflowId}`);
    }

    findAllBySnapshotId(snapshotId: number): Observable<APIResponse<Workflow[]>> {
        return this.api.get(`${this.prefix}/find/snapshot-all/${snapshotId}`);
    }
}
