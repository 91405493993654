<!-- Icon in sidebar -->
<ng-container>
    <div *ngIf="currentPage.asObservable() | async as currentPage" class="help-support-menu">
        <div class="menu-items">
            <!-- Collapsed -->
            <div
                *ngIf="!expanded; else expended"
                [nbPopover]="labelBox"
                [nbPopoverContext]="'Help Desk'"
                nbPopoverPlacement="right"
                nbPopoverTrigger="hover"
            >
                <div class="menu-item-container">
                    <ngx-icon-box
                        class="menu-item-link"
                        (click)="openSupportDialog(dialog)"
                        [icon]="'question-mark-circle'"
                    >
                    </ngx-icon-box>
                </div>
            </div>

            <!-- Expanded -->
            <ng-template #expended>
                <div class="menu-item-container">
                    <ngx-icon-box
                        class="menu-item-link"
                        (click)="openSupportDialog(dialog)"
                        *ngIf="expanded"
                        [title]="'Help'"
                        [icon]="'question-mark-circle'"
                    >
                    </ngx-icon-box>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>

<!-- Support form -->
<ng-template #dialog let-data let-ref="dialogRef" class="dialog">
    <div class="help-support-container">
        <nb-card [formGroup]="helpDeskInfoFormGroup" class="help-support-container">
            <!-- Header -->
            <nb-card-header>
                <div class="container-fluid">
                    <div class="row justify-content-between">
                        <div class="col-11">
                            {{ dialogTitle }}
                            <p>{{ dialogSubtitle }}</p>
                        </div>
                        <div class="col-1" (click)="ref.close()">
                            <nb-icon icon="close" style="color: #a6aebd; cursor: pointer"></nb-icon>
                        </div>
                    </div>
                </div>
            </nb-card-header>

            <!-- Body, collects support information -->
            <nb-card-body>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-6">
                            <div class="container">
                                <div class="row">
                                    <div class="col p-0">
                                        <div>
                                            <!-- User name -->
                                            <p class="labels">{{ dialogFieldName }}</p>
                                            <input
                                                nbInput
                                                fullWidth
                                                fieldSize="large"
                                                formControlName="name"
                                                [status]="
                                                    (nameFormControl.touched &&
                                                        nameFormControl?.hasError('required')) ||
                                                    nameFormControl?.hasError('pattern')
                                                        ? 'danger'
                                                        : ''
                                                "
                                                placeholder="User Name"
                                            />
                                            <ngx-validation-message
                                                label="{{ dialogValidationName }}"
                                                [showPattern]="
                                                    nameFormControl.touched && nameFormControl?.hasError('pattern')
                                                "
                                                [showRequired]="
                                                    nameFormControl.touched && nameFormControl?.hasError('required')
                                                "
                                            >
                                            </ngx-validation-message>
                                        </div>

                                        <!-- Email -->
                                        <div>
                                            <p class="labels">{{ dialogFieldEmail }}</p>
                                            <input
                                                nbInput
                                                fullWidth
                                                fieldSize="large"
                                                formControlName="email"
                                                [status]="
                                                    (emailFormControl.touched &&
                                                        emailFormControl?.hasError('required')) ||
                                                    emailFormControl?.hasError('pattern')
                                                        ? 'danger'
                                                        : ''
                                                "
                                                placeholder="Email"
                                            />
                                            <ngx-validation-message
                                                label="{{ dialogValidationEmail }}"
                                                [showPattern]="
                                                    emailFormControl.touched && emailFormControl?.hasError('pattern')
                                                "
                                                [showRequired]="
                                                    emailFormControl.touched && emailFormControl?.hasError('required')
                                                "
                                            >
                                            </ngx-validation-message>
                                        </div>

                                        <!-- Context -->
                                        <div *ngIf="vm.contextList$ | async as contextList">
                                            <p class="labels">{{ dialogFieldContext }}</p>
                                            <nb-select fullWidth formControlName="context" placeholder="Select Context">
                                                <nb-option
                                                    *ngFor="let contextItem of contextList"
                                                    [value]="contextItem.code"
                                                >
                                                    {{ contextItem.name }}
                                                </nb-option>
                                            </nb-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="container">
                                <div class="row">
                                    <div class="col p-0">
                                        <!-- Subject -->
                                        <div style="padding-bottom: 5px">
                                            <label class="m-0">
                                                <p class="labels">{{ dialogFieldSubject }}</p>
                                                <textarea
                                                    nbInput
                                                    fullWidth
                                                    cols="40"
                                                    rows="5"
                                                    formControlName="subject"
                                                    placeholder="Subject"
                                                    [status]="
                                                        subjectFormControl?.touched &&
                                                        (subjectFormControl?.hasError('minlength') ||
                                                            subjectFormControl?.hasError('maxlength'))
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                    class="input-subject"
                                                >
                                                </textarea>
                                            </label>
                                            <ngx-validation-message
                                                label="{{ dialogValidationSubject }}"
                                                [showMinLength]="
                                                    subjectFormControl?.hasError('minlength') &&
                                                    subjectFormControl.touched
                                                "
                                                [showMaxLength]="
                                                    subjectFormControl?.hasError('maxlength') &&
                                                    subjectFormControl.touched
                                                "
                                                [showRequired]="
                                                    !submitted ||
                                                    (subjectFormControl.touched &&
                                                        subjectFormControl?.hasError('required'))
                                                "
                                                [minLength]="3"
                                                [maxLength]="1000"
                                            >
                                            </ngx-validation-message>
                                        </div>

                                        <!-- Description -->
                                        <div>
                                            <label class="m-0">
                                                <p class="labels">{{ dialogFieldDescription }}</p>
                                                <textarea
                                                    nbInput
                                                    fullWidth
                                                    cols="40"
                                                    rows="5"
                                                    formControlName="description"
                                                    placeholder="Description of issue"
                                                    [status]="
                                                        descriptionFormControl?.touched &&
                                                        (descriptionFormControl?.hasError('minlength') ||
                                                            descriptionFormControl?.hasError('maxlength'))
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                    class="input-description"
                                                >
                                                </textarea>
                                            </label>
                                            <ngx-validation-message
                                                label="{{ dialogValidationDescription }}"
                                                [showMinLength]="
                                                    descriptionFormControl?.hasError('minlength') &&
                                                    descriptionFormControl.touched
                                                "
                                                [showMaxLength]="
                                                    descriptionFormControl?.hasError('maxlength') &&
                                                    descriptionFormControl.touched
                                                "
                                                [showRequired]="
                                                    !submitted ||
                                                    (descriptionFormControl.touched &&
                                                        descriptionFormControl?.hasError('required'))
                                                "
                                                [minLength]="3"
                                                [maxLength]="10000"
                                            >
                                            </ngx-validation-message>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nb-card-body>

            <nb-card-footer>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col">
                            <button
                                nbButton
                                (click)="ref.close()"
                                size="small"
                                status="basic"
                                class="m-2"
                                fullWidth
                                style="border-color: transparent; float: right"
                            >
                                <ngx-icon-box title="Cancel" icon="close-outline"> </ngx-icon-box>
                            </button>
                        </div>
                        <div class="col">
                            <button
                                nbButton
                                status="success"
                                (click)="ref.close('submit')"
                                fullWidth
                                type="submit"
                                size="small"
                                class="m-2"
                            >
                                <ngx-icon-box title="Submit" icon="arrow-circle-right"> </ngx-icon-box>
                            </button>
                        </div>
                    </div>
                </div>
            </nb-card-footer>
        </nb-card>
    </div>
</ng-template>

<ng-template #labelBox let-text>
    <div class="label-container" (click)="openSupportDialog(dialog)">
        <div class="label-body" [ngStyle]="expanded ? {display: 'none'} : {display: 'flex'}">
            <label class="label">{{ text }}</label>
        </div>
    </div>
</ng-template>
