import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {LatestModelStatus} from '@core/interfaces/models/live';
import {SimpleSeries, SimpleUnit, ThresholdStatic} from '@core/interfaces/system/system-common';

export class DGAReadingItem {
    gas: string;
    concentration: number;
    rate: number;
    deltaRatio: number;
}

export interface DGAConcentration {
    data: SimpleSeries<string, number>;
    thresholds: any[];
}

export interface LatestGasReading {
    assetInfo: InfoItem[];
    gasInfo: InfoItem[];
    data: DGAReadingItem[];
}

export interface InfoItem {
    label: string;
    value: string;
    order: number;
}

export interface OnlineDgaDashboardData {
    data: any;
}

export interface DGADuvalPentagonData {
    data: SimpleSeries<Date, DuvalPentagon>;
}

export interface DuvalPentagon {
    time: any;
    timeStr?: string;
    h2: number;
    ch4: number;
    c2h4: number;
    c2h2: number;
    c2h6: number;
    centroid: number[][];
}

export interface Unit {
    prefix: string;
    suffix: string;
    symbol: string;
}

export interface GasSummary {
    gases: Gas[];
}

export interface Gas {
    date: string;
    name: string;
    concentration: number;
    rate: number;
    concentrationUnit: SimpleUnit;
    rateUnit: SimpleUnit;
    concentrationThresholds: ThresholdStatic[];
    rateThresholds: ThresholdStatic[];
}

export abstract class OnlineDgaService {
    abstract getOnlineDgaDatasource(id: string): Observable<DataSource>;

    abstract getCountResults(id: string, uploadDate?: string): Observable<number>;

    abstract getOnlineDgaLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus>;

    abstract getLatestGasReading(id: string, uploadDate: string): Observable<LatestGasReading>;

    abstract getModelDashboardDuvalPentagon(id: string, uploadDate?: string): Observable<DGADuvalPentagonData>;

    abstract getModelDashboardGasSummary(id: string, uploadDate?: string): Observable<GasSummary>;

    abstract getGasConcentration(id: string, gas: string, uploadDate: string): Observable<DGAConcentration>;
}
