import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ECharts} from 'echarts';

@Component({
    selector: 'ngx-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent {
    @Input() options: any;
    @Input() height: string;

    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onChartDataZoom: EventEmitter<any> = new EventEmitter<any>();
    @Output() onChartInstanceInit: EventEmitter<ECharts> = new EventEmitter<ECharts>();

    onChartInit(e: any) {
        this.onChartInstanceInit.emit(e);
    }
}
