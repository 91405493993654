import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ngx-sidebar-action-button',
    templateUrl: './sidebar-action.component.html',
    styleUrls: ['./sidebar-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarActionComponent {
    constructor() {}

    @Input() text;
    @Input() icon;
    @Input() expanded;

    @Output() doAction = new EventEmitter();
}
