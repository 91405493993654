<ng-container *ngIf="list">
    <div class="container">
        <div class="block" *ngFor="let block of list" [style.width]="100 / list.length + '%'">
            {{ block.label }}
            <div class="colored-block" [style.background-color]="block.color"></div>
        </div>
    </div>

    <div class="line-container">
        <div class="line"></div>
        <span class="bullet" [style]="getBulletStyle()"></span>
        <nb-icon icon="arrow-ios-forward" status="basic"></nb-icon>
    </div>
</ng-container>
