import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpRequest} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {
    NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
    NbAuthJWTInterceptor,
    NbAuthJWTToken,
    NbAuthModule,
    NbPasswordAuthStrategy,
    NbTokenLocalStorage,
} from '@nebular/auth';
import {AuthInterceptor} from './auth.interceptor';
import {AuthGuard} from './auth.guard';
import {AuthPipe} from './auth.pipe';
import {RoleProvider} from './role.provider';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {environment} from '../../environments/environment';
import {PermissionsGuard} from './permissions.guard';
import {RerouteGuard} from './reroute.guard';

const GUARDS = [AuthGuard, PermissionsGuard, RerouteGuard];
const PIPES = [AuthPipe];

export function filterInterceptorRequest(req: HttpRequest<any>): boolean {
    return ['/auth/login', '/auth/sign-up', '/auth/request-pass', '.s3.'].some((url) => req.url.includes(url));
}

@NgModule({
    declarations: [...PIPES],
    imports: [
        CommonModule,
        NbAuthModule.forRoot({
            strategies: [
                NbPasswordAuthStrategy.setup({
                    name: 'email',
                    baseEndpoint: environment.NODE_SERVER_URL,
                    login: {
                        endpoint: '/auth/login',
                        method: 'post',
                    },
                    register: {
                        endpoint: '/auth/sign-up',
                        method: 'post',
                    },
                    logout: {
                        endpoint: '/auth/sign-out',
                        method: 'post',
                    },
                    requestPass: {
                        endpoint: '/auth/request-pass',
                        method: 'post',
                    },
                    resetPass: {
                        endpoint: '/auth/reset-pass',
                        method: 'post',
                    },
                    token: {
                        class: NbAuthJWTToken,
                        key: 'token',
                    },
                }),
            ],
        }),
    ],
    exports: [...PIPES],
    providers: [
        NbSecurityModule.forRoot({
            accessControl: {
                user: {},
                admin: {
                    parent: 'user',
                    download: 'uploads',
                    manage: ['user', 'admin'],
                },
                superadmin: {
                    parent: 'admin',
                    manage: 'superadmin',
                },
            },
        }).providers,
        {
            provide: NbRoleProvider,
            useClass: RoleProvider,
        },
        {
            provide: NbTokenLocalStorage,
            useClass: NbTokenLocalStorage,
        },
    ],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: filterInterceptorRequest},
                {provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true},
                {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
                ...GUARDS,
            ],
        };
    }
}
