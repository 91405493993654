import {ModuleWithProviders, NgModule} from '@angular/core';
import {JobsApi} from './api/jobs.api';
import {JobsService} from '../../interfaces/engin/jobs';
import {JobsServiceImpl} from './services/jobs.service';
import {CommonModule} from '@angular/common';
import {CommonBackendModule} from '../common/common-backend.module';
import {WorkflowApi} from './api/workflow.api';
import {WorkflowService} from '@core/interfaces/engin/workflow';
import {WorkflowServiceImpl} from './services/workflow.service';
import {LiveApi} from '@core/backend/models/api/live.api';
import {LiveService} from '@core/interfaces/models/live';
import {LiveServiceImpl} from '@core/backend/models/services/live.service';
import {AssetDetailsApi} from '@core/backend/engin/api/asset-details.api';
import {AssetDetailsService} from '@core/interfaces/engin/asset-details';
import {AssetDetailsServiceImpl} from '@core/backend/engin/services/asset-details.service';

const API = [
    LiveApi,
    AssetDetailsApi,
    /* deprecated */
    JobsApi,
    WorkflowApi,
];

// new comment
// new comment 2
const SERVICES = [
    {provide: JobsService, useClass: JobsServiceImpl},
    {provide: WorkflowService, useClass: WorkflowServiceImpl},
    {provide: LiveService, useClass: LiveServiceImpl},
    {provide: AssetDetailsService, useClass: AssetDetailsServiceImpl},
];

@NgModule({
    imports: [CommonModule, CommonBackendModule],
    exports: [CommonBackendModule],
})
export class EnginBackendModule {
    static forRoot(): ModuleWithProviders<EnginBackendModule> {
        return {
            ngModule: EnginBackendModule,
            providers: [...API, ...SERVICES],
        };
    }
}
