import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'ngx-table-pager',
    styleUrls: ['./table-pager.component.scss'],
    templateUrl: './table-pager.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TablePagerComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablePagerComponent implements ControlValueAccessor, OnChanges {
    pages: any[] = [];
    @Output()
    private onChange: any = () => {};

    @Output() onPageSelected = new EventEmitter();

    constructor(private cd: ChangeDetectorRef) {}

    private _pagesCount: number;

    @Input()
    set pagesCount(count: number) {
        this._pagesCount = count;
        this.initPages();
        this.onChange(this._selectedPage);
        this.cd.markForCheck();
    }

    private _selectedPage: number;

    get pagesCount(): number {
        return this._pagesCount;
    }

    get selectedPage(): number {
        return this._selectedPage;
    }

    @Input()
    set selectedPage(page: number) {
        this._selectedPage = page;
        this.initPages();
        this.onChange(page);
        this.cd.markForCheck();
    }

    initPages(): any {
        this.pages = [];
        const allPages = Array.from({length: this._pagesCount}, (v, k) => k + 1);

        allPages.forEach((pageNumber) => {
            if (this.displayThreePoints(pageNumber)) {
                this.pages.push('...');
            } else {
                if (this.displayPageNumber(pageNumber)) {
                    this.pages.push(pageNumber);
                }
            }
        });
    }

    selectPage(page: number) {
        if (!isNaN(page)) {
            this.selectedPage = page;
            this.onPageSelected.emit(this.selectedPage);
        }
    }

    prevPage() {
        if (this.selectedPage > 1) {
            this.selectPage(this.selectedPage - 1);
        }
    }

    nextPage() {
        if (this.selectedPage < this._pagesCount) {
            this.selectPage(this.selectedPage + 1);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    writeValue(page: number): void {
        this.selectedPage = page;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initPages();
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    private displayThreePoints(page) {
        return (
            !(this._pagesCount <= 7) &&
            ((page === 2 && this.selectedPage >= 5) ||
                (page === this._pagesCount - 1 &&
                    (this.selectedPage < 5 || (this.selectedPage < this._pagesCount - 3 && this.selectedPage > 4))))
        );
    }

    private displayPageNumber(page) {
        return (
            page === 1 ||
            page === this._pagesCount ||
            this._pagesCount <= 7 ||
            (this.selectedPage < 5 && page < 6) ||
            (this.selectedPage > this._pagesCount - 4 && page >= this._pagesCount - 4) ||
            (this.selectedPage < this._pagesCount - 3 &&
                this.selectedPage > 4 &&
                page <= this.selectedPage + 1 &&
                page >= this.selectedPage - 1)
        );
    }
}
