import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {SimpleUnit} from '@core/interfaces/system/system-common';
import {FaultIndicationDashboardData, LatestModelStatus, RowDataReadingsDashboardData} from './live';

export interface OilTemperatureVsMoistureUnits {
    oilTemperatureUnit: SimpleUnit;
    oilMoistureUnit: SimpleUnit;
    paperMoistureUnit: SimpleUnit;
}

export abstract class OilTemperatureMoistureService {
    abstract getOilTemperatureVsMoistureUnits(): Observable<OilTemperatureVsMoistureUnits>;

    abstract getOilTemperatureVsMoistureDatasource(id: string): Observable<DataSource>;

    abstract getCountResults(id: string, uploadDate?: string): Observable<number>;

    abstract getOilTemperatureVsMoistureLatestModelStatus(
        id: string,
        uploadDate?: string,
    ): Observable<LatestModelStatus>;

    abstract getOilRowDataReadingsDashboardData(
        id: string,
        uploadDate?: string,
    ): Observable<RowDataReadingsDashboardData>;

    abstract getOilFaultIndicationDashboardData(
        id: string,
        uploadDate?: string,
    ): Observable<FaultIndicationDashboardData>;
}
