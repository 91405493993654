import {Component} from '@angular/core';
import {NbThemeService} from '@nebular/theme';

@Component({
    selector: 'ngx-theme-switcher',
    styleUrls: ['./theme-switcher.component.scss'],
    templateUrl: './theme-switcher.component.html',
})
export class ThemeSwitcherComponent {
    private firstValue: string = 'default';
    private secondValue: string = 'dark';

    private currValue: string;

    constructor(private nbThemeService: NbThemeService) {
        this.currValue = this.nbThemeService.currentTheme;
    }

    isFirstValue() {
        return this.currValue === this.firstValue;
    }

    isSecondValue() {
        return this.currValue === this.secondValue;
    }

    changeValue() {
        this.currValue = this.isFirstValue() ? this.secondValue : this.firstValue;
        this.nbThemeService.changeTheme(this.currValue);
    }
}
