import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {AssetStaticInfo} from '@core/interfaces/engin/asset-details';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class AssetDetailsApi {
    private readonly prefix = 'asset';

    constructor(private api: HttpService) {}

    public getAssetInfoDetails(assetId: string): Observable<APIResponse<AssetStaticInfo[]>> {
        return this.api.get(`${this.prefix}/${assetId}`);
    }
}
