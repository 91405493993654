import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, filter, map, shareReplay, startWith, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {ServerDataSource} from '@mominsamir/ngx-smart-table';

export interface InitFilter {
    field: string;
    value: string;
}

@Component({
    selector: 'ngx-data-table',
    templateUrl: './data-table-component.html',
    styleUrls: ['./data-table-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent extends Unsubscribable implements OnInit, OnDestroy {
    data: ServerDataSource;
    id: number; // interval hook id
    count: number = 0;

    @Input() settings: any;
    @Input() title: string;
    @Input() subTitle: string;
    @Input() initFilter: InitFilter[] = [];
    @Input() source: Observable<ServerDataSource> = new BehaviorSubject<ServerDataSource>(null);
    @Output() onCustomEvent = new EventEmitter();
    @Output() onRowSelect = new EventEmitter();
    @Input() reload: Observable<number>;

    public pagingFormGroup = this.fb.group({
        perPage: this.fb.control(8),
    });

    readonly perPage$: Observable<number> = this.perPageFormControl.valueChanges.pipe(
        startWith(8),
        filter((perPage) => perPage > 0),
        debounceTime(500),
    );

    constructor(protected fb: FormBuilder, private cdr: ChangeDetectorRef) {
        super();

        cdr.detach();
        this.id = setInterval(() => {
            this.cdr.detectChanges();
        });
    }

    ngOnInit(): void {
        this.createData();

        // Reset records per page  based on settings, if defined
        if (this.settings.pager && this.settings.pager.perPage) {
            this.setPerPageFormControl(this.settings.pager.perPage);
        }
    }

    ngOnDestroy() {
        if (this.id) clearInterval(this.id);
    }

    createData() {
        combineLatest<Observable<ServerDataSource>, Observable<number>, Observable<number>>([
            this.source,
            this.perPage$,
            this.reload,
        ])
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((dataSource) => dataSource[0] !== null && dataSource[1] > 0),
                map(([source, perPage, _]: [ServerDataSource, number, number]) => {
                    const paging = source.getPaging();

                    if (!paging.page) {
                        source.setPaging(1, +perPage, false);
                        if (
                            this.settings.pager &&
                            this.settings.pager.perPage &&
                            this.perPageFormControl.value !== this.settings.pager.perPage
                        ) {
                            this.settings = {
                                ...this.settings,
                                pager: {...this.settings.pager, perPage: this.perPageFormControl.value},
                            };
                        }
                    } else if (source.getPaging().perPage !== perPage) {
                        // this block executed when per page records are changed.
                        source.setPaging(paging.page, +perPage, true);
                    }
                    if (this.initFilter.length > 0) {
                        // this sets initial filters
                        this.initFilter
                            .map((d) => ({field: d.field + '_G', search: d.value}))
                            .forEach((x) => source.addFilter(x, false));
                    }
                    return source;
                }),
                debounceTime(500),
                shareReplay(1),
            )
            .subscribe((data) => {
                data.refresh();
                this.data = data;
            });
    }

    onCustomEventClick($event): void {
        this.onCustomEvent.emit($event);
    }

    private get perPageFormControl(): FormControl {
        return this.pagingFormGroup.get('perPage') as FormControl;
    }

    private setPerPageFormControl(val: number) {
        this.perPageFormControl.reset(val);
    }

    onSearch(query: string = '') {
        Object.keys(this.settings.columns)
            .filter((k) => this.settings.columns[k].type === 'string')
            .map((k) => ({field: k + '_G', search: query}));
    }

    enableHeader = () => (this.settings['enableHeader'] === undefined ? true : this.settings['enableHeader']);

    disableGlobalSearch = () => this.settings['enableGlobalSearch'];

    enablePagePerRecord = () =>
        this.settings['enablePagePerRecord'] === undefined ? false : this.settings['enablePagePerRecord'];

    onUserRowSelect = ($event) => this.onRowSelect.emit($event);
}
