import {Injectable} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {BehaviorSubject, Observable} from 'rxjs';
import {AlertStatusItem, ConfigurationService, DatasetItem, LiveConfig} from '@core/interfaces/common/configuration';
import {filter, map, takeUntil} from 'rxjs/operators';
import {APIResponse, SimpleUnit} from '@core/interfaces/system/system-common';
import {AlertStatusEnum} from '@core/interfaces/models/live';

@Injectable()
export class ConfigurationStore extends Unsubscribable {
    private liveConfig: BehaviorSubject<LiveConfig> = new BehaviorSubject<LiveConfig>(null);
    readonly liveConfig$: Observable<LiveConfig> = this.liveConfig.asObservable().pipe(filter((d) => d && !!d));

    constructor(private configurationService: ConfigurationService) {
        super();

        this.configurationService
            .getLiveConfiguration()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: APIResponse<LiveConfig>) => {
                this.liveConfig.next(res.response);
            });
    }

    readonly datasetList$: Observable<DatasetItem[]> = this.configurationService
        .getDatasets()
        .pipe(map((res) => res.response));

    // Helper functions for LiveConfig
    public statusToLabel(status: AlertStatusEnum): string {
        if (this.liveConfig.value) {
            const mapping: AlertStatusItem[] = this.liveConfig.value.statuses;
            return mapping.filter((m) => m.code === status)[0]
                ? mapping.filter((m) => m.code === status)[0].label
                : status;
        }
        return status.toString();
    }

    public getLiveConfig(): LiveConfig {
        if (this.liveConfig.value) {
            return this.liveConfig.value;
        }
        return null;
    }
    public getLiveStatuses(): AlertStatusItem[] {
        if (this.liveConfig.value) {
            return this.liveConfig.value.statuses;
        }
        return Object.keys(AlertStatusEnum).map((k: string) => {
            return {
                code: AlertStatusEnum[k],
                label: k,
            };
        });
    }
    public getSimpleUnit(code: string): SimpleUnit {
        if (this.liveConfig.value) {
            return this.liveConfig.value.units.filter((u) => u.code === code)[0]?.unit;
        }
    }
}
