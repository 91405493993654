import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {
    DGAConcentration,
    LatestGasReading,
    OnlineDgaDashboardData,
    DGADuvalPentagonData,
    GasSummary,
} from '@core/interfaces/models/online-dga';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {LatestModelStatus} from '@core/interfaces/models/live';
import {HttpParams} from '@angular/common/http';
import {APIResponse} from '@core/interfaces/system/system-common';
import {map} from 'rxjs/operators';

@Injectable()
export class OnlineDgaApi {
    private readonly PREFIX = 'live/output/online-dga';

    constructor(private api: HttpService) {}

    public getOnlineDgaDatasource(id: string): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.PREFIX}/list?filterByassetId=${id}`);
    }

    public getCountResults(assetId: string, uploadDate?: string): Observable<number> {
        return this.api
            .get(`${this.PREFIX}/count`, {
                params: new HttpParams().set('assetId', assetId || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<number>) => res.response));
    }

    public getOnlineDgaLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus> {
        return this.api
            .get(`${this.PREFIX}/latest`, {
                params: new HttpParams().set('assetId', id || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<LatestModelStatus>) => res.response));
    }

    public getLatestGasReading(id: string, uploadDate: string): Observable<LatestGasReading> {
        return this.api
            .get(`${this.PREFIX}/latest-gas-reading`, {
                params: new HttpParams().set('assetId', id || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<LatestGasReading>) => res.response));
    }

    public getModelDashboardDuvalPentagon(id: string, uploadDate?: string): Observable<DGADuvalPentagonData> {
        return this.api
            .get(`${this.PREFIX}/data/duval-pentagon`, {
                params: new HttpParams().set('assetId', id || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<DGADuvalPentagonData>) => res.response));
    }

    public getModelDashboardGasSummary(id: string, uploadDate?: string): Observable<GasSummary> {
        return this.api
            .get(`${this.PREFIX}/data/gas-summary`, {
                params: new HttpParams().set('assetId', id || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<GasSummary>) => res.response));
    }

    public getGasConcentration(id: string, gas: string, uploadDate: string): Observable<DGAConcentration> {
        return this.api
            .get(`${this.PREFIX}/data/gas-concentration`, {
                params: new HttpParams()
                    .set('assetId', id || '')
                    .set('uploadDate', uploadDate || '')
                    .set('gas', gas || 'oxygen'),
            })
            .pipe(map((res: APIResponse<DGAConcentration>) => res.response));
    }
}
