<nb-card>
    <nb-card-header>{{ confirmationData.title }}</nb-card-header>
    <nb-card-body>
        {{ confirmationData.message }}
    </nb-card-body>
    <nb-card-footer>
        <nb-actions [size]="'medium'">
            <nb-action>
                <button nbButton size="medium" status="basic" (click)="cancel()">
                    <span>{{ confirmationData.cancelText ? confirmationData.cancelText : "Cancel" }}</span>
                </button>
            </nb-action>
            <nb-action>
                <button nbButton size="medium" status="info" (click)="confirm()">
                    <span>{{ confirmationData.confirmText ? confirmationData.confirmText : "Confirm" }}</span>
                </button>
            </nb-action>
        </nb-actions>
    </nb-card-footer>
</nb-card>
