<ng-container>
    <div class="menu-items">
        <ng-container *ngFor="let item of items">
            <div
                *ngIf="item.icon"
                class="menu-item-container"
                [nbPopover]="!expanded && submenuTemplate"
                [nbPopoverContext]="item"
                nbPopoverPlacement="right"
                nbPopoverTrigger="hover"
                [routerLink]="item.link"
                routerLinkActive="active"
            >
                <ngx-icon-box
                    (click)="expanded && item.children && (item.opened = true); $event.stopPropagation()"
                    class="menu-item-link"
                    [routerLink]="item.link"
                    routerLinkActive="active"
                    #rla="routerLinkActive"
                    [title]="expanded ? item.title : null"
                    [iconDetails]="getIconDetails(rla.isActive, item.icon)"
                >
                </ngx-icon-box>
                <ng-container *ngIf="expanded && item.children">
                    <ngx-icon-box
                        (click)="expanded && (item.opened = !item.opened); $event.stopPropagation()"
                        class="open-arrow"
                        [icon]="item.opened ? 'chevron-down-outline' : 'chevron-left-outline'"
                    >
                    </ngx-icon-box>
                </ng-container>
            </div>
            <ng-container *ngIf="expanded && item.opened">
                <ngx-submenu [items]="item.children"></ngx-submenu>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<ng-template #submenuTemplate let-item>
    <ngx-submenu-popover [item]="item"></ngx-submenu-popover>
</ng-template>
