import {ColorsService} from './colors.service';
import {map, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FontsService} from './fonts.service';
import {Injectable} from '@angular/core';
import {FormatsService} from '@core/utils/formats.service';

export interface Chart {
    options: any;
    colorsMap: any[];
    metadata?: any;
}

@Injectable()
export class ChartsService {
    public colors = this.colorsService.colorsMap$.pipe(shareReplay(1));

    public getChartFooterIconsMap(name: string): any {
        return [
            {
                name: ['Past Economic EOL', 'Past TUL'],
                icon: 'alert-circle',
                iconContainerClass: 'chart-color-1',
            },
            {
                name: ['Approaching Economic EOL', 'Approaching TUL'],
                icon: 'alert-triangle',
                iconContainerClass: 'chart-color-2',
            },
            {
                name: ['Not Near EOL', 'Not Near TUL'],
                icon: 'checkmark-circle-2',
                iconContainerClass: 'chart-color-5',
            },
            {
                name: ['N/A'],
                icon: '',
                iconContainerClass: 'chart-color-none empty-icon',
            },
            {
                name: ['Very Poor'],
                icon: '',
                iconContainerClass: 'chart-color-1 empty-icon',
            },
            {
                name: ['Poor'],
                icon: '',
                iconContainerClass: 'chart-color-2 empty-icon',
            },
            {
                name: ['Fair'],
                icon: '',
                iconContainerClass: 'chart-color-3 empty-icon',
            },
            {
                name: ['Good'],
                icon: '',
                iconContainerClass: 'chart-color-4 empty-icon',
            },
            {
                name: ['Very Good'],
                icon: '',
                iconContainerClass: 'chart-color-5 empty-icon',
            },
            {
                name: ['Total'],
                icon: '',
                iconContainerClass: 'chart-color-empty empty-icon',
            },
        ].find((item) => item.name.includes(name));
    }

    readonly donutChartWithHighlight$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            const numberSize = this.fontsService.pxToRem(window.innerWidth, 'numberSize');
            const fontSize = this.fontsService.pxToRem(window.innerWidth, 'fontSize');
            const titleFontSize = this.fontsService.pxToRem(window.innerWidth, 'titleFontSize');
            const subTitleFontSize = this.fontsService.pxToRem(window.innerWidth, 'subTitleFontSize');
            return {
                options: {
                    title: {
                        x: 'center',
                        y: 'top',
                        itemGap: 5,
                        textStyle: {
                            fontSize: titleFontSize,
                            color: colors['primaryFontColor'],
                        },
                        subtextStyle: {
                            fontSize: subTitleFontSize,
                            color: colors['hintFontColor'],
                        },
                    },
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        formatter: (params) => {
                            return `${params.data.name}: ${params.data.formattedValue}`;
                        },
                        textStyle: {
                            fontSize: 10,
                        },
                    },
                    series: [
                        {
                            name: 'data',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: true,
                            itemStyle: {
                                normal: {
                                    label: {
                                        position: 'inner',
                                        formatter: function (params) {
                                            return (params.percent - 0).toFixed(0) + '%';
                                        },
                                    },
                                    labelLine: {
                                        show: false,
                                    },
                                },
                            },
                        },
                        {
                            name: 'highLightedTop',
                            type: 'pie',
                            radius: ['70%', '70%'],
                            tooltip: {
                                show: false,
                            },
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true,
                                        position: 'center',
                                        formatter: function (params) {
                                            return params.value;
                                        },
                                        textStyle: {
                                            fontSize: numberSize,
                                            // fontSize: 28,
                                            fontWeight: 'bold',
                                            color: colors['primaryFontColor'],
                                        },
                                    },
                                    labelLine: {
                                        show: false,
                                    },
                                },
                            },
                            data: [],
                        },
                        {
                            name: 'highLightedBottom',
                            type: 'pie',
                            radius: ['70%', '70%'],
                            tooltip: {
                                show: false,
                            },
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true,
                                        position: 'center',
                                        formatter: function (params) {
                                            return `\n\n\n${params.value}`;
                                        },
                                        textStyle: {
                                            fontSize: fontSize,
                                            // fontSize: 16,
                                            color: colors['hintFontColor'],
                                        },
                                    },
                                    labelLine: {
                                        show: false,
                                    },
                                },
                            },
                            data: [],
                        },
                    ],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly barChartPercentStacked$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        formatter: (params) => {
                            return `${params.data.name}: ${params.data.formattedValue}`;
                        },
                        textStyle: {
                            fontSize: 16,
                        },
                    },
                    legend: {
                        y: 'bottom',
                        textStyle: {
                            color: colors['primaryFontColor'],
                        },
                    },
                    xAxis: [
                        {
                            type: 'value',
                            show: false,
                            max: 100,
                        },
                    ],
                    yAxis: [
                        {
                            type: 'category',
                            axisLabel: {
                                interval: 0,
                                textStyle: {
                                    color: colors['primaryFontColor'],
                                },
                            },
                            axisLine: {
                                lineStyle: {
                                    color: colors['primaryFontColor'],
                                },
                            },
                        },
                    ],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly colChartStacked$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                        formatter: (params) => {
                            const colorSpan = (color) =>
                                `<span style="display: inline-block;
              margin-right: 5px; border-radius: 10px; width: 9px; height: 9px; background-color:${color}"></span>`;

                            let retStr = `<b>${params[0].axisId.slice(0, params[0].axisId.length - 1)}
              : ${params[0].axisValue}</b><br/>`;
                            for (let i = 0; i < params.length; i++) {
                                retStr += `${colorSpan(params[i].color)} ${params[i].data.name}: ${
                                    params[i].data.formattedValue
                                }<br/>`;
                            }
                            return retStr;
                        },
                        textStyle: {
                            fontSize: 16,
                        },
                    },
                    legend: {
                        y: 'bottom',
                        textStyle: {
                            fontSize: 16,
                            color: colors['primaryFontColor'],
                        },
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisLabel: {
                                interval: 0,
                                textStyle: {
                                    fontSize: 12,
                                    color: colors['primaryFontColor'],
                                },
                            },
                            axisLine: {
                                lineStyle: {
                                    color: colors['primaryFontColor'],
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel: {
                                interval: 0,
                                textStyle: {
                                    fontSize: 12,
                                    color: colors['primaryFontColor'],
                                },
                            },
                            axisLine: {
                                lineStyle: {
                                    color: colors['primaryFontColor'],
                                },
                            },
                        },
                    ],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly denseTimeseriesLine$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    grid: {
                        top: '11%',
                        left: '3%',
                        bottom: '15%',
                        right: '13%',
                        containLabel: true,
                    },
                    dataZoom: [
                        {
                            type: 'slider',
                            filterMode: 'weakFilter',
                            showDataShadow: false,
                            bottom: '3%',
                            labelFormatter: '',
                            start: 0,
                            end: 100,
                            height: 18,
                        },
                        {
                            type: 'inside',
                            filterMode: 'weakFilter',
                            start: 0,
                            end: 100,
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: colors['tooltipBg'],
                        borderWidth: 1,
                        extraCssText: 'box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 400,
                            color: colors['primaryFontColor'],
                        },
                        showDelay: 50,
                        axisPointer: {
                            type: 'line',
                            axis: 'x',
                            lineStyle: {
                                color: colors['hintFontColor'],
                                width: 1,
                                type: 'solid',
                            },
                        },
                        formatter: function (params) {
                            let tooltip = `Time: <b>${params[0].axisValueLabel}</b><br/>`;
                            params.forEach((p) => {
                                tooltip =
                                    tooltip +
                                    `${p.marker} ${p.seriesName}: <b>${FormatsService.prepareValue(
                                        p.value[1],
                                        '',
                                        '',
                                    )}</b><br/>`;
                            });
                            return tooltip;
                        },
                    },
                    xAxis: {
                        type: 'time',
                        name: 'Time',
                        nameLocation: 'middle',
                        nameGap: 25,
                        nameTextStyle: {
                            fontWeight: '400',
                            color: colors['axisNameText'],
                        },
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        boundaryGap: true,
                        scale: true,
                    },
                    yAxis: {
                        type: 'value',
                        alignTicks: true,
                        nameTextStyle: {
                            fontWeight: '400',
                            color: colors['axisNameText'],
                        },
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colors['axisLine'],
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: colors['axisLine'],
                            },
                        },
                        /*                            splitArea: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },*/
                    },
                    series: [],
                    legend: {
                        show: false,
                    },
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly lineChart$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    grid: {
                        left: 70,
                        right: 20,
                        top: 10,
                        bottom: 50,
                    },
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: colors['backgroundBasicColor1'],
                        borderWidth: 1,
                        extraCssText: 'box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 600,
                            color: colors['primaryFontColor'],
                        },
                        showDelay: 0,
                        axisPointer: {
                            type: 'line',
                            axis: 'x',
                            lineStyle: {
                                color: colors['hintFontColor'],
                                width: 1,
                                type: 'solid',
                            },
                        },
                    },
                    xAxis: [
                        {
                            nameLocation: 'center',
                            nameGap: 30,
                            type: 'category',
                            splitLine: {
                                show: false,
                            },
                            splitArea: {
                                show: false,
                            },
                            axisTick: {
                                show: true,
                                alignWithLabel: true,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 2,
                                    color: colors['hintFontColor'],
                                },
                            },
                            nameTextStyle: {
                                fontWeight: '400',
                                color: colors['axisNameText'],
                            },
                            axisLabel: {
                                textStyle: {
                                    color: colors['primaryFontColor'],
                                },
                                color: colors['primaryFontColor'],
                            },
                            boundaryGap: false,
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel: {
                                formatter: '{value}%',
                                fontWeight: 600,
                                color: colors['primaryFontColor'],
                                textStyle: {
                                    color: colors['primaryFontColor'],
                                },
                                margin: 15,
                            },
                            splitArea: {
                                show: false,
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 0,
                                },
                            },
                            nameTextStyle: {
                                fontWeight: '400',
                                color: colors['axisNameText'],
                            },
                            splitLine: {
                                lineStyle: {
                                    color: colors['axisLine'],
                                },
                            },
                        },
                    ],
                    series: [
                        {
                            type: 'line',
                            symbol: 'circle',
                            symbolSize: 0,
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly scatterChart$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    grid: {
                        top: '10%',
                        left: '0',
                        right: '4%',
                        bottom: '25%',
                        containLabel: true,
                    },
                    tooltip: {
                        backgroundColor: colors['backgroundBasicColor1'],
                        borderWidth: 1,
                        extraCssText: 'box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);',
                        textStyle: {
                            fontSize: 12,
                            color: colors['primaryFontColor'],
                        },
                        showDelay: 0,
                    },
                    xAxis: {
                        nameLocation: 'middle',
                        nameGap: 25,
                        nameTextStyle: {
                            fontWeight: '400',
                            color: colors['axisNameText'],
                        },
                        axisLabel: {
                            textStyle: {
                                color: colors['primaryFontColor'],
                            },
                            color: colors['primaryFontColor'],
                        },
                        scale: true,
                        splitLine: {
                            show: true,
                        },
                        boundaryGap: false,
                    },
                    yAxis: {
                        nameLocation: 'end',
                        nameTextStyle: {
                            fontWeight: '400',
                            color: colors['axisNameText'],
                        },
                        axisLabel: {
                            textStyle: {
                                color: colors['primaryFontColor'],
                            },
                            color: colors['primaryFontColor'],
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: colors['axisLine'],
                            },
                        },
                        boundaryGap: false,
                    },
                    series: [
                        {
                            type: 'scatter',
                        },
                    ],
                    backgroundColor: colors['backgroundChartColor'],
                },
                colorsMap: colors,
            };
        }),
    );

    /*
     * Failure Curve tool
     */
    readonly multiSeriesLineChart$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                colorsMap: colors,
                options: {
                    title: {
                        x: 'middle',
                        y: 'top',
                        textStyle: {
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: colors['primaryFontColor'],
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            axis: 'x',
                            lineStyle: {
                                color: colors['hintFontColor'],
                                width: 1,
                                type: 'solid',
                            },
                        },
                    },
                    xAxis: {
                        nameLocation: 'middle',
                        type: 'category',
                        nameTextStyle: {
                            padding: [20, 0, 0, 0],
                            align: 'center',
                            color: colors['hintFontColor'],
                        },
                        axisLabel: {
                            interval: 9,
                            textStyle: {
                                color: colors['primaryFontColor'],
                            },
                            color: colors['primaryFontColor'],
                        },
                        splitLine: {
                            show: true,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                width: 0,
                                colors: colors['hintFontColor'],
                            },
                        },
                        axisPointer: {
                            show: true,
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '50',
                        containLabel: true,
                    },
                    yAxis: {
                        type: 'value',
                        nameLocation: 'end',
                        nameTextStyle: {
                            padding: [10, 0, 0, 0],
                            align: 'center',
                            color: colors['hintFontColor'],
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: colors['primaryFontColor'],
                                fontWeight: 'bold',
                            },
                            color: colors['primaryFontColor'],
                        },
                        splitLine: {
                            show: true,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                width: 0,
                                colors: colors['hintFontColor'],
                            },
                        },
                        axisPointer: {
                            show: true,
                            label: {
                                show: false,
                            },
                        },
                    },
                },
            };
        }),
    );

    readonly barChartClusteredDrilldown$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        formatter: (params) => {
                            return `${params.data.name}: ${params.data.formattedValue}`;
                        },
                        textStyle: {
                            fontSize: 16,
                        },
                    },
                    legend: {
                        y: 'bottom',
                        textStyle: {
                            color: colors['primaryFontColor'],
                        },
                    },
                    xAxis: [
                        {
                            triggerEvent: true,
                            type: 'category',
                            nameLocation: 'center',
                            nameGap: 30,
                            nameTextStyle: {
                                color: colors['hintFontColor'],
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 2,
                                    color: colors['hintFontColor'],
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            nameGap: 20,
                            nameTextStyle: {
                                fontSize: 13,
                                fontWeight: 400,
                                color: colors['hintFontColor'],
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                interval: 0,
                                textStyle: {
                                    color: colors['primaryFontColor'],
                                },
                                color: colors['primaryFontColor'],
                                fontWeight: 600,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 0,
                                },
                            },
                        },
                    ],
                },
                colorsMap: colors,
            };
        }),
    );

    /*
     * Data audit
     */
    readonly lineChartMultiSeriesHorizontal$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                        position: 'top',
                        backgroundColor: colors['backgroundBasicColor1'],
                        borderWidth: 0,
                        extraCssText: 'box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 600,
                            color: colors['primaryFontColor'],
                        },
                    },
                    grid: {
                        // left: 45,
                        x: 0,
                        y: 35,
                        x1: 0,
                        y2: 0,
                        // width: '90%',
                        containLabel: true,
                    },
                    legend: {
                        show: false,
                        selected: {},
                    },
                    xAxis: {
                        type: 'category',
                        nameLocation: 'center',
                        nameTextStyle: {padding: [10, 0, 0, 0]},
                        boundaryGap: false,
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisTick: {show: false},
                    },
                    yAxis: [
                        {
                            type: 'value',
                            nameTextStyle: {padding: [0, 0, 0, 75]},
                            min: 0,
                            max: 100,
                            interval: 10,
                            position: 'left',
                            splitLine: {
                                lineStyle: {
                                    type: 'solid',
                                    color: colors['hintFontColor'],
                                    width: 0.25,
                                },
                            },
                            axisLabel: {
                                formatter: (params) => {
                                    return `${params}%`;
                                },
                                color: colors['primaryFontColor'],
                            },
                            axisLine: {
                                lineStyle: {
                                    type: 'solid',
                                    color: colors['hintFontColor'],
                                    width: 0.25,
                                },
                            },
                            axisTick: {show: false},
                        },
                        {
                            type: 'value',
                            nameTextStyle: {padding: [0, 75, 0, 0]},
                            position: 'right',
                            // interval: 1000,
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                formatter: (params) => {
                                    return `${params}`;
                                },
                                color: colors['primaryFontColor'],
                            },
                            axisLine: {
                                lineStyle: {
                                    type: 'solid',
                                    color: colors['hintFontColor'],
                                    width: 0.25,
                                },
                            },
                        },
                    ],
                },
                colorsMap: colors,
            };
        }),
    );

    readonly barChartClustered$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                    },
                    legend: {
                        show: false,
                        selected: {},
                    },
                    grid: {
                        x: 35,
                        y: 35,
                        x1: 35,
                        y2: 35,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'value',
                        nameLocation: 'center',
                        nameTextStyle: {padding: [10, 0, 0, 0]},
                        min: 0,
                        max: 100,
                        interval: 10,
                        splitLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisLabel: {
                            formatter: (params) => {
                                return `${params}%`;
                            },
                            color: colors['primaryFontColor'],
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisTick: {show: false},
                    },
                    yAxis: {
                        type: 'category',
                        nameTextStyle: {padding: [0, 0, 0, 45]},
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisTick: {show: false},
                    },
                },
                colorsMap: colors,
            };
        }),
    );

    readonly colChartSimple$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                    },
                    legend: {
                        show: false,
                    },
                    grid: {
                        x: 35,
                        y: 35,
                        x1: 35,
                        y2: 35,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        nameLocation: 'center',
                        nameTextStyle: {padding: [10, 0, 0, 0]},
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisTick: {show: false},
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: colors['hintFontColor'],
                                width: 0.25,
                            },
                        },
                        axisLabel: {
                            color: colors['primaryFontColor'],
                        },
                        axisTick: {show: false},
                    },
                },
                colorsMap: colors,
            };
        }),
    );

    readonly lineChartMiniTrend$: Observable<Chart> = this.colors.pipe(
        map((colors) => {
            return {
                options: {
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        position: 'top',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 600,
                            color: colors['primaryFontColor'],
                        },
                    },
                    grid: {
                        x: 15,
                        y: 15,
                        x1: 15,
                        y2: 15,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                },
                colorsMap: colors,
            };
        }),
    );

    /*
     * Common
     */
    private readonly emptyChart: any = {
        title: {
            show: true,
            x: 'center',
            textStyle: {
                fontSize: 14,
            },
        },
        xAxis: {
            show: false,
        },
        yAxis: {
            show: false,
        },
        series: [
            {
                name: 'highLightedBottom',
                type: 'pie',
                radius: ['100%', '100%'],
                tooltip: {
                    show: false,
                },
                label: {
                    show: true,
                    position: 'center',
                    formatter: function (params) {
                        return params.value;
                    },
                },
                textStyle: {
                    fontSize: 16,
                },
                labelLine: {
                    show: false,
                },
                itemStyle: {},
            },
        ],
    };

    constructor(private colorsService: ColorsService, private fontsService: FontsService) {}

    public getEmptyChart(metadata, chartOptions) {
        return {
            metadata: metadata,
            options: {
                ...this.emptyChart,
                title: {
                    ...this.emptyChart.title,
                    text: metadata.name,
                    textStyle: {
                        ...this.emptyChart.title.textStyle,
                        color: chartOptions.colorsMap['primaryFontColor'],
                    },
                },
                series: [
                    {
                        ...this.emptyChart.series[0],
                        itemStyle: {
                            ...this.emptyChart.series[0].itemStyle,
                            normal: {
                                ...this.emptyChart.series[0].itemStyle.normal,
                                label: {
                                    ...this.emptyChart.series[0].itemStyle.normal.label,
                                    textStyle: {
                                        ...this.emptyChart.series[0].itemStyle.normal.label.textStyle,
                                        color: chartOptions.colorsMap['primaryFontColor'],
                                    },
                                },
                            },
                        },
                        data: [{value: 'Data not available for this study'}],
                    },
                ],
            },
        };
    }
}
