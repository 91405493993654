import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {JobsService, OutputReportSource} from '../../../interfaces/engin/jobs';
import {JobsApi} from '../api/jobs.api';

@Injectable()
export class JobsServiceImpl extends JobsService {
    constructor(private api: JobsApi) {
        super();
    }

    getOutputReportGeneric(reportRequest): Observable<OutputReportSource> {
        return this.api.getOutputReportGeneric(reportRequest);
    }

    updateDefaultStudyStrategy(latestAsDefault: boolean, defaultCollectionId: string): Observable<any> {
        return this.api.updateDefaultStudyStrategy(latestAsDefault, defaultCollectionId);
    }
}
