import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';

export interface Note {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    lastUpdatedBy: string;
    lastUpdatedByName: string;
    documentType: string;
    relatedEntity: string;
    note: string;
}

export interface NoteCreate {
    documentType: string;
    relatedEntity: string;
    note: string;
}

export interface DocumentAssetImage {
    id: number;
    assetId: number;
    name: string;
    tag: string;
    url: string;
}

export abstract class NoteService {
    /*
     * Operational
     */
    abstract create(note: NoteCreate): Observable<APIResponse<Note>>;

    abstract update(noteId: number, note: Note): Observable<APIResponse<Note>>;

    abstract delete(noteId: number): Observable<APIResponse<boolean>>;

    /*
     * Retrieval
     */
    abstract getAssetNotes(assetId: string): Observable<APIResponse<Note[]>>;
}
