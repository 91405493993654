import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {Dataset} from './dataset';
import {APIResponse} from '../system/system-common';

export interface Snapshot {
    id: string;
    version: number;
    status: 'CREATED' | 'EXECUTED' | 'PENDING_DELETE' | 'DELETED';
    createdOn: Date;
    updatedOn: Date;
    tag: string;
    note: string;
    workFlowType: 'STUDY' | 'CURVE_CALIBRATION';
    datasets: Dataset[];
}

export interface SnapshotDto {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    workflowType: 'STUDY' | 'CURVE_CALIBRATION';
    tag: string;
    note: string;
    status: 'CREATED' | 'EXECUTED' | 'PENDING_DELETE' | 'DELETED';
    snapshotVersion: number;
    datasets: Dataset[];
}

export abstract class SnapshotService {
    abstract findOne(id: string): Observable<APIResponse<Snapshot>>;

    abstract findByDataSetId(id: string): Observable<APIResponse<any>>;

    abstract create(data: Snapshot): Observable<APIResponse<Snapshot>>;

    abstract update(id: string, data: Snapshot): Observable<APIResponse<Snapshot>>;

    abstract delete(snapshotId: string): Observable<APIResponse<boolean>>;

    abstract reversePendingDelete(snapshotId: string): Observable<APIResponse<boolean>>;

    abstract getDataSource(): Observable<DataSource>;
}
