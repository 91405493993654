<div class="group-button-container">
    <nb-button-group>
        <button
            *ngFor="let option of options"
            nbButtonToggle
            class="toggle-button"
            [value]="option.code"
            [pressed]="isButtonSelected(option.code)"
            [ngClass]="isButtonSelected(option.code) ? 'selected' : ''"
            (click)="onChange(option.code)"
        >
            {{ option.label }}
        </button>
    </nb-button-group>
</div>
