<ul class="pages-list">
    <li class="pages-item arrow-prev" (click)="prevPage()" [ngClass]="{hover: selectedPage !== 1}">
        <a>
            <ngx-icon-box class="arrow-icon" icon="arrow-back"> </ngx-icon-box>
        </a>
    </li>
    <ng-container *ngFor="let page of pages">
        <li
            class="pages-item"
            [ngClass]="{selected: page === selectedPage, hover: page !== selectedPage}"
            (click)="selectPage(page)"
        >
            <a>{{ page }}</a>
        </li>
    </ng-container>
    <li class="pages-item arrow-next" (click)="nextPage()" [ngClass]="{hover: selectedPage !== pagesCount}">
        <a>
            <ngx-icon-box class="arrow-icon" icon="arrow-forward"> </ngx-icon-box>
        </a>
    </li>
</ul>
