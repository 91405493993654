import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Org, PagesStore, SidebarStore, UsersStore} from '../../../@store';
import {NbAuthService} from '@nebular/auth';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {User} from '@core/interfaces/common/users';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends Unsubscribable implements OnInit {
    @Input() currentUser: any;

    constructor(
        public pagesStore: PagesStore,
        public sidebarStore: SidebarStore,
        public authService: NbAuthService,
        private usersStore: UsersStore,
    ) {
        super();
    }

    ngOnInit() {
        this.usersStore.currentUser$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((u) => u && !!u),
            )
            .subscribe((user: User) => {
                this.isOrgSwi.next(this.usersStore.checkCurrentOrg(user) == Org.SWI);
            });
    }

    staging: boolean = environment.staging;

    private isOrgSwi: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isOrgSwi$: Observable<boolean> = this.isOrgSwi.asObservable();
}
